import React, { useEffect, useState } from 'react';
import ChainLogo from '../../../base/ChainLogo';
import HoriontalPercentBarChart from '../../HorizontalBarChart/PercentageBased';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import { getGrowth } from '../../../../utils/values';

const MultiChainActivity = ({
  activityData, previousHighlight, walletCount, isLoading, className = '', type = 'audience',
}) => {
  const [data, setData] = useState([]);
  const [isSettingData, setIsSettingData] = useState(true);
  const [avgShare, setAvgShare] = useState('');
  const [avgPercent, setAvgPercent] = useState(0);
  const [previousAvgPercent, setPreviousAvgPercent] = useState(0);

  const legend = 'Wallets active on the blockchain';
  const info = type === 'nft'
    ? 'Chains most commonly used by holders of this collection'
    : type === 'asset'
      ? 'Chains most commonly used by holders of this asset'
      : 'Chains most commonly used by members of this audience';

  useEffect(() => {
    if (activityData && !isLoading) {
      if (walletCount === 0) return;
      setIsSettingData(true);
      const newData = activityData.buckets
        .filter((bucket) => bucket.value > 0)
        .map((bucket) => ({
          name: bucket.label,
          value: bucket.value,
          logo: <ChainLogo chain={bucket.label} square large />,
          message: `${convertNumber(bucket.value.toFixed(0))}
          wallet${bucket.value > 1 ? 's' : ''} of this audience have had activity on ${bucket.label}.`,
        }))
        .sort((a, b) => b.value - a.value);
      const addresses = (activityData.multi_chain_addresses / walletCount) * 100;
      setAvgPercent(addresses);
      const formattedAddresses = addresses.toFixed(2) === '0.00' ? '0' : addresses.toFixed(2);
      setAvgShare(`${formattedAddresses}%`);
      setData(newData);
    } else {
      setData([]);
      setAvgPercent(0);
    }
    setTimeout(() => setIsSettingData(false), 100);
  }, [activityData, isLoading]);

  useEffect(() => {
    if (previousHighlight && walletCount !== 0) {
      const addresses = (previousHighlight.multi_chain_addresses / walletCount) * 100;
      setPreviousAvgPercent(addresses);
    } else {
      setPreviousAvgPercent(0);
    }
  }, [previousHighlight, walletCount]);

  return (
    <div className={className || ''}>
      <HoriontalPercentBarChart
        title="Multi-chain Activity"
        highlightText="Wallets Active on Multiple Chains"
        highlightValue={avgShare}
        isLoading={isLoading || isSettingData}
        data={data}
        total={walletCount}
        info={info}
        legend={legend}
        growth={previousHighlight
          ? getGrowth(
            avgPercent,
            previousAvgPercent,
            `${previousAvgPercent.toFixed(2)}%`,
          )
          : null}
      />
    </div>
  );
};

export default MultiChainActivity;
