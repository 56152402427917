import React from 'react';
import { webSDKApi } from '../../../api/webSDK';
import { formsApi } from '../../../api/forms';
import { getWebsite, setWebsite, getForm, setForm } from '../../../store/reducers/campaign';
import Dropdown from '../Components/Dropdown';
import styles from './Selection.module.scss';
import { parseSingleData } from '../helper';

const Selection = ({ sourceFormId, sourceWebsiteId }) => {
  const {
    currentData: defaultForm,
  } = formsApi.useGetFormByIdQuery(
    {
      id: sourceFormId,
    },
    { skip: !sourceFormId },
  );

  const {
    data : defaultWebsite,
  } = webSDKApi.useGetWebSDKByIdQuery(
    sourceWebsiteId, 
    { 
      skip: !sourceWebsiteId 
    }
  );

  return (
    <div className={styles.wrapper}>
      <Dropdown 
        type="websites" 
        label="Website" 
        defaultItem={defaultWebsite ? parseSingleData(defaultWebsite, 'websites') : null}
        getter={getWebsite}
        setter={setWebsite}
      />
      <Dropdown 
        type="forms" 
        label="Form" 
        defaultItem={defaultForm ? parseSingleData(defaultForm, 'forms') : null} 
        getter={getForm}
        setter={setForm}
      />
    </div>
  )
}

export default Selection
