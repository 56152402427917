import React from 'react';
import { useSelector } from 'react-redux';
import ConditionButton from '../Components/ConditionButton';
import { conditionsConfig } from '../options';
import { getSelectedConditions } from '../../../store/reducers/campaign';
import styles from './ConditionButtons.module.scss';

const ConditionButtons = () => {
  const conditions = useSelector(getSelectedConditions);

  return (
    <div className={styles.wrapper}>
      {conditionsConfig
        .filter((cond) => !conditions.includes(cond.id))
        .map((condition) => (
          <ConditionButton 
            key={condition.id} 
            id={condition.id} 
            label={condition.label} 
            icon={condition.icon} 
          />
      ))}
    </div>
  )
}

export default ConditionButtons
