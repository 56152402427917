import React, { useEffect, useState } from 'react';
import styles from './DevActivityList.module.scss';
import Table from '../../../components/base/Table';
import DownloadModal from '../../../components/ui/modals/DownloadModal';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import ChainLogo from '../../base/ChainLogo';
import Header from '../Table/Header';
import { getMaxValueByProperty } from '../../../utils/object';
import { getTextWidth } from '../../../utils/numbers';

const DevActivityList = ({
  data, 
  holders, 
  isLoading, 
  isFetching, 
  page, 
  setPage, 
  orderBy, 
  setOrderBy,
  order, 
  setOrder, 
  limit, 
  setLimit
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const header = [
    {
      field: 'blockchain',
      title: 'Blockchain name',
    },
    {
      field: 'active_ever',
      title: 'Total developers',
    },
    {
      field: 'active_7d',
      title: 'Developers last 7d',
    },
    {
      field: 'active_30d',
      title: 'Developers last 30d',
    },
    {
      field: 'active_90d',
      title: 'Developers last 90d',
    },
  ];

  useEffect(() => {
    if (data?.length) {
      const totalDevelopersWidth = `${getTextWidth(getMaxValueByProperty(data, "active_ever"), 14) + 12}px`;
      const developers7dWidth = `${getTextWidth(getMaxValueByProperty(data, "active_7d"), 14) + 12}px`;
      const developers30dWidth = `${getTextWidth(getMaxValueByProperty(data, "active_30d"), 14) + 12}px`;
      const developers90dWidth = `${getTextWidth(getMaxValueByProperty(data, "active_90d"), 14) + 12}px`;

      const temp = data.map((elem) => ({
        blockchain: elem?.value !==  "OVERALL" ? (
          <span className="d-flex justfy-content-between align-items-center gap-2">
            <ChainLogo chain={elem.value} square large />
            <span className="text-capitalize">
              {elem.value.toLowerCase()}
            </span>
          </span>
        ) : ( <span className="d-flex text-capitalize">
                {elem.value.toLowerCase()}
              </span>),
        active_ever:(
          <div className={`${styles.elements} d-flex align-items-baseline`}>
            <span style={{ minWidth: totalDevelopersWidth }}>{ethFormat(elem.active_ever, 1)}</span>
            <span className={`${styles.percentage}`}>
                  {(elem.active_ever / holders) * 100 >= 0.01
                    ? ethFormat((elem.active_ever / holders) * 100, 1)
                    : (elem.active_ever / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        active_7d: (
          <div className={`${styles.elements} d-flex align-items-baseline`}>
            <span style={{ minWidth: developers7dWidth }}>{ethFormat(elem.active_7d, 1)}</span>
            <span className={`${styles.percentage}`}>
                  {(elem.active_7d / holders) * 100 >= 0.01
                    ? ethFormat((elem.active_7d / holders) * 100, 1)
                    : (elem.active_7d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        active_30d:(
          <div className={`${styles.elements} d-flex align-items-baseline`}>
            <span style={{ minWidth: developers30dWidth }}>{ethFormat(elem.active_30d, 1)}</span>
            <span className={`${styles.percentage}`}>
                    {(elem.active_30d / holders) * 100 >= 0.01
                      ? ethFormat((elem.active_30d / holders) * 100, 1)
                      : (elem.active_30d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        active_90d: (
          <div className={`${styles.elements} d-flex align-items-baseline`}>
            <span style={{ minWidth: developers90dWidth }}>{ethFormat(elem.active_90d, 1)}</span>
            <span className={`${styles.percentage}`}>
                    {(elem.active_90d / holders) * 100 >= 0.01
                      ? ethFormat((elem.active_90d / holders) * 100, 1)
                      : (elem.active_90d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
    }));
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data, holders]);


  return (
    <div className={styles.wrapper}>
      <Header
        title="Developer activity"
        tooltip="Measures the unique addresses in the audience which deployed contracts on the chains listed, on mainnet"
      />
      <div className="w-100">
        <Table
          total={data ? data.length !== 0 ? data[0].number_of_paths : 0 : 0}
          data={tableData}
          desc={order}
          setDesc={setOrder}
          ordering={orderBy}
          setOrdering={setOrderBy}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isLoading || isFetching}
          min={25}
          highlight_border={true}
        />
      </div>
      {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
    </div>
  );
};

export default DevActivityList;
