import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMessage } from '../../../../components/base/Notifications';
import { setFormName, setFormUrl, selectFormUrl } from '../../../../store/reducers/forms';
import NameModal from '../../../../components/ui/modals/NameModal';

export const SaveFormNameModals = ({
  onCancel, onSubmit, formName, status,
}) => {
  const dispatch = useDispatch();
  const formurl = useSelector(selectFormUrl);

  const generateRandomString = () => {
    const chars = 'abcdefghjkmnpqrstuvwxyz23456789';
    let resulting = '';
    for (let i = 0; i < 5; i += 1) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      resulting += chars[randomIndex];
    }
    return resulting;
  };

  useEffect(() => {
    if (!formurl || formurl === '') {
      const gen = generateRandomString();
      dispatch(setFormUrl(gen));
    }
  }, []);

  const handleSave = () => {
    if (!formName) {
      showErrorMessage('Form Name cannot be empty');
      return;
    }
    onSubmit(status);
    onCancel();
  };

  return (
    <NameModal
      title="Name your form"
      description="To save a form you will need to give it a name"
      placeholder="Form Name"
      onCancel={onCancel}
      onSave={handleSave}
      onChange={(name) => dispatch(setFormName(name))}
    />
  );
};
