import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCondition, setConditionData, getConditionsData } from '../../../../store/reducers/campaign';
import { conditionsConfig, getDefaultForCondition } from '../../options';
import Add from '../../../../assets/icons/plus_blue.svg';
import styles from './ConditionButton.module.scss';

const ConditionButton = ({ id, label, icon }) => {
  const dispatch = useDispatch();
  const data = useSelector(getConditionsData);
  
  const handleAddCondition = () => {
    const conditionConfig = conditionsConfig.find(c => c.id === id);

    if (conditionConfig) {
      conditionConfig.inputs?.forEach((input) => {
        const defaultValue = getDefaultForCondition(id, input.label);
        dispatch(addCondition({ conditionId: id, label: input.label, defaultValue }));
        
        if (input.data && (!data[id] || !data[id][input.label])) {
          dispatch(setConditionData({ conditionId: id, data: { [input.label]: input.data } }));
        }
        else if (input.fields) {
          const data = input.fields.find(field => field.hasOwnProperty('data'))?.data;
          if (data && (!data[id] || !data[id][input.label])) {
            dispatch(setConditionData({ conditionId: id, data: { [input.label]: data } }));
          }
        }
      });
    }
  };

  return (
    <button className={styles.wrapper} onClick={handleAddCondition}>
      <div className={styles.content}>
        <img src={icon} alt={label} />
        <span className={styles.label}>{label}</span>
      </div>
      <div className={styles.add}>
        <Add />
      </div>
    </button>
  )
}

export default ConditionButton
