import { useEffect, useRef } from 'react';

const useKeydown = (key, func) => {
  const funcRef = useRef(func);

  useEffect(() => {
    funcRef.current = func;
  }, [func]);

  const downHandler = (event) => {
    if (event.key === key) {
      funcRef.current();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [window]);
};

export default useKeydown;
