import React from 'react';
import Modal from '../../../base/Modal';
import ModalTitle from '../../../base/Modal/ModalTitle';
import ModalFooter from '../../../base/Modal/ModalFooter';

const ForbiddenModal = ({
  onProceed,
  toLogin,
}) => {
  return (
    <Modal onCancel={onProceed}>
      <ModalTitle title="We are sorry..." />
      
      <div className="text-md-regular px-3 py-2">
        {`The page you’re trying to use has restricted access. Please reach out to your Customer Success Manager.
        ${toLogin ? 'You will be log out.' : ''}`}
      </div>

      <ModalFooter>
        <button className="regular-button" type="button" onClick={onProceed}>
          Okay
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ForbiddenModal;
