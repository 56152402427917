import { formsApi } from "../../api/forms";
import { audiencesApi } from "../../api/audiences";
import { webSDKApi } from "../../api/webSDK";
import { apiTypes } from "./constants";
import { CustomListDataLabel } from "../AddSegment/components/labels";
import { isEmptyObject } from "../../utils/object";
import { countriesOptions, languagesOptions } from "../../utils/webSDK";
import { conditionsConfig } from "./options";

export const getUrl = (type, val) => {
  if (!type || !apiTypes.includes(type)) return null;

  let url;
  let value;
  switch (type) {
    case 'forms':
      url = formsApi.endpoints.getForms;
      value = {
        search: val || '', limit: 100, additionalParams: '&active_draft_only=true'
      };
      break;
    case 'websites':
      url = webSDKApi.endpoints.getWebSDK;
      value = { search: val || '' };
      break;
    case 'segments':
      url = audiencesApi.endpoints.getFlowsSegments;
      value = { search: val || '',  for_web_campaigns: true };
      break;
  }

  return { url, value };
}

export const parseData = (apiData, type) => {
  if (!type || !apiTypes.includes(type)) return;

  let returnData = null;
  switch (type) {
    case 'websites':
      if (apiData.status === 'fulfilled') {
        returnData = apiData.data.map((item) => ({
          value: item.site_id,
          label: item.website_name,
          id: item.id,
        }));
      }
      break;
    case 'forms':
      if (apiData.status === 'fulfilled') {
        returnData = apiData.data.results.map((item) => ({
          value: item.id,
          label: item.name,
          status: item.status,
          id: item.id,
        }));
      }
      break;
    case 'segments':
      if (apiData.status === 'fulfilled') {
        returnData = apiData.data.results.filter((item) => item?.ready).map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
      break;
  }

  return returnData;
};

export const parseSingleData = (rawData, type) => {
  if (!type || !apiTypes.includes(type)) return;

  let returnData = null;
  switch (type) {
    case 'websites':
      returnData = {
        value: rawData.site_id,
        label: rawData.website_name,
        id: rawData.id,
      };
      break;
    case 'forms':
      returnData = {
        value: rawData.id,
        label: rawData.name,
        status: rawData.status,
        id: rawData.id,
      };
      break;
  }

  return returnData;
};

export const getLabelComponent = (type, val) => {
  if (!type || !apiTypes.includes(type)) return null;

  switch (type) {
    case 'segments':
      return <CustomListDataLabel val={val} additional={false} />;
  }
}

export const parseButtons = (rawButtons) => {
  if (!rawButtons) return [];
  return rawButtons.map((button, index) => ({
    value: button.id,
    label: button.options.text,
    index: index + 1
  }))
}

const isConditionValid = (condition, values) => {
  switch (condition) {
    case 'dates':
      return new Date(values.startDate) < new Date(values.endDate);
    case 'wallet':
      return values.wallet?.value === "must_not_have" 
        || ((values.of_type_any_of_all_of?.value === "of_any_type" 
        || values.chains?.length > 0)
        && (values.with_name_any_of_all_of?.value === "of_any_name" 
        || values.names?.length > 0));
    case 'wallet_connected':
      return true;
    case 'language':
      return values.languages.length > 0;
    case 'scroll':
      return values.level !== '' && values.level !== '0';
    case 'country':
      return values.countries.length > 0;
    case 'time':
      return values.timeSpent !== '' && values.timeSpent !== '0';
    case 'urlReq':
    case 'urlExc':
      return values.urlRequirements.every(item => item.text !== "");
    case 'segments':
      return values.inSegments?.length > 0 || values.notInSegments?.length > 0;
    case 'utm':
      return values.currentTags && values.tags.every((item) => item.tags?.length > 0);
    default:
      throw new Error(`Condition '${condition}' not managed !`);
  }
}

export const isCampaignValid = (campaign) => {
  if (!campaign.website || !campaign.form) {
    return false;
  }
  if (campaign.advancedSettings.timesDisplay === 0 
    || campaign.advancedSettings.timeBetweenDisplay === 0) {
    return false;
  }
  if (campaign.advancedSettings.abTesting.eligible <= 0 || campaign.advancedSettings.abTesting.eligible > 100) {
    return false;
  }
  if (campaign.advancedSettings.abTesting.eligible < 100 && campaign.advancedSettings.abTesting.othersForm.value && campaign.advancedSettings.abTesting.othersForm.value === campaign.form.id) {
    return false;
  }
  if (!isEmptyObject(campaign.conditions?.selectedValues)) {
    let conditionValidation = [];
    for (const condition in campaign.conditions?.selectedValues) {
      conditionValidation.push(
        isConditionValid(
          condition, 
          campaign.conditions?.selectedValues[condition]
        )
      );
    }
    if (conditionValidation.some(value => value === false)) {
      return false;
    }
  }
  return true;
}

const parseDatesToBE = (dates) => {
  if (dates) {
    return {
      startDate: dates.startDate.toISOString(),
      endDate: dates.endDate.toISOString()
    }
  }
  return {
    startDate: null,
    endDate: null
  }
}

const groupUrlsByCondition = (urls) => {
  return urls.reduce((acc, item) => {
    const key = item.condition.value === 'ends' ? 'ends_with' 
              : item.condition.value === 'starts' ? 'starts_with' 
              : item.condition.value;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item.text);
    return acc;
  }, {});
};

export const parseCampaignToBE = (campaign) => {
  if (!campaign) return null;

  let parsedConditions = {};
  for (const condition in campaign.conditions?.selectedValues) {
    const values = campaign.conditions?.selectedValues[condition];
    if (values && condition !== 'dates') {
      switch (condition) {
        case 'language':
          if (values.languages.length !== 0) {
            if (values.requirements.value === 'required') {
              parsedConditions['language'] = { "allowed": values.languages.map((language) => language.value) }
            } else {
              parsedConditions['language'] = { "excluded": values.languages.map((language) => language.value) }
            }
          }
          break;
        case 'wallet':
          parsedConditions['has_wallet'] = {
            must_have: values.wallet.value === 'must_have',
            names: {
              condition: values.with_name_any_of_all_of ? values.with_name_any_of_all_of.value.slice(10) : null,
              values: values.names ? values.names.map((name) => (
                {
                  name: name.value,
                  platform: name.network
                })) : null,
            },
            types: {
              condition: values.of_type_any_of_all_of ? values.of_type_any_of_all_of.value.slice(8) : null,
              values: values.chains ? values.chains.map((chain) => chain.value) : null,
            }
          }
          break;
        case 'wallet_connected':
          parsedConditions['wallet_connected'] = values.wallet.value === 'must_have';
          break;
        case 'scroll':
          if (values.level > 0) {
            parsedConditions['scroll_level'] = values.level;
          }
          break;
        case 'country':
          if (values.countries.length !== 0) {
            if (values.requirements.value === 'required') {
              parsedConditions['country'] = { "allowed": values.countries.map((country) => country.value) }
            } else {
              parsedConditions['country'] = { "excluded": values.countries.map((country) => country.value) }
            }
          }
          break;
        case 'time':
          if (values.timeSpent > 0) {
            parsedConditions['time_on_page'] = values.timeSpent;
          }
          break;
        case 'urlReq':
          const urlToSend = values.urlRequirements.filter((url) => url.text.trim() !== '');
          const groupedUrls = groupUrlsByCondition(urlToSend);
          if (!isEmptyObject(groupedUrls)) {
            parsedConditions['url_requirements'] = groupedUrls;
          }
          break;
        case 'urlExc':
          const eUrlToSend = values.urlRequirements.filter((url) => url.text.trim() !== '');
          const groupedEUrls = groupUrlsByCondition(eUrlToSend);
          if (!isEmptyObject(groupedEUrls)) {
            parsedConditions['url_exclusions'] = groupedEUrls;
          }
          break;
        case 'segments':
          const segments = {};
          if (values.inSegments?.length > 0) {
            segments['in_segments'] = values.inSegments.map((segment) => segment.value);
          }
          if (values.notInSegments?.length > 0) {
            segments['not_in_segments'] = values.notInSegments.map((segment) => segment.value);
          }
          if (!isEmptyObject(segments)) {
            parsedConditions['segments'] = segments;
          }
          break;
        case 'utm':
          const utmConfig = conditionsConfig.find(c => c.id === 'utm');
          const utmData = utmConfig.inputs[1].fields[0].data.map((utm) => utm.value);
          
          const utmToSend = values.tags.filter((utm) => utm.utm && utm.tags?.length > 0);
          const utms = utmToSend.reduce((acc, item) => {
            const key = item.utm.value;
            const values = item.tags.map(tag => tag.value);
            acc[key] = values;
            return acc;
          }, {});

          const nullUtms = utmData.reduce((acc, item) => {
            const exists = !!utmToSend.find((utm) => utm.utm.value === item);
            if (!exists) acc[item] = null;
            return acc;
          }, {});
          
          parsedConditions['utm'] = {
            current_only: values.currentTags?.value === "current",
            ...utms,
            ...nullUtms
          };
          break;
        default:
          throw new Error(`Condition '${condition}' not managed !`);
      }
    }
  }

  const { startDate, endDate } = parseDatesToBE(campaign.conditions.selectedValues.dates);

  return {
    ...(campaign.website && 
      { website: campaign.website.id}
    ),
    ...(campaign.form && 
      { form: campaign.form.id}
    ),
    ...(campaign.title && 
      { name: campaign.title}
    ),
    ...(startDate && 
      { start_at: startDate }
    ),
    ...(endDate && 
      { end_at: endDate }
    ),
    ...(campaign.advancedSettings.timesDisplay > 0 &&
      { max_display_count: campaign.advancedSettings.timesDisplay }
    ),
    ...(campaign.advancedSettings.timeBetweenDisplay > 0 &&
      { min_display_delay: campaign.advancedSettings.timeBetweenDisplay }
    ),
    ...(campaign.advancedSettings.timeBetweenDisplay > 0 &&
      { min_display_delay: campaign.advancedSettings.timeBetweenDisplay }
    ),
    stop_displaying_if_submitted: campaign.advancedSettings.hideAfterSubmission,
    ...(campaign.advancedSettings.hideIfButtonClicked && campaign.advancedSettings.hideIfButtonClicked.value &&
      { stop_displaying_if_button_clicked: campaign.advancedSettings.hideIfButtonClicked.index }
    ),
    ...(campaign.advancedSettings.closeIfButtonClicked && campaign.advancedSettings.closeIfButtonClicked.value &&
      { close_modal_if_button_clicked: campaign.advancedSettings.closeIfButtonClicked.index }
    ),
    ...(campaign.advancedSettings.abTesting.eligible > 0 && campaign.advancedSettings.abTesting.eligible <= 100 &&
      { display_probability: (campaign.advancedSettings.abTesting.eligible / 100) }
    ),
    ...(campaign.advancedSettings.abTesting.eligible > 0 && campaign.advancedSettings.abTesting.eligible < 100 &&
      campaign.advancedSettings.abTesting.othersForm &&
      { related_form: campaign.advancedSettings.abTesting.othersForm.value }
    ),
    ...(!isEmptyObject(parsedConditions) && 
      { 
        conditions: {
        ...parsedConditions,
        }
      }
    ),
  }
}

export const getSelectedCountries = (countries) => {
  let selectedCountries = [];
  countries.forEach((country) => {
    const foundCountry = countriesOptions.find((c) => c.value === country);
    if (foundCountry) {
      selectedCountries.push(foundCountry);
    }
  });
  return selectedCountries;
}

export const getSelectedLanguages = (languages) => {
  let selectedLanguages = [];
  languages.forEach((language) => {
    const foundLanguage = languagesOptions.find((c) => c.value === language);
    if (foundLanguage) {
      selectedLanguages.push(foundLanguage);
    }
  });
  return selectedLanguages;
}

export const getTagsFromBE = (tagsObject) => {
  let tags = [];
  let rowId = Date.now();
  for (const key in tagsObject) {
    if (tagsObject.hasOwnProperty(key)) {
      if (!!tagsObject[key]) {
        tags.push({
            id: rowId,
            utm: { label: key, value: key },
            tags: tagsObject[key].map((tag) => ({ label: tag, value: tag })),
          });
        rowId += 1;
      }
    }
  }
  return tags;
}
