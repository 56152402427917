import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { counter } from '../../../../tools/Counter';
import Loader from '../../../../components/base/Loader';
import Table from '../../../../components/base/Table';
import { webSDKApi } from '../../../../api/webSDK';
import { selectWebSDKFilterParam } from '../../../../store/reducers/webSDK';
import DownloadModal from '../../../../components/ui/modals/DownloadModal';
import Export from '../../../../components/ui/Buttons/Export';
import { getCardValue } from '../../../../utils/values';
import Header from '../../../../components/ui/Table/Header';

const PagesList = () => {
  const { id } = useParams();
  const filterParams = useSelector(selectWebSDKFilterParam);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [orderBy, setOrderBy] = useState(searchParams.get('orderBy') || 'adoption');
  const [order, setOrder] = useState(searchParams.get('order') !== 'false');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const [tableData, setTableData] = useState([]);

  const {
    data: eCommercePagesData,
    isLoading: isECommercePagesDataLoading,
    isFetching: isECommercePagesDataFetching,
  } = webSDKApi.useGetWebSDKECommercePagesQuery(
    {
      id,
      limit: +limit,
      offset: (page - 1) * (+limit),
      orderBy,
      order: order ? 'desc' : 'asc',
      ...filterParams,
    }, {
      skip: !filterParams || !filterParams?.day
    },
  );
  const [getWebSDKECommercePagesCSV, result] = webSDKApi.useGetWebSDKECommercePagesCSVMutation();

  const header = [
    { field: 'pageNumber', title: '#', width: '50px' },
    {
      field: 'path',
      title: 'Page',
    },
    {
      field: 'have_wallets',
      title: 'Wallet Detected',
    },
    {
      field: 'adoption',
      title: 'Web3 Adoption',
    },
  ];

  useEffect(() => {
    if (eCommercePagesData?.length) {
      const temp = eCommercePagesData.map((elem, index) => ({
        index: index + 1 + (page - 1) * (+limit),
        path: elem.path ? decodeURIComponent(elem.path) : '/',
        walletDetected: getCardValue(elem.have_wallets),
        adoption: `${elem.adoption.toFixed(1)}%`,
      }));
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [eCommercePagesData, dispatch, searchParams, setSearchParams]);

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);


  return (
    <div className="row">
      {(isECommercePagesDataLoading || isECommercePagesDataFetching) && !eCommercePagesData
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : (
          <>
            <Header
              title="Web3 Adoption per Page"
              info={counter(
                eCommercePagesData ? eCommercePagesData.length !== 0 ? eCommercePagesData[0].number_of_paths : 0 : 0,
                page,
                limit,
              )}
              actions={
                <Export
                  onClick={() => getWebSDKECommercePagesCSV({
                    id,
                    limit: 50000,
                    orderBy,
                    order: order ? 'desc' : 'asc',
                    ...filterParams,
                  })}
                  tooltip={"Export E-Commerce pages to CSV"}
                  tooltipWarnings={["No more than 50,000 profiles will be downloaded"]}
                />
              }
            />
            <div className="w-100">
              <Table
                total={eCommercePagesData ? eCommercePagesData.length !== 0 ? eCommercePagesData[0].number_of_paths : 0 : 0}
                data={tableData}
                desc={order}
                setDesc={setOrder}
                ordering={orderBy}
                setOrdering={setOrderBy}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={header}
                isLoading={isECommercePagesDataLoading || isECommercePagesDataFetching}
                min={25}
              />
            </div>
            {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
          </>
        )}
    </div>
  );
};

export default PagesList;
