import React from 'react';
import ModalTitle from '../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../components/base/Modal/ModalFooter';
import styles from './FormDraftModal.module.scss';

const FormModal = ( { closeModal, onSubmit, isLoading, other }) => {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  }

  return (
    <>
      <ModalTitle title="Draft Form" />

      <span className={styles.text}>
        {other 
          ? 'The form selected for others visitors is still in draft mode. Do you want to publish it now so you can launch your web campaign?' 
          : 'The form selected for your campaign is still in draft mode. Do you want to publish it now so you can launch your web campaign?'}
        
      </span>
      
      <ModalFooter>
        <button
          type="button"
          className="outline-button border border-1"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="regular-button d-flex gap-2"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading && <div className={styles.loading_spinner} />}
          Publish the Form
        </button>
      </ModalFooter>
   </>
  )
}

export default FormModal
