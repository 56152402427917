import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styles from '../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import customLIstStyles from './SaveCustomListModal.module.scss';
import Arrow from '../../../assets/icons/select_arrow.svg';
import Search from '../../../assets/icons/search.svg';
import { audiencesApi } from '../../../api/audiences';
import Loader from '../../../components/base/Loader';
import uniqueId from '../../../utils/uniqueId';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import Modal from '../../../components/base/Modal';
import ModalTitle from '../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../components/base/Modal/ModalFooter';

const SaveCustomListModal = ({
  onCancel, onSubmit, setCustomListName, customListName, setCustomListId,
}) => {
  const inputRef = useRef();
  const [showAddModal, setShowAddModal] = useState(false);
  const [suggestedList, setSuggestedList] = useState([]);
  const {
    data: customListsData,
    isLoading: isCustomListsDataLoading,
  } = audiencesApi.useGetTeamSegmentsQuery({
    limit: 100000, itemType: 'custom list',
  }, {
    skip: !showAddModal,
  });
  const [createCustomListAddresses, createCustomListAddressesResult] = audiencesApi.useSubmitCustomListMutation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (showAddModal) {
          setShowAddModal(false);
          setCustomListName('');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAddModal]);

  useEffect(() => {
    if (customListsData?.results) {
      const dataName = customListsData?.results.map((elem) => elem.name);
      const filterName = dataName
        .filter((elem) => elem.toLowerCase().includes(customListName.toLowerCase()));
      setSuggestedList(filterName);
    } else {
      setSuggestedList([]);
    }
  }, [customListsData?.results, customListName]);

  useEffect(() => {
    if (createCustomListAddressesResult.isSuccess) {
      showSuccessMessage('List was successfully created');
      setCustomListName(createCustomListAddressesResult?.originalArgs?.name);
      setCustomListId(createCustomListAddressesResult?.data?.id);
      createCustomListAddressesResult.reset();
      setShowAddModal(false);
    }
    if (createCustomListAddressesResult.isError) {
      if (createCustomListAddressesResult.error.status === 400) {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length !== 0
          && 'List with the same name already exists');
      } else {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length
          !== 0 && 'Something went wrong');
      }
      createCustomListAddressesResult.reset();
    }
  }, [createCustomListAddressesResult]);

  const modalContent = useMemo(() => {
    if (isCustomListsDataLoading) {
      return (
        <div className="my-4">
          <Loader />
        </div>
      );
    }
    if (suggestedList.length) {
      return (
        <div className="d-flex flex-column gap-2">
          <span className={customLIstStyles.title_group}>Select an existing list or create new one</span>
          <div className={customLIstStyles.wrapper_list}>
            {suggestedList.map((elem) => (
              <div
                key={uniqueId('name_list')}
                className={customLIstStyles.hover}
              >
                <button
                  className={`${customLIstStyles.name_list_wrapper} text-truncate my-2 w-100`}
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomListName(elem);
                    setCustomListId(customListsData?.results.filter((item) => item.name === elem)[0].id);
                    setCustomListName(elem);
                    setShowAddModal(false);
                  }}
                >
                  {elem}
                </button>
                <div className={customLIstStyles.divider_list} />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }, [isCustomListsDataLoading, suggestedList]);

  return (
    <Modal onCancel={onCancel} loading={createCustomListAddressesResult.isLoading || createCustomListAddresses.isFetching}>
      <ModalTitle title="Save a list for this import" />
      <div className={`${styles.description} mb-0`}>
        <span>
          To save a list for this import,
          please provide a name for the list to be created or select existing
        </span>
        <div
          ref={inputRef}
          role="presentation"
          className={`${customLIstStyles.link_area} mt-2`}
          onClick={() => {
            setShowAddModal(true);
            setCustomListName('');
          }}
        >
          <div width="28px" height="28px" className="cursor-pointer">
            <Search />
          </div>
          <input
            placeholder="Search for the list or create a new one"
            type="text"
            className="w-100 p-1"
            maxLength={50}
            value={customListName}
            onChange={(e) => setCustomListName(e.target.value)}
          />
          <div className="d-flex align-items-center me-2">
            <div className={`${customLIstStyles.divider} me-2`} />
            <Arrow
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setShowAddModal(!showAddModal);
                setCustomListName('');
              }}
            />
          </div>
          {showAddModal && (
            <div
              role="presentation"
              className={`${customLIstStyles.modal_wrapper} position-absolute d-flex flex-column gap-2`}
              onClick={(e) => e.stopPropagation()}
            >
              {customListName
                ? (
                  <div className="d-flex flex-column gap-2">
                    <span className={customLIstStyles.title_group}>Create new list</span>
                    <button
                      className={`${customLIstStyles.name_wrapper} text-truncate`}
                      type="button"
                      disabled={!customListName}
                      onClick={() => createCustomListAddresses({
                        name: customListName,
                        addresses: [],
                      })}
                    >
                      {customListName}
                    </button>
                  </div>
                ) : null}
              {modalContent}
            </div>
          )}
        </div>
        <div className={`mt-3 ${customLIstStyles.no_cl}`}>
          You can upload file with no list creation.
        </div>
      </div>
      <ModalFooter>
        {onCancel && (
          <button
            type="button"
            className="outline-button border border-1"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
        )}
        <button
          type="button"
          className="regular-button"
          onClick={() => onSubmit()}
          disabled={showAddModal}
        >
          {!customListName ? 'Import without saving a list' : 'Import'}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SaveCustomListModal;
