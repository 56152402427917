import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import styles from './Attribution.module.scss';
import Filters from './Filters';
import { selectWebSDKFilterParam, selectWebSDKAttributionParam, setWebSDKAttributionParam } from '../../../store/reducers/webSDK';
import { webSDKApi } from '../../../api/webSDK';
import List from './List';
import { metricOptions } from '../../../utils/webSDK';
import { omitKey } from '../../../utils/object';

const AttributionTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const filterParams = useSelector(selectWebSDKFilterParam);
  const attributionParams = useSelector(selectWebSDKAttributionParam);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [orderBy, setOrderBy] = useState(searchParams.get('orderBy') || 'unique_visitors');
  const [order, setOrder] = useState(searchParams.get('order') !== 'false');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const [taggedEventsOptions, setTaggedEventsOptions] = useState([]);
  const [hasFetchedTaggedEvents, setHasFetchedTaggedEvents] = useState(false);

  const isTaggedOptionSelected = taggedEventsOptions.some((e) => e.value === attributionParams?.conversion_event)

  const {
    data,
    isLoading,
    isFetching,
  } = webSDKApi.useGetWebSDKAttributionListQuery(
    {
      id,
      limit: +limit,
      offset: (page - 1) * (+limit),
      orderBy,
      order: order ? 'desc' : 'asc',
      ...filterParams,
      ...omitKey(attributionParams, "conversion_event"),
      ...(isTaggedOptionSelected ? {tagged_conversion_event: attributionParams.conversion_event} : {conversion_event: attributionParams.conversion_event})
    },
    { skip: (attributionParams.conversion_event === null && attributionParams.custom_conversion_event === null)
      || !hasFetchedTaggedEvents
     }
  );

  useEffect(() => {
    return () => {
      dispatch(setWebSDKAttributionParam({ conversion_event: null, custom_conversion_event: null }));
    }
  }, [])

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page,
      orderBy,
      order,
      perPage: limit,
      ...filterParams,
      ...attributionParams,
    });
  }, [page, orderBy, order, limit, filterParams, attributionParams, setSearchParams, searchParams]);

  const title = `${metricOptions.find((elem) => elem.value === attributionParams?.metric)?.label}` || '';

  const {
    data: taggedEventsData,
  } = webSDKApi.useGetTaggedEventsQuery(
    {
      id,
      period: filterParams?.day
    },
    { skip: filterParams?.day === null || filterParams?.day === undefined }
  );

  useEffect(() => {
    if (taggedEventsData) {
      setTaggedEventsOptions(taggedEventsData.map((event) => ({
        label: event.event,
        value: event.event,
      })));
      setHasFetchedTaggedEvents(true);
    } else {
      setTaggedEventsOptions([]);
    }
  }, [taggedEventsData])

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column`}>
      <Filters 
        taggedEventsOptions={taggedEventsOptions} 
        hasFetchedTaggedEvents={hasFetchedTaggedEvents} 
      />
      <List
        data={data}
        isLoading={isLoading || isFetching || !hasFetchedTaggedEvents}
        page={page}
        setPage={setPage}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        order={order}
        setOrder={setOrder}
        limit={limit}
        setLimit={setLimit}
        title={title}
        showUniqueVisitorsColumn={!attributionParams.new_visitors_only}
        filterParams={filterParams}
        attributionParams={attributionParams}
        isTaggedOptionSelected={isTaggedOptionSelected}
      />
    </div>
  );
};

export default AttributionTab;
