import React, { useCallback, useEffect, useRef, useState } from 'react';
import useKeydown from '../../hooks/app/useKeydown';
import useOutsideClick from '../../hooks/app/useOutsideClick';
import CloseModalButton from '../../ui/modals/components/CloseModalButton';
import styles from './Modal.module.scss';
import RoundSpinner from '../RoundSpinner';

const Modal = ({ 
  children, 
  onCancel, 
  loading = false,
  size = 'normal', 
  position = 'center',
  onDrop = null,
  dragContent = null,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const containerRef = useRef(null);
  const onCancelRef = useRef(onCancel);

  useEffect(() => {
    onCancelRef.current = onCancel;
  }, [onCancel]);

  const handleCancel = useCallback(() => {
    if (!loading) {
      onCancelRef.current();
    }
  }, [loading, onCancelRef.current]);

  useKeydown('Escape', handleCancel);
  useOutsideClick(containerRef, handleCancel);

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [])

  const handleDrag = (e) => {
    if (onDrop) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    }
  };

  const handleDrop = (e) => {
    if (onDrop) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      onDrop(e);
    }
  }

  return (
    <div className={styles.background} tabIndex="-1" aria-hidden>
      {loading && <RoundSpinner />}
      <div 
        ref={containerRef}
        className={`${styles.container} ${size === 'normal' ? '' : size === 'large' ? styles.large : styles.xlarge} ${position === 'top' ? styles.top : ''}`} 
        onDragEnter={handleDrag}
      >
        { dragActive && onDrop && (
          <div
            className={styles.drag}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {dragContent}
          </div>
        )}

        <CloseModalButton onCancel={handleCancel} />

        <div className={styles.content_wrapper}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
