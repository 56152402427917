import React from 'react';
import Plus from '../../../../assets/icons/plus_white.svg';
import styles from './AddButton.module.scss';

const AddButton = ({ onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <button className={styles.button} type='button' onClick={handleClick}><Plus /></button>
  )
}

export default AddButton
