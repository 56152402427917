import React from 'react';
import styles from './Modal.module.scss';

const ModalErrorMessage = ({ show, message }) => {
  if (show) {
    return (
      <p className={`${styles.error_message}`}>{message}</p>
    )
  }
  return null;
}

export default ModalErrorMessage
