import React from 'react';
import styles from './ConditionSeparator.module.scss';

const ConditionSeparator = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.separatorLine} />
      <div className={styles.andButton}>AND</div>
      <div className={styles.separatorLine} />
    </div>
  )
}

export default ConditionSeparator
