import React from 'react';
import styles from './Modal.module.scss';

const ModalTitle = ({ title }) => {
  if (!title) return null;
  return (
    <div className={styles.title}>{title}</div>
  )
};

export default ModalTitle
