import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCondition, getConditionsData, getConditionsSelectedValues, removeCondition, removeSelectedValue } from '../../../../store/reducers/campaign';
import { conditionsConfig, getDefaultForCondition } from '../../options';
import Trash from '../../../../assets/icons/trash.svg';
import DateTime from '../Conditions/DateTime';
import { showErrorMessage } from '../../../../components/base/Notifications';
import Select from '../Conditions/Select';
import MultiSelect from '../Conditions/MultiSelect';
import TextWithValue from '../Conditions/TextWithValue';
import DropdownInputList from '../Conditions/DropdownInputList';
import LabeledAsyncMultiSelect from '../Conditions/LabeledAsyncMultiSelect';
import styles from './ConditionBlock.module.scss';
import Tags from '../Conditions/Tags';

const ConditionBlock = ({ id }) => {
  const dispatch = useDispatch();
  const data = useSelector(getConditionsData);
  const selectedData = data[id];
  const values = useSelector(getConditionsSelectedValues);
  const selectedValues = values[id];
  const selectedConditionData = conditionsConfig.find((condition) => condition.id === id);

  const handleRemove = () => {
    dispatch(removeCondition({conditionId: id}));
  }

  const getValue = (label) => {
    if (selectedValues) {
      return selectedValues[label];
    }
    showErrorMessage('Something went wrong. Please, refresh the page and try again.')
  }

  const getData = (label) => {
    if (selectedData) {
      return selectedData[label];
    }
    showErrorMessage('Something went wrong. Please, refresh the page and try again.')
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {selectedConditionData.label}
        <button className={styles.trash} type='button' onClick={handleRemove}><Trash /></button>
      </div>
      <div className={styles.content}>
        {selectedConditionData.inputs.map((input) => {
          if (input.preLabel && input.preValue) {
            let shouldHide = false;
            if (typeof input.preValue === "string") {
              if (input.preValue !== selectedValues[input.preLabel]?.value) {
                shouldHide = true;
              };
            } else if (Array.isArray(input.preValue)) {
              if (!input.preValue.some((val) => val === selectedValues[input.preLabel]?.value)) {
                shouldHide = true;
              };
            }
            if (shouldHide) {
              dispatch(removeSelectedValue({ conditionId: selectedConditionData.id, label: input.label }))
              return null;
            } else if (!selectedValues[input.label]) {
              const defaultValue = getDefaultForCondition(selectedConditionData.id, input.label);
              if (defaultValue) { 
                dispatch(addCondition({ conditionId: selectedConditionData.id, label: input.label, defaultValue }));
              }
            }
          }

          if (input.type === 'dateTime') {
            return <DateTime 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      labelText={input.displayedLabel} 
                      label={input.label} 
                      value={getValue(input.label)} 
                    />
          }
          if (input.type === 'dropdown') {
            return <Select 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)} 
                      data={getData(input.label)}
                      placeholder={input.placeholder}
                    />
          }
          if (input.type === 'multiSelect') {
            return <MultiSelect 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)} 
                      data={getData(input.label)}
                    />
          }
          if (input.type === 'textWithValue') {
            return <TextWithValue 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)} 
                      textBefore={input.textBefore}
                      textAfter={input.textAfter}
                      max={input.max ? input.max : null}
                    />
          }
          if (input.type === 'dropdownAndInputList') {
            return <DropdownInputList
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)}
                      data={getData(input.label)}
                   />
          }
          if (input.type === 'labeledAsyncMultiSelect') {
            return <LabeledAsyncMultiSelect 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      displayedLabel={input.displayedLabel}
                      value={getValue(input.label)}
                      type={input.dataType}
                      exclusionValue={input.exclusionLabel ? getValue(input.exclusionLabel) : null}
                   />
          }
          if (input.type === 'tags') {
            return <Tags
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)}
                      data={getData(input.label)}
                  />;
          }
        })}
      </div>
    </div>
  )
}

export default ConditionBlock;
