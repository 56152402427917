import React from 'react';
import styles from './ConfirmModal.module.scss';
import Modal from '../../../base/Modal';
import ModalTitle from '../../../base/Modal/ModalTitle';
import ModalFooter from '../../../base/Modal/ModalFooter';

const ConfirmModal = ({
  title = 'Confirm ?',
  description = '',
  buttonName = 'Confirm',
  onSubmit,
  onCancel,
  loading,
  link,
  deleteMode = false,
}) => {
  return (
    <Modal onCancel={onCancel} loading={loading}>
      <ModalTitle title={title} />
      <div className={`${styles.description}`}>
        <span>
          {description}
        </span>
        {link
          ? (
            <div>
              {link}
            </div>
          )
          : null}
      </div>
      <ModalFooter>
        <div className={styles.footer}>
          {onCancel && (
            <button
              type="button"
              className="outline-button border border-1"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          )}
          <button
            type="button"
            className={`regular-button ${deleteMode ? 'delete_button' : ''}`}
            onClick={() => onSubmit()}
          >
            {buttonName}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
