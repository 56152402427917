import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';
import Investments from './Investments';
import { profilesApi } from '../../../api/profiles';
import { isWalletAddress } from '../../../utils/supportedBlockchains';

const Assets = ({ portfolioValue, address }) => {
  const { name } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [checkedToken, setCheckedToken] = useState(searchParams.get('sort') !== 'nft');
  const [checkedNft, setCheckedNft] = useState(searchParams.get('sort') !== 'token');
  const [type, setType] = useState(searchParams.get('sort'));
  const [orderBy, setOrder] = useState(searchParams.get('orderBy') || 'value');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);

  const {
    data: investments,
    isLoading: isInvestmentsLoading,
    isFetching: isInvestmentsFetching,
  } = profilesApi.useGetWalletInvestorPortfolioDataQuery({
    address: isWalletAddress(name) ? `?address=${name}` : `?alid=${name}&address=${address}`,
    asset_type: type || '',
    limit: +limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
    chain: 'ethereum',
    page,
  }, { skip: !isWalletAddress(name) && !address });

  useEffect(() => {
    if (checkedNft && checkedToken) {
      setType('');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        sort: '',
      });
    }
    if (checkedNft && !checkedToken) {
      setType('nft');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        sort: 'nft',
      });
    }
    if (!checkedNft && checkedToken) {
      setType('token');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        sort: 'token',
      });
    }
  }, [checkedNft, checkedToken]);

  // TODO: add validation to the supported blockchain
  if (true) {
    return (
      <Investments
        investments={investments}
        isLoading={isInvestmentsLoading || isInvestmentsFetching}
        limit={limit}
        setLimit={setLimit}
        portfolioValue={portfolioValue}
        page={page}
        desc={desc}
        setPage={setPage}
        setDesc={setDesc}
        setCheckedToken={setCheckedToken}
        setCheckedNft={setCheckedNft}
        checkedToken={checkedToken}
        checkedNft={checkedNft}
        order={orderBy}
        setOrder={setOrder}
      />
    );
  }
  return <NotSupportedBlockchain />;
};

export default Assets;
