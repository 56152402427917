import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './WebCampaigns.module.scss';

const NoCampaign = ({ websiteId }) => {
  const navigate = useNavigate();
  
  const handleCreateCampaign = () => {
    navigate('/form/campaign', { state: { website: websiteId } });
  }

  return (
    <div className={styles.no_campaign_wrapper}>
      <span className={styles.no_campaign_text}>
        You can show a Form created on Absolute Labs in a modal on your site, based on various criteria.
      </span>
      <button
        type="button"
        className="regular-button text-nowrap"
        onClick={() => handleCreateCampaign()}
        >
        Create Your First Campaign
      </button>
    </div>
  )
}

export default NoCampaign
