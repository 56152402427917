export const isEmptyObject = (obj) => 
  obj && typeof obj === 'object' && obj.constructor === Object && Object.keys(obj).length === 0;

export const omitKey = (obj, key) => {
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

export const getMaxValueByProperty = (array, property) => {
  if (!Array.isArray(array)) {
    return undefined;
  }
  return array.reduce((max, obj) => 
      obj[property] > max ? obj[property] : max, 0
  );
}
