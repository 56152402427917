import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { foldersApi } from '../../../../../api/folders';
import emitter from '../../../../../utils/emitter';
import { showErrorMessage, showSuccessMessage } from '../../../../base/Notifications';
import Close from '../../../../../assets/icons/close.svg';
import styles from '../foldersModal.module.scss';
import useFolders from '../../../../hooks/useFolder';
import Modal from '../../../../base/Modal';
import ModalTitle from '../../../../base/Modal/ModalTitle';
import ModalFooter from '../../../../base/Modal/ModalFooter';

const MultiValue = (props) => {
  const { data } = props;

  const { parentName } = data;
  let shortParentName = '';

  if (parentName) {
    if (parentName.length > 20) {
      shortParentName = `${parentName.substring(0, 20)}...`;
    } else {
      shortParentName = parentName;
    }
  }

  return (
    <components.MultiValue {...props}>
      {shortParentName
        ? (
          <>
            <span className={styles.parent}>{shortParentName}</span>
            <span className={styles.multi_value_separator_container}>
              <div className={styles.multi_value_separator}>▸ </div>
            </span>
          </>
        )
        : null}
      {data.label}
    </components.MultiValue>
  );
};

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Close />
  </components.MultiValueRemove>
);

const Option = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      {data.parentName
        ? (
          <>
            <span className={styles.parent}>
              {data.parentName}
            </span>
            <span className={styles.option_separator}>▸ </span>
          </>
        )
        : null}
      <span>{data.label}</span>
    </components.Option>
  );
};

const selectStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: state.isFocused ? '#F1F4F8' : 'inherit',
    ':hover': {
      backgroundColor: '#F1F4F8',
    },
  }),
  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: '#F1F4F8',
    borderRadius: '4px',
  }),
  multiValueRemove: (defaultStyles) => ({
    ...defaultStyles,
    ':hover': {
      backgroundColor: 'inherit',
    },
  }),
};

const ManageFoldersModal = ({ itemId, itemType, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectionLoading, setIsSelectionLoading] = useState(false);
  const [displayedFolders, setDisplayedFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const {
    data: folders,
    isLoading: isLoadingFolders,
  } = foldersApi.useGetFoldersQuery({
    type: itemType === 'custom_lists' ? 'audiences' : itemType,
  });
  const [manageFolders] = foldersApi.useManageFoldersMutation();
  const {
    folders: itemFolders,
    isFetching: isItemFoldersFetching,
    isLoading: isItemFoldersLoading,
    refetch: refetchItemFolders,
  } = useFolders(itemId, itemType);

  const handleCloseModal = () => {
    setSelectedFolders([]);
    setIsSelectionLoading(false);
    closeModal();
  };

  useEffect(() => {
    refetchItemFolders();
  }, []);

  useEffect(() => {
    setIsSelectionLoading(true);
    const tempSelectedFolders = [];
    if (itemFolders && itemFolders.length > 0) {
      itemFolders.forEach((itemFolder) => {
        const tempFolder = displayedFolders.find((f) => f.id === itemFolder.id);
        if (tempFolder) {
          tempSelectedFolders.push({
            id: itemFolder.id,
            name: itemFolder.name,
            parentName: tempFolder.parentName,
          });
        }
      });
    }
    setSelectedFolders(tempSelectedFolders.sort((a, b) => a.name.localeCompare(b.name)));
    setIsSelectionLoading(false);
  }, [displayedFolders, itemFolders]);

  useEffect(() => {
    if (!folders || folders.length === 0) {
      setDisplayedFolders([]);
      return;
    }
    const dFolders = [];
    folders.forEach((f) => {
      dFolders.push({
        id: f.id,
        name: f.name,
        parentName: null,
      });
      f.sub_folders.forEach((sf) => {
        dFolders.push({
          id: sf.id,
          name: sf.name,
          parentName: f.name,
        });
      });
    });
    setDisplayedFolders(dFolders);
  }, [folders]);

  useEffect(() => {
    setIsLoading(
      isItemFoldersFetching
      || isItemFoldersLoading
      || isLoadingFolders
      || isSelectionLoading,
    );
  }, [
    isItemFoldersFetching,
    isItemFoldersLoading,
    isLoadingFolders,
    isSelectionLoading,
  ]);

  const refetch = () => {
    if (itemType === 'custom_lists' || itemType === 'audiences') {
      emitter.emit('refetch_audiences');
    } else if (itemType === 'flows') {
      emitter.emit('refetch_flows');
    } else if (itemType === 'forms') {
      emitter.emit('refetch_forms');
    }
  };

  const handleSave = async () => {
    const result = await manageFolders(
      {
        id: itemId,
        type: itemType,
        folders: selectedFolders.map((sf) => sf.id),
        folderType: itemType === 'custom_lists' ? 'audiences' : itemType,
      },
    );
    if (result.error) {
      showErrorMessage('Error during folders management');
      return;
    }
    showSuccessMessage('Folders was successfully updated');
    refetch();
    handleCloseModal();
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.key === 'Enter') {
        await handleSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedFolders]);

  return (
    <Modal onCancel={handleCloseModal}>
      <ModalTitle title="Manage folders" />

      <Select
        className="mb-4"
        placeholder="Search for the folder"
        maxMenuHeight={194}
        onChange={(options, action) => {
          if (action.action === 'select-option') {
            setSelectedFolders([...selectedFolders, displayedFolders.find((f) => f.id === action.option.value)]);
          } else if (action.action === 'remove-value') {
            setSelectedFolders(selectedFolders.filter((f) => f.id !== action.removedValue.value));
          } else if (action.action === 'clear') {
            setSelectedFolders([]);
          }
        }}
        value={selectedFolders.map((sf) => ({
          value: sf.id,
          label: sf.name,
          parentName: sf.parentName,
        }))}
        isLoading={isLoading}
        options={isLoadingFolders ? [] : displayedFolders.map((sf) => ({
          value: sf.id,
          label: sf.name,
          parentName: sf.parentName,
        }))}
        isMulti
        styles={selectStyles}
        components={{ MultiValue, MultiValueRemove, Option }}
      />

      <ModalFooter>
        <button
          type="button"
          className="outline-button border border-1"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="regular-button"
          onClick={handleSave}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ManageFoldersModal;
