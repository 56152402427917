import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Title from '../../../../components/base/Title';
import uniqueId from '../../../../utils/uniqueId';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import styles from './FormActivityGraph.module.scss';
import DailyChart from './DailyChart';
import Visitors from '../../../../assets/icons/trackedWebsite/Users.svg?url';
import ConversionRate from '../../../../assets/icons/forms/conversion_rate.svg?url';
import Responses from '../../../../assets/icons/forms/responses.svg?url';
import Views from '../../../../assets/icons/forms/views.svg?url';
import Clicks from '../../../../assets/icons/forms/click.svg?url';
import { formsApi } from '../../../../api/forms';
import WidgetCard from '../../../../components/ui/WidgetCard';
import { getCardValue, getPercent } from '../../../../utils/values';

const FormActivityGraph = ({
  url, formDataOverview, isFormLoading, isDataOverviewLoading, isStaticForm
}) => {
  const dispatch = useDispatch();
  const [widgetData, setWidgetData] = useState([]);

  const {
    data: formGraphChart,
    isLoading: isGraphLoading,
    isFetching: isGraphFetching,
    isSuccess: isGraphSuccess,
  } = formsApi.useFormResponseDailyChartQuery(url, { skip: !url });

  useEffect(() => {
    if (formDataOverview && !isDataOverviewLoading) {
      setWidgetData([
        {
          title: 'Views',
          value: getCardValue(formDataOverview?.views),
          icon: Views,
          info: 'Number of views of your form',
        },
        {
          title: 'Visitors',
          value: convertNumber(formDataOverview?.visitors),
          icon: Visitors,
          info: 'Number of visitors of your form',
        },
        isStaticForm 
          ?
            {
              title: 'Clicks',
              value: getCardValue(formDataOverview?.btn_clicks),
              icon: Clicks,
              info: 'Number of clicks on your form',
            }
          :
            {
              title: 'Responses',
              value: getCardValue(formDataOverview?.response_count),
              icon: Responses,
              info: 'Number of responses to your form',
            },
        {
          title: 'Conversion Rate',
          value: getPercent(isStaticForm ? formDataOverview?.btn_clicks : formDataOverview?.response_count, formDataOverview?.visitors),
          icon: ConversionRate,
          info: isStaticForm ? 'Percentage of visitors who clicked a button in your form' : 'Percentage of visitors who responded to your form',
        },
      ]);
    }
  }, [dispatch, formDataOverview]);

  return (
    <div className="asset-container">
      <div className="title-gap d-flex align-items-center gap-2">
        <Title>Overview</Title>
      </div>
      <div className="dropdown-divider w-100" />
      
      <div className={styles.wrapper}>
        <div className="info-wrapper-top d-flex justify-content-between gap-4">
          {(widgetData.length ? widgetData : [{}, {}, {}, {}]).map((item) => (
            <WidgetCard
              key={uniqueId('form-widget')}
              icon={item.icon}
              info={item.info}
              growth={item.growth}
              title={item.title}
              value={item.value}
              isLoading={isFormLoading || isDataOverviewLoading || item.isLoading}
            />
          ))}
        </div>
        <DailyChart
          graphData={formGraphChart}
          isFormLoading={isFormLoading}
          isGraphDataLoading={isGraphLoading || isGraphFetching}
          isSuccess={isGraphSuccess}
          isStaticForm={isStaticForm}
        />
      </div>

    </div>
  );
};

export default FormActivityGraph;
