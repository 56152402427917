import React from 'react';
import ReactSwitch from 'react-switch';
import styles from './TextWithToggle.module.scss';

const TextWithToggle = ({ text, isChecked, onChange }) => {
  const handleChange = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.text}>{text}</span>
      <ReactSwitch
        checked={isChecked}
        onChange={handleChange}
        checkedIcon={false}
        uncheckedIcon={false}
        height={16}
        width={28}
        handleDiameter={14}
        onColor="#109cf1"
        offColor="#adb5bd"
      />
    </div>
  )
}

export default TextWithToggle
