import React, { useState, useRef, useEffect } from 'react';
import emitter from '../../../../../utils/emitter';
import { showErrorMessage, showSuccessMessage } from '../../../../base/Notifications';
import { foldersApi } from '../../../../../api/folders';
import styles from '../foldersModal.module.scss';
import Modal from '../../../../base/Modal';
import ModalTitle from '../../../../base/Modal/ModalTitle';
import ModalFooter from '../../../../base/Modal/ModalFooter';

const RenameFolderModal = ({
  closeModal, folder, type,
}) => {
  const [changeFolder] = foldersApi.useChangeFolderMutation();
  const [folderName, setFolderName] = useState(folder.name);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleConfirm = async () => {
    if (!folderName) {
      showErrorMessage('New folder name cannot be empty');
      return;
    }
    const result = await changeFolder({ id: folder.id, name: folderName, type });
    if (result.error) {
      showErrorMessage('Error during folder update');
      return;
    }
    showSuccessMessage('Folder was successfully renamed');
    emitter.emit('refetch_folders');
    closeModal();
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.key === 'Enter') {
        await handleConfirm();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [folderName]);

  return (
    <Modal onCancel={closeModal}>
      <ModalTitle title="Rename folder" />

      <input
        ref={inputRef}
        type="text"
        className={`form-control mb-4 ${styles.input}`}
        placeholder="Add (sub)folder name here"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
      />

      <ModalFooter>
        <button
          type="button"
          className="outline-button border border-1"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="regular-button"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RenameFolderModal;
