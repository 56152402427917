import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/forms';

export const formsApi = createApi({
  reducerPath: 'formsAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Form'],
  endpoints: (build) => ({
    getForms: build.query({
      query: (arg) => ({
        url: `${BASE_API}/?limit=${arg.limit}${
          arg.offset ? `&offset=${arg.offset}` : ''}${
          arg.order_by ? `&order_by=${arg.order_by}` : ''}${
          arg.order ? `&order=${arg.order}` : ''}${
          arg.additionalParams ? arg.additionalParams : ''}${
          arg.status ? `&status=${arg.status}` : ''}${
          arg.search ? `&search=${encodeURIComponent(arg.search)}` : ''}${
          arg.folders ? `&folders=${arg.folders}` : ''}${
          arg.my_items ? `&my_items=${arg.my_items}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
    getFormById: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
    saveForm: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: payload,
      }),
      providesTags: () => ['Form'],
    }),
    changeForm: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/`,
        method: 'PATCH',
        body: arg.payload,
      }),
      providesTags: () => ['Form'],
    }),
    deleteForm: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['Form'],
    }),
    deleteMultipleForms: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/multiple-delete/`,
        method: 'DELETE',
        body: body.map((i) => ({
          object_id: i.id,
          object_type: i.type,
        })),
      }),
      providesTags: () => ['Form'],
    }),
    copyForm: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/copy/`,
        method: 'POST',
      }),
      providesTags: () => ['Form'],
    }),
    pauseForm: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/pause/`,
        method: 'POST',
      }),
      providesTags: () => ['Form'],
    }),
    closeForm: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/close/`,
        method: 'POST',
      }),
      providesTags: () => ['Form'],
    }),
    continueForm: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/continue/`,
        method: 'POST',
      }),
      providesTags: () => ['Form'],
    }),
    uploadImage: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/form-image/`,
        method: 'POST',
        body: payload,
      }),
      providesTags: () => ['Form'],
    }),
    formResponseDailyChart: build.query({
      query: (url) => ({
        url: `${BASE_API}/${url}/response-daily-chart/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
    responsesTopBlock: build.query({
      query: (url) => ({
        url: `${BASE_API}/${url}/response-stats/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
    responseList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.url}/response-list/?limit=${arg.limit}${
          arg.offset ? `&offset=${arg.offset}` : ''}${
          arg.order_by ? `&order_by=${arg.order_by}` : ''}${
          arg.order ? `&order=${arg.order}` : 'desc'}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
    getFormFoldersById: build.query({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/?folder_only=true`,
        method: 'GET',
      }),
      transformResponse: (response) => response.folders,
      providesTags: () => ['Form'],
    }),
    getResponseListCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/response-list-csv/?limit=${arg.limit}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}`,
        method: 'GET',
      }),
    }),
    getDetailsById: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/detailed-stats/?limit=${arg.limit}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['Form'],
    }),
    getDetailsCSV: build.mutation({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/detailed-stats-csv/`,
        method: 'GET',
      }),
    }),
    getFormCampaigns: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/web-campaigns/?limit=${arg.limit}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.status
          ? `&status=${arg.status}` : ''}${arg.search 
          ? `&search=${encodeURIComponent(arg.search)}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
  }),
});
