import { convertNumber, convertSciTruncSmallCommaBig, dividerCommaFormat } from '../tools/NumberConverterTool';
import { toMaxNDecimals } from './percent';
import { ethFormat } from './singleAssetPage/parseData';

const formatValue = (value, hyphen, formatFn, prefix = '', suffix = '') => {
  if (hyphen) {
    return value ? `${prefix}${formatFn(value)}${suffix}` : '-';
  }
  return value || value === 0 ? `${prefix}${formatFn(value)}${suffix}` : '?';
};

export const getFormatedNumber = (number, decimals = 0) => {
  if (number) {
    return convertNumber(number.toFixed(decimals));
  }
  return 0;
};

export const getCardValue = (value, hyphen = false) => formatValue(
  value,
  hyphen,
  getFormatedNumber,
);

export const getFormattedValue = (value, options = {}) => {
  const { hyphen = false, decimals = 0, prefix = '', suffix = '' } = options;

  return value ? `${prefix}${getFormatedNumber(value, decimals)}${suffix}` : hyphen ? '-' : '?';
};

export const getPrice = (value, hyphen = false) => formatValue(value, hyphen, ethFormat, '$');

export const getEthNumber = (value, hyphen = false) => formatValue(value, hyphen, ethFormat);

export const getEthPrice = (value, hyphen = false) => formatValue(value, hyphen, ethFormat, '$');

export const getItems = (itemsCount) => {
  let items = '?';
  if (itemsCount) {
    const itemsCountToDisplay = itemsCount > 1000
      ? convertNumber(+itemsCount.toFixed(0))
      : convertNumber(+itemsCount.toFixed(2));
    items = dividerCommaFormat(itemsCountToDisplay);
  }
  return [`${items}`];
};

export const getItemsPerHolder = (itemsCount, holdersCount) => {
  let holders = '?';
  if (itemsCount && holdersCount && holdersCount !== 0) {
    const result = (itemsCount / holdersCount);
    const resultToDisplay = result > 1000
      ? convertNumber(+result.toFixed(0))
      : convertNumber(+result.toFixed(2));
    holders = dividerCommaFormat(resultToDisplay);
  }
  return [`${holders} items per holder`];
};

const getGrowthData = (percent, previousValue, displayedValue, hideZero) => ({
  percent: toMaxNDecimals(percent),
  previousValue: `
  ${
  percent === 0
    ? ''
    : percent > 0
      ? 'Up '
      : 'Down '}
  ${toMaxNDecimals(percent)}% from last week (was ${displayedValue || previousValue})`,
  hideZero,
});

export const getGrowth = (current, previous, displayedValue = null, hideZero = false) => {
  if (current === null
    || current === undefined) return null;

  if (current === 0 && previous !== null && previous !== undefined) {
    return {
      percent: '-',
      previousValue: `Was ${displayedValue || previous} last week`,
      hideZero,
    };
  }

  if (previous === 0) {
    return {
      percent: '+',
      previousValue: 'Was 0 last week',
      hideZero,
    };
  }

  if (previous === null || previous === undefined) {
    return {
      percent: '+',
      previousValue: 'No value last week',
      hideZero,
    };
  }

  const percent = ((current - previous) / previous) * 100;
  return getGrowthData(percent, previous, displayedValue, hideZero);
};

export const getDays = (value, hyphen = false) => formatValue(
  value,
  hyphen,
  (val) => val,
  '',
  ' days',
);

export const getDataWithPercent = (number, divider) => {
  if (!number) {
    return '0 (0%)';
  }
  const numberToDisplay = convertSciTruncSmallCommaBig(number);
  if (!divider || divider === 0) {
    return numberToDisplay;
  }

  const percent = ((number / divider) * 100).toFixed(0);

  return `${numberToDisplay} (${percent}%)`;
};

export const getPercent = (number, divider, decimals = 0, hyphen = false) => {
  const nothing = hyphen ? '-' : '?';
  if (number === null
    || number === undefined
    || divider === null
    || divider === undefined) {
    return nothing;
  }

  if (number === 0) {
    return '0%';
  }

  if (divider === 0) {
    return nothing;
  }

  const percent = ((number / divider) * 100).toFixed(decimals);
  return `${percent}%`;
};
