import React, {useCallback} from 'react';
import Loader from '../../../base/Loader';
import NoData from '../../../base/NoData';
import uniqueId from '../../../../utils/uniqueId';
import styles from './ValueBased.module.scss';
import Highlight from '../../CircleChart/Highlight';
import CardTitle from '../../CardTitle';
import OneBarChart from '../../../base/OneBarChart';
import TruncatedText from '../../../base/TruncatedText';

const HorizontalValueBarChart = ({
  title, highlightText, highlightValue, data, isLoading, info, legend, growth,
}) => {
  const maxValue = Math.max(
    ...data.map((item) => (item.value)),
  );
  const getValue = useCallback((value) => {
    let res = '-';
    if (value) {
      if (title === 'Conversion Rate Increase') {
        res = `${value}x`
      } else {
        res = `${value}%`
      }
    }
    return res
  }, [data])


  return (
    <div className={`${styles.wrapper} h-100`}>
      <CardTitle title={title} info={info} />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
      ) : (
        data && data.length ? (
          <>
            <div className={styles.card_content}>
              {highlightValue ? (
                <div className={styles.subTitle}>
                  <Highlight text={highlightText} value={highlightValue} growth={growth} />
                </div>
              ) : null}
              <div className={styles.chart_container}>
              {data.map((item) => (
                <div key={uniqueId('Chart_row')} className={styles.row}>
                    {item.logo ? item.logo : null}
                  <div className={styles.row_right}>
                    <div className={styles.text}>
                      <TruncatedText className={styles.name} text={item.name} />
                      <span className={styles.notable_count}>
                        {getValue(item.value)}
                      </span>
                    </div>
                    <OneBarChart value={item.value} max={maxValue} />
                  </div>
                </div>
              ))}
              </div>
            </div>
            {legend ? (
              <div className="d-flex">
                <div className="d-flex align-items-center gap-2 asset-wrap">
                  <div className="dot" />
                  <span>
                    {`${legend}`}
                  </span>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div>
            <NoData />
          </div>
        ))}
    </div>
  );
};

export default React.memo(HorizontalValueBarChart);
