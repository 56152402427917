import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../../../store/reducers/user';
import Upload from '../../../../../assets/icons/upload.svg';
import Trash from '../../../../../assets/icons/trash_asset.svg';
import { webSDKApi } from '../../../../../api/webSDK';
import { showErrorMessage, showSuccessMessage } from '../../../../../components/base/Notifications';
import styles from './WebSDKCreationModal.module.scss';
import Modal from '../../../../../components/base/Modal';
import ModalFooter from '../../../../../components/base/Modal/ModalFooter';
import ModalSection from '../../../../../components/base/Modal/ModalSection';
import { ModalInputText } from '../../../../../components/base/Modal/ModalInput';
import ModalErrorMessage from '../../../../../components/base/Modal/ModalErrorMessage';
import ModalTitle from '../../../../../components/base/Modal/ModalTitle';
import Switch from '../../../../Forms/CreateForm/Components/ModalSettings/Switch';
import InfoIcon from '../../../../../assets/icons/info_small_raw.svg';
import Tooltip from '../../../../../components/ui/Tooltip';

const WebSDKCreationModal = ({
  onCancel,
  loading,
}) => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [urlImage, setUrlImage] = useState('');
  const inputRef = useRef(null);
  const user = useSelector(getUser);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [createWebSDK, createWebSDKResult] = webSDKApi.useCreateWebSDKMutation();

  const isValidImageType = (imgFile) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
    return validTypes.includes(imgFile.type) && imgFile.name.match(/\.(jpg|jpeg|png|gif)$/);
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (!e.target.files || !isValidImageType(e.target.files[0])) {
      showErrorMessage('Please select a valid image file. JPG, PNG, GIF are supported.');
    } else {
      setUrlImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleDrop = (e) => {
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (!isValidImageType(e.dataTransfer.files[0])) {
        showErrorMessage('Please select a valid image file. JPG, PNG, GIF are supported.');
      } else {
        setUrlImage(URL.createObjectURL(e.dataTransfer.files[0]));
        setFile(e.dataTransfer.files[0]);
      }
    }
  };

  const handleECommerceChange = () => {
    setValue('is_ecommerce', !watch('is_ecommerce'));
  };


  const clearAsset = () => {
    setUrlImage('');
    setFile(null);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onSubmit = (onSubmitData) => {
    const formData = new FormData();
    if (file) {
      Object.keys(onSubmitData).forEach((elem) => {
        if (onSubmitData[elem] !== null) {
          if (elem === 'logo') {
            formData.append(elem, file);
          } else {
            formData.append(elem, onSubmitData[elem]);
          }
        }
      });
    } else {
      Object.keys(onSubmitData).forEach((elem) => {
        if (onSubmitData[elem] && elem !== 'logo') {
          formData.append(elem, onSubmitData[elem]);
        }
      });
      formData.append('logo', '');
    }
    formData.append('user', user.id);
    createWebSDK(formData);
  };

  useEffect(() => {
    if (createWebSDKResult.isSuccess) {
      showSuccessMessage('Website was successfully registered');
      onCancel();
      navigate(`/tracked-website/${createWebSDKResult.data.id}`);
    }
    if (createWebSDKResult.isError) {
      showErrorMessage(createWebSDKResult.isError?.error?.data?.detail
          || 'Something went wrong. Try another website name ');
    }
  }, [createWebSDKResult, navigate, onCancel]);

  useEffect(() => {
    setValue('is_ecommerce', true);
  }, []);


  const assetsContent = useMemo(() => {
    if (file) {
      return (
        <div className={styles.label_file_showing}>
          <div className="d-flex align-items-center w-75 gap-2">
            <div className={styles.img_asset}>
              <img src={urlImage} alt="img" width="50px" height="50px" />
            </div>
            <span className="text-truncate">{file?.name || ''}</span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              type="button"
              className={`${styles.upload_button} cursor-pointer`}
              onClick={() => clearAsset()}
            >
              <Trash />
            </button>
            <label {...register('logo')} htmlFor="input-file-upload">
              <input
                id="input-file-upload"
                ref={inputRef}
                type="file"
                accept=".jpg,.png,.gif"
                className={styles.input_file_upload}
                onChange={handleChange}
              />
              <button
                type="button"
                className={`${styles.upload_button} ${styles.upload_color}`}
                onClick={onButtonClick}
              >
                <Upload />
              </button>
            </label>
          </div>
        </div>
      );
    }

    return (
      <label
        htmlFor="input-file-upload"
        className={styles.label_file_upload}
      >
        <input
          id="input-file-upload"
          ref={inputRef}
          type="file"
          accept=".jpg,.png,.gif"
          className={styles.input_file_upload}
          onChange={handleChange}
        />
        <button
          type="button"
          className={styles.upload_button}
          onClick={onButtonClick}
        >
          <div className="d-flex align-items-center justify-content-center gap-1">
            <Upload />
            <span className="w-100 p-3">Drag & Drop Image or click to upload</span>
          </div>
        </button>
      </label>
    );
  }, [file]);

  return (
    <Modal 
      onCancel={onCancel} 
      dragContent={(
        <div className={styles.upload_wrapper}>
          <div className={styles.upload_img} />
          Drop file anywhere to upload
        </div>
      )}
      onDrop={handleDrop}
      loading={loading 
        || createWebSDKResult.isLoading 
        || createWebSDKResult.isFetching
      }
    >
      <ModalTitle title="Track a Website" />
      <ModalSection title="Website name" titleInfo="The name that will appear in your insights dashboard">
        <ModalInputText
          placeholder="Enter name"
          onChange={(e) => {
            setValue('website_name', e.target.value);
          }}
          formProps={{...register('website_name', { required: 'Website name is required' })}}
        />
        <ModalErrorMessage show={errors?.website_name} message={errors?.website_name?.message} />
      </ModalSection>

      <ModalSection title="Upload logo" titleInfo="File types supported: JPG,PNG,GIF" optional>
        {assetsContent}
      </ModalSection>
      
      <ModalSection title="Website domain" titleInfo="Comma-separated list of domains to track. All subdomains will also be tracked.">
        <ModalInputText
          placeholder="Enter domain"
          maxLength={1000}
          onChange={(e) => {
            setValue('domains', e.target.value);
          }}
          formProps={{...register('domains', { required: 'Website domain is required' })}}
        />
        <ModalErrorMessage show={errors?.domains} message={errors?.domains?.message} />
      </ModalSection>

      <ModalSection row>
        <Switch
          text="E-commerce site"
          checked={watch('is_ecommerce')}
          onChange={handleECommerceChange}
        />
        <div
          data-for="e-commerce-toggle"
          data-tip="show"
        >
          <InfoIcon height="15px" width="15px" color="#C2CFE0" />
        </div>
        <Tooltip
          id="e-commerce-toggle"
          info="Sites marked as e-commerce will have extra stats around orders, customers and conversion."
        />
      </ModalSection>

      <ModalFooter>
        <button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          className="regular-button"
        >
          Track
        </button>
      </ModalFooter>
    </Modal>
  )
};

export default WebSDKCreationModal;
