import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../Tooltip';
import styles from './nameField.module.scss';
import tableStyles from '../../base/Table/Table.module.scss';

const NameField = ({
  id, text, search, link, tooltip, status,
}) => {
  const spanRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const [handledText, setHandledText] = useState(null);

  useEffect(() => {
    const span = spanRef.current;
    if (span) {
      setIsTextOverflowing(span.scrollWidth > span.clientWidth);
    }
  }, [text, handledText]);

  function cutText() {
    const index = text.toLowerCase().indexOf(search.toLowerCase());
    if (index === -1) {
      return [text, '', ''];
    }

    const before = text.substring(0, index);
    const searchInText = text.substring(index, index + search.length);
    const after = text.substring(index + search.length);

    return [before, searchInText, after];
  }

  useEffect(() => {
    if (status === 'restricted') {
      setHandledText(<span className={styles.name_unready}>{text}</span>);
    } else if (search.length === 0) {
      setHandledText(<span>{text}</span>);
    } else {
      const [before, searchInText, after] = cutText();
      setHandledText(
        <>
          {
            before && <span className={`${styles.lightText}`}>{before}</span>
          }
          <span className={`${styles.highlightText}`}>{searchInText}</span>
          {
            after && <span className={`${styles.lightText}`}>{after}</span>
          }
        </>,
      );
    }
  }, [text, search]);

  return (
    <>
      <span
        ref={spanRef}
        className={`${styles.nameContainer} ${tableStyles.underline_on_hover}`}
        data-for={`Name_${id}`}
        data-tip
      >
        <Link
          role="presentation"
          to={link}
          className={styles.name}
        >
          {handledText}
        </Link>
      </span>
      {((status !== 'restricted' && isTextOverflowing) || status === 'restricted') && (
        <Tooltip
          fixWidth
          id={`Name_${id}`}
          info={tooltip}
        />
      )}
    </>
  );
};

export default NameField;
