import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import { placeholderStyle } from '../../../../AddSegment/Condition/Condition.constants';
import styles from './DropdownMultiInput.module.scss';

const DropdownMultiInput = ({ 
  id,
  conditionId,
  label,
  utm, 
  utmData,
  tags, 
  allValues
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  
  const handleUtmChange = (newValue) => {
    dispatch(setSelectedValue({ conditionId, label, value: allValues.map((item) => 
      item.id === id ? { ...item, utm: newValue } : item
    ), }))
  }

  const handleTagsChange = (newValue) => {
    dispatch(setSelectedValue({ conditionId, label, value: allValues.map((item) => 
      item.id === id ? { ...item, tags: newValue } : item
    ), }));
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        dispatch(setSelectedValue({ conditionId, label, value: allValues.map((item) => 
          item.id === id ? { ...item, tags: [...tags, {
            label: inputValue,
            value: inputValue
          }] } : item
        ), }));
        setInputValue('');
        event.preventDefault();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.select_wrapper}>
        <Select
          styles={placeholderStyle}
          maxMenuHeight={194}
          className="w-100"
          value={utm}
          options={utmData}
          onChange={handleUtmChange}
          isShowValue
        />
      </div>

      <div className={styles.select_wrapper}>
        <CreatableSelect
            placeholder="Type a tag and press enter..."
            styles={placeholderStyle}
            components={{ DropdownIndicator: null }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleTagsChange}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            value={tags}
            isDisabled={!utm}
          />
      </div>
    </div>
  )
}

export default DropdownMultiInput
