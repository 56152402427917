import React from 'react';
import styles from './ConditionWrapper.module.scss';

const ConditionRow = ({ children }) => {
  return (
    <div className={styles.row}>
      {children}
    </div>
  )
}

export default ConditionRow
