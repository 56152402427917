import React from 'react';
import ShareStyles from './ShareFormModal.module.scss';
import Copy from '../../../../assets/icons/copy_outline.svg';
import { showInfoMessage } from '../../../../components/base/Notifications';
import Modal from '../../../../components/base/Modal';
import ModalTitle from '../../../../components/base/Modal/ModalTitle';

const ShareFormModal = ({
  onCancel, url,
}) => {
  const iFrameText = `
  <!--We recommend the width to be 600px-->
  <iframe 
    src="${url}"
    frameborder="0" style="max-width:600px; width:100%;" 
    onload="window.addEventListener(
      'message',(e)=>{
        if(e.data.type==='wrmSize'){
          this.style.height=e.data.height+'px';
        }
      },
    false);">
  </iframe>`;

  return (
    <Modal onCancel={onCancel}>
      <ModalTitle title="Publish form" />
      <div className={`${ShareStyles.description} d-flex justify-content-start`}>
        <span>Copy this link and send it to your audience</span>
      </div>
      <div className={`${ShareStyles.copy_container} d-flex justify-content-start`}>
        <p>{url}</p>
        <div>
          <Copy
            className={`${ShareStyles.copy_icon} cursor-pointer`}
            onClick={() => {
              navigator.clipboard.writeText(url);
              showInfoMessage('The URL was copied to your clipboard');
            }}
          />
        </div>
      </div>
      <div className={`${ShareStyles.description} d-flex justify-content-start`}>
        <span>Or copy the embedding code below</span>
      </div>
      <div className={`${ShareStyles.copy_container}`}>
        <p>
          {iFrameText}
        </p>
        <div>
          <Copy
            className={`${ShareStyles.copy_icon} cursor-pointer`}
            onClick={() => {
              navigator.clipboard.writeText(iFrameText);
              showInfoMessage('The iFrame Embedding was copied to your clipboard');
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ShareFormModal;
