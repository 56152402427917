import React, {
  useState, useCallback, useRef, useMemo, useEffect,
} from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useWeb3Modal } from '@web3modal/react';
import { ethers } from 'ethers';
import {
  useAccount,
  useNetwork,
  useSwitchNetwork,
  useSigner, UserRejectedRequestError,
} from 'wagmi';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  mainnet, polygon, sepolia,
} from 'wagmi/chains';
import { nftCollectionContractAbi, bytecode } from '../../../../utils/web3/nftCollectionContractAbi';
import RoundSpinner from '../../../../components/base/RoundSpinnerWithStatus';
import { getUser } from '../../../../store/reducers/user';
import { IconNearby } from '../../../../components/base/SelectLabels';
import Upload from '../../../../assets/icons/upload.svg';
import InfoIcon from '../../../../assets/icons/info_small_raw.svg';
import Trash from '../../../../assets/icons/trash_asset.svg';
import Tooltip from '../../../../components/ui/Tooltip';
import styles from './NFTCollectionCreationModal.module.scss';
import { contractsApi } from '../../../../api/contracts';
import { showErrorMessage, showSuccessMessage } from '../../../../components/base/Notifications';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { onSettingsChange } from '../../../../store/reducers/flows';
import { isNFTCreationAssetAdded } from '../../../../store/reducers/contracts';
import { blockChainOptionsWithTestnets } from '../../../../components/base/ChainLogo/chains';
import {
  amoy, baseSepolia, linea, base, lineaSepolia, chiliz, chilizSpicy,
} from '../../../../utils/web3/customChains.ts';
import Switch from '../../../Forms/CreateForm/Components/ModalSettings/Switch';
import { trackNFTCreation } from '../../../../utils/mixpanel/mixpanelEvents';
import Modal from '../../../../components/base/Modal/index.jsx';
import ModalTitle from '../../../../components/base/Modal/ModalTitle.jsx';
import ModalSection from '../../../../components/base/Modal/ModalSection.jsx';
import ModalDivider from '../../../../components/base/Modal/ModalDivider.jsx';
import ModalFooter from '../../../../components/base/Modal/ModalFooter.jsx';
import ModalCollapsable from '../../../../components/base/Modal/ModalCollapsable.jsx';
import ModalErrorMessage from '../../../../components/base/Modal/ModalErrorMessage.jsx';
import { ModalInputText, ModalInputNumber, ModalInputTextArea } from '../../../../components/base/Modal/ModalInput.jsx';

const OPERATOR_ADDRESS = import.meta.env.VITE_APP_NFT_CREATION_OPERATOR_ADDRESS;

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
    height: '200px',
  }),
};

const NFTCollectionCreationModal = ({
  onCancel,
  data = '',
}) => {
  const [isDeploying, setIsDeploying] = useState(false);
  const [file, setFile] = useState(null);
  const [urlImage, setUrlImage] = useState('');
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [onSubmitDataStack, setOnSubmitDataStack] = useState();
  const { open, setDefaultChain, isOpen } = useWeb3Modal();
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const [checkupStatus, setCheckupStatus] = useState(false);
  const { switchNetwork } = useSwitchNetwork({
    onError(err) {
      if (err instanceof UserRejectedRequestError) {
        showErrorMessage('The network change request was canceled in the wallet.');
        setIsDeploying(false);
      }
    },
  });
  const dispatch = useDispatch();
  const { data: signer } = useSigner();

  const user = useSelector(getUser);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitted },
  } = useForm();

  const onCancelRef = useRef(onCancel);
  useEffect(() => {
    if (isOpen) {
      onCancelRef.current = () => null;
    } else {
      onCancelRef.current = onCancel;
    }
  }, [isOpen])
  
  const handleCancel = useCallback(() => {
    if (!isOpen) {
      onCancelRef.current();
    }
  }, [isOpen])

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    setValue('transferable', 'yes');
  }, []);

  const getCoinLabel = useCallback((val) => <IconNearby val={val} />, []);

  const [postNFTMedia] = contractsApi.usePostNFTMediaMutation();
  const [createNFT, createNFTResult] = contractsApi.useCreateNFTMutation();

  const isValidImageType = (imgFile) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
    return validTypes.includes(imgFile.type) && imgFile.name.match(/\.(jpg|jpeg|png|gif)$/);
  };

  const handleChange = useCallback((e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (!isValidImageType(e.target.files[0])) {
        showErrorMessage('Please select a valid image file. JPG, PNG, GIF are supported.');
      } else {
        setUrlImage(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
      }
    }
  }, [setFile, setUrlImage]);

  const handleDrop = (e) => {
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (!isValidImageType(e.dataTransfer.files[0])) {
        showErrorMessage('Please select a valid image file. JPG, PNG, GIF are supported.');
      } else {
        setUrlImage(URL.createObjectURL(e.dataTransfer.files[0]));
        setFile(e.dataTransfer.files[0]);
      }
    }
  };

  const clearAsset = () => {
    setUrlImage('');
    setFile(null);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const convertIntoFormData = (onSubmitData) => {
    const formData = new FormData();
    Object.keys(onSubmitData).forEach((elem) => {
      if (onSubmitData[elem]) {
        formData.append(elem, onSubmitData[elem]);
      }
    });
    return formData;
  };

  const generateSymbol = (string) => {
    let result = '';
    const array = string.split(' ');
    if (array.length > 1) {
      result = array.map((elem) => elem[0]).join('');
    } else {
      result = string.slice(0, 20);
    }
    return result;
  };

  const onSubmit = (onSubmitData, event) => {
    event.preventDefault();
    setIsDeploying(true);
    if (onSubmitData.blockchain === 'eth-sepolia'
      || onSubmitData.blockchain === 'amoy'
      || onSubmitData.blockchain === 'linea-sepolia'
      || onSubmitData.blockchain === 'base-sepolia'
      || onSubmitData.blockchain === 'chiliz-spicy'
    ) {
      onSubmitData.testnet = true;
    }
    setOnSubmitDataStack(onSubmitData);

    // step1 switch network
    setStatus(1);
    const currNetwork = blockChainOptionsWithTestnets
      .find((c) => c.value === onSubmitData.blockchain)?.networkId;
    if (currNetwork !== chain?.id) {
      switchNetwork(currNetwork);
    }
  };

  const deploy = useCallback(async () => {
    // step 2 upload assets
    setStatus(2);
    const mediaRes = await postNFTMedia(convertIntoFormData({ media: file }));
    if (mediaRes.error) {
      showErrorMessage('Media file uploading fails');
      setIsDeploying(false);
    }
    const mediaUrl = mediaRes.data?.media;

    try {
      // step 3 Checkup for all fields(without saving as record), then deploy on chain
      setStatus(3);
      const deployerAddress = await signer.getAddress();
      onSubmitDataStack.contract_address = '0x';
      onSubmitDataStack.user = user.id;
      onSubmitDataStack.media = mediaUrl;
      onSubmitDataStack.deployer_address = deployerAddress;
      onSubmitDataStack.checkup_only = true;
      setCheckupStatus(true);
      await createNFT(convertIntoFormData(onSubmitDataStack));

      const factory = new ethers.ContractFactory(nftCollectionContractAbi, bytecode, signer);
      const contract = await factory.deploy(
        onSubmitDataStack.contract_name,
        onSubmitDataStack.contract_symbol,
        onSubmitDataStack.collection_name.replaceAll('"', '\\"').replace(/(?:\r\n|\r|\n)/g, '\\n'),
        onSubmitDataStack.collection_description.replaceAll('"', '\\"').replace(/(?:\r\n|\r|\n)/g, '\\n'),
        mediaUrl,
        '',
        onSubmitDataStack.transferable === 'yes',
        onSubmitDataStack.max_supply ? onSubmitDataStack.max_supply : ethers.constants.MaxUint256,
        OPERATOR_ADDRESS,
      );

      // step 4 Confirm transaction and post all data to BE
      setStatus(4);
      await contract.deployTransaction.wait();
      onSubmitDataStack.contract_address = contract.address;
      onSubmitDataStack.checkup_only = false;
      setCheckupStatus(false);
      await createNFT(convertIntoFormData(onSubmitDataStack));
      setIsDeploying(false);
      dispatch(isNFTCreationAssetAdded(true));
    } catch (err) {
      setIsDeploying(false);
      if (err.message.includes('user rejected transaction')) {
        showErrorMessage('The contract deployment request was canceled in the wallet.');
      } else {
        showErrorMessage('The contract deployment failed. Please check your wallet for more details');
      }
    }
  }, [createNFT, file, onSubmitDataStack, postNFTMedia, signer, user.id, dispatch]);

  const openWeb3Modal = useCallback(async () => {
    switch (watch('blockchain')) {
      case 'ethereum': setDefaultChain(mainnet); break;
      case 'polygon': setDefaultChain(polygon); break;
      case 'amoy': setDefaultChain(amoy); break;
      case 'linea': setDefaultChain(linea); break;
      case 'base': setDefaultChain(base); break;
      case 'base-sepolia': setDefaultChain(baseSepolia); break;
      case 'linea-sepolia': setDefaultChain(lineaSepolia); break;
      case 'eth-sepolia': setDefaultChain(sepolia); break;
      case 'chiliz': setDefaultChain(chiliz); break;
      case 'chiliz-spicy': setDefaultChain(chilizSpicy); break;
      default: break;
    }
    await open();
  }, [watch, open, setDefaultChain]);

  useEffect(() => {
    if (onSubmitDataStack && isDeploying && status === 1 && signer) {
      const currNetwork = blockChainOptionsWithTestnets
        .find((c) => c.value === onSubmitDataStack.blockchain)?.networkId;
      if (currNetwork === chain?.id) {
        deploy();
      }
    }
  }, [onSubmitDataStack, chain?.id, status, isDeploying, signer]);

  useEffect(() => {
    if (!checkupStatus && createNFTResult.isSuccess) {
      showSuccessMessage('NFT was successfully created');
      trackNFTCreation(user, onSubmitDataStack);
      if (currentPath !== '/dashboard' && data.includes('node')) {
        setTimeout(() => {
          const airdropConfig = {
            name: 'Airdrop',
            source_of_nfts: 'created_assets',
            nft_address: onSubmitDataStack.contract_address,
            nft_blockchain: onSubmitDataStack.blockchain,
            asset_name: onSubmitDataStack.collection_name,
            nft_amount: '',
            is_erc1155: false,
            user_token_ids: [],
            assetImg: onSubmitDataStack.media,
            asset_type: 'nft',
            team_name: user?.team,
            node_id: data,
            testnet: true,
          };
          dispatch(onSettingsChange([airdropConfig]));
        }, 10);
      }
      onCancel();

      if (currentPath === '/dashboard') {
        navigate(`/nfts/${createNFTResult?.data.blockchain}/${createNFTResult?.data.contract_address}`);
      }
    }
    if (createNFTResult.isError) {
      showErrorMessage(createNFTResult.isError?.error?.data?.detail
        || 'Something went wrong. Please check your configurations');
    }
  }, [createNFTResult, navigate, onCancel, checkupStatus]);

  const assetsContent = useMemo(() => {
    if (file) {
      return (
        <div className={styles.label_file_showing}>
          <div className="d-flex align-items-center w-75 gap-2">
            <div className={styles.img_asset}>
              <img src={urlImage} alt="img" width="50px" height="50px" />
            </div>
            <span className="text-truncate">{file?.name || ''}</span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              type="button"
              className={`${styles.upload_button} cursor-pointer`}
              onClick={() => clearAsset()}
            >
              <Trash />
            </button>
            <label {...register('media')} htmlFor="input-file-upload">
              <input
                id="input-file-upload"
                ref={inputRef}
                type="file"
                accept=".jpg,.png,.gif"
                className={styles.input_file_upload}
                onChange={handleChange}
              />
              <button
                type="button"
                className={`${styles.upload_button} ${styles.upload_color}`}
                onClick={onButtonClick}
              >
                <Upload />
              </button>
            </label>
          </div>
        </div>
      );
    }
    return (
      <label
        {...register('media')}
        htmlFor="input-file-upload"
        className={styles.label_file_upload}
      >
        <input
          id="input-file-upload"
          ref={inputRef}
          type="file"
          accept=".jpg,.png,.gif"
          className={styles.input_file_upload}
          onChange={handleChange}
        />
        <button
          type="button"
          className={styles.upload_button}
          onClick={onButtonClick}
        >
          <div className="d-flex align-items-center justify-content-center gap-3">
            <Upload />
            <span>Drag & Drop Image or click to upload</span>
          </div>
        </button>
      </label>
    );
  }, [file, register, urlImage, handleChange]);

  const statusContent = useMemo(() => {
    if (status === 1) {
      return {
        title: `Switching to ${capitalizeFirstLetter(onSubmitDataStack.blockchain)}`,
        extraText: 'Accept the network change request in your wallet',
      };
    } if (status === 2) {
      return {
        title: '',
        extraText: '',
      };
    } if (status === 3) {
      return {
        title: 'Deploying the contract on-chain',
        extraText: 'Accept the contract deployment transaction in your wallet',
      };
    } if (status === 4) {
      return {
        title: 'Waiting for transaction to confirm',
        extraText: 'Your transaction is being executed on the blockchain. This can take up to a few minutes',
      };
    }
  }, [chain?.network, status]);

  const handleTransferableChange = () => {
    const newValue = watch('transferable') === 'yes' ? 'no' : 'yes';
    setValue('transferable', newValue);
  };

  return (
    <Modal 
      onCancel={handleCancel} 
      dragContent={(
        <div className={styles.upload_wrapper}>
          <div className={styles.upload_img} />
          Drop file anywhere to upload
        </div>
      )}
      onDrop={handleDrop}
    >
      <ModalTitle title="Create a new NFT Collection" />
      <ModalSection title="Collection name">
        <ModalInputText
          placeholder="Enter name"
          onChange={(e) => {
            setValue('collection_name', e.target.value);
            setValue('contract_name', e.target.value.slice(0, 20));
            setValue('contract_symbol', generateSymbol(e.target.value));
          }}
          formProps={{...register('collection_name', { required: 'Collection name is required' })}}
        />
        <ModalErrorMessage show={errors?.collection_name} message={errors?.collection_name?.message} />
      </ModalSection>

      <ModalSection title="Collection Description">
        <ModalInputTextArea
          placeholder="Enter description"
          onChange={(e) => setValue('collection_description', e.target.value)}
          formProps={{...register('collection_description', { required: 'Collection description is required' })}}
        />
        <ModalErrorMessage show={errors?.collection_description} message={errors?.collection_description?.message} />
      </ModalSection>

      <ModalSection 
        title="NFT Asset" 
        titleInfo={'File types supported: JPG,PNG,GIF'}
      >
        {assetsContent}
        <ModalErrorMessage show={isSubmitted && !file} message="NFT Asset file is required" />
      </ModalSection>

      <ModalSection title="Blockchain" half>
        <Select
          {...register('blockchain', { required: 'Blockchain is required' })}
          styles={inputStyles}
          key="blockchain"
          value={
            (() => {
              const value = blockChainOptionsWithTestnets
                .find((blockchain) => blockchain.value === watch('blockchain'));
              return value || null;
            })()
          }
          onChange={(val) => {
            if (watch('blockchain') === val.value) {
              return;
            }
            setValue('blockchain', val.value);
          }}
          getOptionLabel={(val) => getCoinLabel(val)}
          name="blockchain"
          options={blockChainOptionsWithTestnets}
        />
        <ModalErrorMessage show={errors?.blockchain} message={errors?.blockchain?.message} />
      </ModalSection>

      <ModalSection row>
        <Switch
          text="Transferable"
          checked={watch('transferable') === 'yes'}
          onChange={handleTransferableChange}
        />
        <div
          data-for="transferability"
          data-tip="show"
        >
          <InfoIcon height="15px" width="15px" color="#C2CFE0" />
        </div>
        <Tooltip
          id="transferability"
          info="Toggle off to create a Soulbound Token (SBT)"
        />
      </ModalSection>

      <ModalDivider />

      <ModalCollapsable title="Advanced options">
        <ModalSection title="Contract name">
          <ModalInputText
            placeholder="Input contract name"
            onChange={(e) => setValue('contract_name', e.target.value.trim())}
            maxLength={20}
            formProps={{...register('contract_name', { required: true })}}
          />
        </ModalSection>

        <ModalSection title="Symbol">
          <ModalInputText
            placeholder="Input symbol"
            onChange={(e) => setValue('contract_symbol', e.target.value.trim())}
            maxLength={20}
            formProps={{...register('contract_symbol', { required: true })}}
          />
        </ModalSection>

        <ModalSection title="Max number of NFTs" optional>
          <ModalInputNumber
            value={watch('max_supply')}
            placeholder="1000"
            onChange={(e) => setValue('max_supply', e.target.value)}
            formProps={{...register('max_supply', {setValueAs: (v) => (v === '' ? null : parseInt(v, 10))})}}
          />
        </ModalSection>

          {/* <ModalSection title="Expires after">
            <Select
              {...register('contract_type')}
              styles={inputStyles}
              key="blockchain"
              value={
                (() => {
                  const value = expiresOptions
                    .filter((opt) => opt.value === watch('contract_type'))[0];
                  return value || null;
                })()
              }
              onChange={(val) => setValue('contract_type', val.value)}
              name="expires_after"
              options={expiresOptions}
            />
          </ModalSection> */}
      </ModalCollapsable>

      <ModalFooter>
        {
          !isDeploying ? (
            isConnected ? (
              <button
                type="submit"
                className="regular-button mt-3"
                onClick={handleSubmit(onSubmit)}
              >
                Deploy
              </button>
            ) : (
              <button
                type="button"
                className="regular-button mt-3"
                onClick={() => openWeb3Modal()}
              >
                Connect Wallet
              </button>
            )
          ) : (
            <RoundSpinner
              statusInfo={{ status, statusContent }}
            />
          )
        }
      </ModalFooter>
    </Modal>
  );
};

export default NFTCollectionCreationModal;
