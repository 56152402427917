import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import styles from './DateTime.module.scss';

const DateTime = ({ conditionId, labelText, label, value }) => {
  const dispatch = useDispatch();
  const [calendarIsOpened, setCalendarIsOpened] = useState(false);

  const handleChange = (newDate) => {
    if (newDate) {
      newDate.setSeconds(0, 0);
      dispatch(setSelectedValue({ conditionId, label, value: newDate }))
    }
  }

  return (
    <div className={`${styles.wrapper} ${calendarIsOpened ? styles.opened : ''}`}>
      <span className={styles.label}>{labelText}</span>
      <DatePicker
        className='form-control'
        selected={new Date(value)}
        placeholderText="Select a Date"
        onChange={(date) => {
          handleChange(date)
        }}
        value={value ? new Date(value) : null}
        onCalendarOpen={() => setCalendarIsOpened(true)}
        onCalendarClose={() => setCalendarIsOpened(false)}
        wrapperClassName='w-100'
        timeInputLabel="Time:"
        dateFormat="MMM. d, yyyy h:mm aa"
        showTimeInput
      />
    </div>
  )
}

export default DateTime
