import { useSelector } from 'react-redux';
import { showErrorMessage } from '../../components/base/Notifications';
import { getConditionsSelectedValues } from '../../store/reducers/campaign';

const getSelectedValues = (id) => {
  const values = useSelector(getConditionsSelectedValues);
  return values?.[id] || null;
};

export const getValue = (id, label) => {
  const selectedValues = getSelectedValues(id);
  if (selectedValues) {
    return selectedValues[label];
  }
  showErrorMessage('Something went wrong. Please, refresh the page and try again.');
};
