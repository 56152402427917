import React, { useState, useEffect, useRef } from 'react';
import emitter from '../../../../../utils/emitter';
import { showErrorMessage, showSuccessMessage } from '../../../../base/Notifications';
import { foldersApi } from '../../../../../api/folders';
import styles from '../foldersModal.module.scss';
import Modal from '../../../../base/Modal';
import ModalTitle from '../../../../base/Modal/ModalTitle';
import ModalFooter from '../../../../base/Modal/ModalFooter';

const AddSubfolderModal = ({ closeModal, parent, type }) => {
  const [createSubfolder] = foldersApi.useCreateSubfolderMutation();
  const [subfolderName, setSubfolderName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleConfirm = async () => {
    if (!subfolderName) {
      showErrorMessage('Subfolder name cannot be empty');
      return;
    }
    const result = await createSubfolder({ name: subfolderName, parentId: parent.id, type });
    if (result.error) {
      showErrorMessage('Error during subfolder creation');
      return;
    }
    showSuccessMessage('Subfolder was successfully created');
    emitter.emit('refetch_folders');
    setTimeout(() => {
      emitter.emit('open_folder', parent.id, type);
    }, 500);
    closeModal();
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.key === 'Enter') {
        await handleConfirm();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [subfolderName]);

  return (
    <Modal onCancel={closeModal}>
      <ModalTitle title="Create a subfolder" />

      <input
        ref={inputRef}
        type="text"
        className={`form-control mb-4 ${styles.input}`}
        placeholder="Add subfolder name here"
        value={subfolderName}
        onChange={(e) => setSubfolderName(e.target.value)}
      />

      <ModalFooter>
        <button
          type="button"
          className="outline-button border border-1"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="regular-button"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddSubfolderModal;
