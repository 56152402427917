import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { convertUnicodeChars } from '../../../utils/convertUnicodeChars';
import styles from './Mardown.module.scss';

const Markdown = ({ content, className }) => (
  <ReactMarkdown
    rehypePlugins={[rehypeRaw]}
    components={{
      // eslint-disable-next-line react/no-unstable-nested-components
      p: 'span',
    }}
    className={`${styles.markdown} ${className ? className : ''}`}
  >
    {convertUnicodeChars(content)}
  </ReactMarkdown>
);

export default Markdown;
