import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { format } from 'date-fns';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import Accept from '../../../../assets/icons/check.svg';
import Close from '../../../../assets/icons/flows/close.svg';
import Edit from '../../../../assets/icons/edit_info.svg';
import {
  onPaste, onPasteFloat, preventInvalidSymbols, validateInput,
} from '../../../../utils/segments';
import { isCustomDataEdit } from '../../../../store/reducers/app';
import styles from './EditCustomDataField.module.scss';

const EditCustomDataField = ({
  type, value, register, unregister, setValue, setFocus, fieldName, watch,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [prevValue, setPrevValue] = useState(value);

  const undefinedData = useMemo(() => (
    <span className={styles.undefined}>Undefined</span>
  ), []);

  const handleValue = useCallback((typeValue, val) => {
    switch (typeValue) {
      case 'BOOLEAN':
        return val ? 'Yes' : 'No';
      case 'DATE':
        return val ? format(new Date(val.replace(/-/g, '/')), 'MMM dd, yyyy') : undefinedData;
      case 'TIME':
        return val ? format(new Date(`0000-01-01T${val}`), 'paa') : undefinedData;
      case 'DATETIME':
      case 'TIMESTAMP':
        return val ? format(new Date(val), 'MMM dd, yyyy @ paa') : undefinedData;
      case 'INTEGER':
        return val !== null ? ethFormat(val, 1) : undefinedData;
      default:
        return val || undefinedData;
    }
  }, [undefinedData]);

  const handleType = useCallback((typeValue) => {
    switch (typeValue) {
      case 'TIME':
        return 'time';
      case 'DATE':
        return 'date';
      default:
        return 'datetime-local';
    }
  }, []);

  const handleISOTimestamp = (val) => {
    if (val && val.endsWith('Z')) {
      const isoTimestamp = new Date(val);
      const timeShift = isoTimestamp.getTimezoneOffset();
      return new Date(isoTimestamp.getTime() - timeShift * 60 * 1000).toISOString().split('.')[0];
    }
    return val;
  };

  useEffect(() => {
    if (editMode) {
      setFocus(fieldName);
    }
  }, [editMode, setFocus, fieldName]);

  const editField = useMemo(() => {
    if (type === 'BOOLEAN') {
      const boolOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ];
      return (
        <Select
          {...register(fieldName)}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: 'transparent',
            }),
          }}
          options={boolOptions}
          value={[{
            value: inputValue, label: inputValue ? 'Yes' : 'No',
          }]}
          onChange={(val) => {
            setInputValue(val.value);
            setValue(fieldName, val.value);
          }}
          isSearchable={false}
          className={`w-100 shadow-none ${styles.select}`}
        />
      );
    }
    if (type === 'TIMESTAMP' || type === 'DATE' || type === 'TIME' || type === 'DATETIME') {
      return (
        <input
          {...register(fieldName)}
          onChange={(e) => {
            if (type === 'TIMESTAMP' || type === 'DATETIME') {
              const newValue = new Date(e.target.value)
              newValue.setSeconds(0, 0);
              const newValueIso = newValue.toISOString();
              setInputValue(newValueIso);
            } else {
              setInputValue(e.target.value);
            }
            
          }}
          value={type === 'TIMESTAMP' ? handleISOTimestamp(inputValue) : inputValue}
          step={type !== 'TIMESTAMP' ? '1' : '0'}
          max="9999-12-31T00:00:00"
          type={handleType(type)}
          className="w-100 p-2 form-select shadow-none"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && (value === prevValue || editMode)) {
              unregister(fieldName);
              e.preventDefault();
            }
          }}
        />
      );
    }
    if (type === 'FLOAT' || type === 'INTEGER' || type === 'NUMERIC') {
      return (
        <input
          {...register(fieldName, {
            valueAsNumber: true,
          })}
          value={inputValue || undefined}
          className="shadow-none form-control"
          onWheel={(e) => e.target.blur()}
          min="0"
          type="number"
          step={type === 'FLOAT' || type === 'NUMERIC' ? '0.001' : '0'}
          onChange={(e) => {
            const ifValidValue = validateInput(
              e.target.value,
              false,
              type === 'FLOAT' || type === 'NUMERIC',
            );
            if (!ifValidValue) {
              return;
            }
            setInputValue(e.target.value !== '' ? e.target.value : null);
          }}
          onPaste={type === 'FLOAT' || type === 'NUMERIC' ? onPasteFloat : onPaste}
          onKeyPress={(e) => preventInvalidSymbols(
            e,
            type === 'FLOAT' || type === 'NUMERIC',
          )}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && (value === prevValue || editMode)) {
              unregister(fieldName);
              e.preventDefault();
            }
          }}
        />
      );
    }
    return (
      <textarea
        {...register(fieldName)}
        value={inputValue || ''}
        rows={3}
        onChange={(e) => setInputValue(e.target.value)}
        className={`shadow-none form-control w-100 ${styles.text_area}`}
        maxLength={255}
        name={fieldName}
        id={fieldName}
        onWheel={(e) => e.target.blur()}
        onKeyPress={(e) => {
          if (['/'].includes(e.key)) {
            e.preventDefault();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && (value === prevValue || editMode)) {
            unregister(fieldName);
            e.preventDefault();
          }
        }}
      />
    );
  }, [editMode, fieldName, inputValue, prevValue, register, setValue, type, unregister, value, handleType]);

  return (
    <div
      className={`d-flex flex-column align-content-center justify-content-between
       ${styles.custom_data} ${styles.hover}`}
    >
      {editMode
        ? (
          <div className="d-flex justify-content-between">
            {editField}
            <div className="d-flex align-items-center gap-2">
              <div
                className={`${styles.accept} cursor-pointer`}
                role="presentation"
                onClick={() => {
                  if (value === inputValue) {
                    unregister(fieldName);
                    setPrevValue(inputValue);
                  } else {
                    setValue(fieldName, inputValue);
                    setPrevValue(watch(fieldName));
                  }
                  setEditMode(false);
                }}
              >
                <Accept />
              </div>
              <div className={`${styles.divider} d-flex align-items-center`} />
              <div
                className={`${styles.close} cursor-pointer`}
                role="presentation"
                onClick={() => {
                  if (value === prevValue) {
                    unregister(fieldName);
                  }
                  setInputValue(prevValue);
                  if (value !== prevValue) {
                    setValue(fieldName, prevValue);
                  }
                  setEditMode(false);
                }}
              >
                <Close />
              </div>
            </div>
          </div>
        )
        : (
          <div className="d-flex w-100 justify-content-between">
            <div
              className="text-truncate"
            >
              {handleValue(type, inputValue)}
            </div>
            <div
              role="presentation"
              className={`${styles.edit} d-flex align-items-center cursor-pointer`}
              onClick={() => {
                setEditMode(true);
                dispatch(isCustomDataEdit(true));
              }}
            >
              <Edit />
            </div>
          </div>
        )}
    </div>
  );
};

export default EditCustomDataField;
