import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { formsApi } from '../../../../../api/forms';
import styles from './Details.module.scss'
import { counter } from '../../../../../tools/Counter';
import Export from '../../../../../components/ui/Buttons/Export';
import Table from '../../../../../components/base/Table';
import { format } from 'date-fns';
import DownloadModal from '../../../../../components/ui/modals/DownloadModal';
import Header from '../../../../../components/ui/Table/Header';

const Details = ({ url, isFormLoading, isStaticForm }) => {
  const [searchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'received_date');
  const [desc, setDesc] = useState(searchParams.get('desc') || true);
  const [isOpen, setIsOpen] = useState(false);

  const {
    currentData: data,
    isLoading,
    isFetching,
  } = formsApi.useGetDetailsByIdQuery(
    {
      id: url,
      limit,
      order: desc ? 'desc' : 'asc',
      orderBy: order,
      offset: (page - 1) * limit
    },
    { skip: !url },
  );

  const [getDetailsCSV, result] = formsApi.useGetDetailsCSVMutation();

  const headers = [
    { title: 'Date', field: 'received_date' },
    { title: 'Views', field: 'views' },
    { title: 'Unique Visitors', field: 'visitors' },
    { title: 'New Visitors', field: 'new_visitors' },
    { title: 'With Wallet', field: 'visitors_with_wallet' },
    isStaticForm 
      ? { title: 'Clicks', field: 'clicks' }
      : { title: 'Responses', field: 'responses' },
    { 
      title: 'Conversion Rate', 
      field: 'conversion_rate', 
      info: isStaticForm ? 'Based on unique visitors who clicked a button in the form' : 'Based on unique visitors who submitted the form'
    },
  ];

  useEffect(() => {
    if (data && data.data) {
      const temp = [];
      data.data.forEach((item) => {
        temp.push({
          received_date: format(new Date(item.received_date), 'eeee, MMM dd, yyyy'),
          views: item.views,
          visitors: item.visitors,
          new_visitors: item.new_visitors,
          visitors_with_wallet: item.visitors_with_wallet,
          [isStaticForm ? 'clicks' : 'responses']: isStaticForm ? item.clicks || 0 : item.responses || 0,
          conversion: item.conversion_rate !== null && item.conversion_rate !== undefined
            ? `${(item.conversion_rate * 100).toFixed(2)}%` 
            : '?',
        })
      });
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);

  return (
    <div className={styles.wrapper}>
      <Header
        title="Detailed Stats"
        info={counter(data?.count, page, limit)}
        actions={
          <Export
            onClick={() => getDetailsCSV({
              id: url,
            })}
            tooltip={"Export detailed stats to CSV"}
            tooltipWarnings={[]}
          />
        }
      />
      <div className={`${styles.table} ${(isLoading || isFetching || isFormLoading) ? styles.loading_table : ''}`}>
        <Table
          total={data?.count || 0}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={order}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={headers}
          isLoading={isFormLoading || isLoading || isFetching}
          min={25}
        />
      </div>
      {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
    </div>
  );
}

export default Details
