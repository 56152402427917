import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styles from './AiBlock.module.scss';
import { AiReport } from '../AiReport';
import AiInsight from '../../../assets/icons/insights/Ai_Insight.svg?url';
import AiInsightWithBackground from '../../../assets/icons/insights/Ai_Insight_with_Back.svg?url';
import { truncateTextByNumOfWords } from '../../../utils/truncateText';
import { convertUnicodeChars } from '../../../utils/convertUnicodeChars';
import { trackAIReportViewed, trackAIDeepDiveViewed } from '../../../utils/mixpanel/mixpanelEvents';
import { getUser } from '../../../store/reducers/user';

const CustomLink = ({
  href, children, handleClick, isProfile, ...props
}) => {
  const text = children[0];
  if (text === 'Full AI-Generated Report' || text === 'Read more' || text === 'Read Less') {
    return (
      <span
        {...props}
        onClick={(e) => handleClick(e, text)}
        className={styles.link}
        role="button"
        tabIndex={0}
      >
        {children}
      </span>
    );
  }
  return (
    <a href={href} {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const AiBlock = ({
  data, linkText, reportTitle, wBackground = false, title, isProfile = false,
}) => {
  const user = useSelector(getUser);
  const [showReport, setShowReport] = useState(false);
  const [highlightedText, setHighlightedText] = useState('');
  const [fullAnalysis, setFullAnalysis] = useState('');
  const [isFullMessageReceived, setIsFullMessageReceived] = useState(false);
  const [isHighlightComplete, setIsHighlightComplete] = useState(false);
  const [isTruncate, setIsTruncate] = useState(true);
  let highlightTags = ['<highlight>', '</highlight>'];
  const fullAnalysisTags = ['<full_analysis>', '</full_analysis>'];
  if (isProfile) highlightTags = ['<full_analysis>', '</full_analysis>'];
  const noData = data?.data?.length === 1 && data.data[0] === 'There is no data for this asset';

  const extractBetweenTags = (str, tags) => {
    const start = str.indexOf(tags[0]) + tags[0].length;
    const end = str.indexOf(tags[1], start);
    return start !== -1 && (end > start || end === -1)
      ? str.substring(start, end === -1 ? str.length : end) : '';
  };

  const handleClick = (e, text) => {
    if (noData) return;
    e.preventDefault();
    if (text === 'Full AI-Generated Report') {
      setShowReport(true);
      trackAIReportViewed(user);
    } else if (isProfile) {
      setIsTruncate(!isTruncate);
      trackAIDeepDiveViewed(user);
    }
  };

  useEffect(() => {
    if (data && (typeof data.fullJSONMessage === 'string')) {
      const fullMessage = data.fullJSONMessage;
      if (isProfile) {
        setFullAnalysis(fullMessage);
        setHighlightedText(fullMessage);
      } else {
        setFullAnalysis(extractBetweenTags(fullMessage, fullAnalysisTags));
        setHighlightedText(extractBetweenTags(fullMessage, highlightTags));
      }
      setIsFullMessageReceived(true);
      setIsHighlightComplete(true);
    } else if (data && data.data && data.data.length > 0) {
      const partialMessage = data.data.join('');
      const highlightPart = isProfile ? partialMessage : extractBetweenTags(partialMessage, highlightTags);
      if (highlightPart) {
        setHighlightedText(highlightPart);
      }
      if ((!isProfile && partialMessage.includes(highlightTags[1]))
        || (isProfile && partialMessage.split(' ').length > 50)) {
        setIsHighlightComplete(true);
      }
    } else {
      setHighlightedText('');
      setIsHighlightComplete(false);
      setIsFullMessageReceived(false);
    }
  }, [data]);

  const renderContent = () => {
    const displayText = isTruncate ? truncateTextByNumOfWords(highlightedText, 50) : highlightedText;
    const combinedText = `${title ? `**${title}** ` : ''}
    ${displayText}<del></del>${linkText && isHighlightComplete ? ` [${isTruncate ? linkText : 'Read Less'}](#)` : ''}`;
    return (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          a: (props) => <CustomLink handleClick={handleClick} isProfile {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          p: 'span',
          // eslint-disable-next-line react/no-unstable-nested-components
          del(props) {
            const { node, ...rest } = props;
            let returnItem = <span className={styles.blinkingDot} {...rest} />;
            if (isHighlightComplete && data.fullJSONMessage) returnItem = null;
            return returnItem;
          },
        }}
        className={styles.markdown}
      >
        {noData ? `${title ? `**${title}** ` : ''}There is no data for this asset` : convertUnicodeChars(combinedText)}
      </ReactMarkdown>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={wBackground ? AiInsightWithBackground : AiInsight} alt="Ai Insight" className={styles.icon} />
        <div className={styles.text}>
          {renderContent()}
        </div>
      </div>
      {showReport && (
        <AiReport
          data={{ full_analysis: fullAnalysis, partialMessage: data?.data?.join('') }}
          onCancel={() => setShowReport(false)}
          title={reportTitle}
          isFullMessageReceived={isFullMessageReceived}
          user={user}
        />
      )}
    </div>
  );
};

export default AiBlock;
