import React from 'react';
import Close from '../../../../../assets/icons/close.svg';
import styles from './CloseModalButton.module.scss';

const CloseModalButton = ({ onCancel }) => (
  <div className={`${styles.close}`}>
    <button
      type="button"
      aria-label="Close"
      onClick={() => onCancel()}
    >
      <Close />
    </button>
    Esc
  </div>
);

export default CloseModalButton;
