import ApexWallet from '../../../assets/icons/wallets/apexwallet.png';
import Avalanche from '../../../assets/icons/wallets/avalanche.png';
import Backpack from '../../../assets/icons/wallets/backpack.png';
import Bifrost from '../../../assets/icons/wallets/bifrost.png';
import Bitkeep from '../../../assets/icons/wallets/bitkeep.png';
import Bitski from '../../../assets/icons/wallets/bitski.png';
import BlockWallet from '../../../assets/icons/wallets/blockwallet.png';
import BraveWallet from '../../../assets/icons/wallets/bravewallet.png';
import Clover from '../../../assets/icons/wallets/clover.png';
import Coin98 from '../../../assets/icons/wallets/coin98.png';
import Coinbase from '../../../assets/icons/wallets/coinbase.png';
import CoinbaseWallet from '../../../assets/icons/wallets/coinbasewallet.png';
import Cosmostation from '../../../assets/icons/wallets/cosmostation.png';
import Dawn from '../../../assets/icons/wallets/dawn.png';
import Defiant from '../../../assets/icons/wallets/defiant.png';
import Enkrypt from '../../../assets/icons/wallets/enkrypt.png';
import Exodus from '../../../assets/icons/wallets/exodus.png';
import Frame from '../../../assets/icons/wallets/frame.png';
import Frontier from '../../../assets/icons/wallets/frontier.png';
import Gamestop from '../../../assets/icons/wallets/gamestop.png';
import HaloWallet from '../../../assets/icons/wallets/halowallet.png';
import HaqqWallet from '../../../assets/icons/wallets/haqqwallet.png';
import Hyperpay from '../../../assets/icons/wallets/hyperpay.png';
import Imtoken from '../../../assets/icons/wallets/imtoken.png';
import Keplr from '../../../assets/icons/wallets/keplr.png';
import Leap from '../../../assets/icons/wallets/leap.png';
import Leather from '../../../assets/icons/wallets/leather.png';
import MagicEdenWallet from '../../../assets/icons/wallets/magicedenwallet.png';
import MavrykWallet from '../../../assets/icons/wallets/mavrykwallet.png';
import MathWallet from '../../../assets/icons/wallets/mathwallet.png';
import MetaMask from '../../../assets/icons/wallets/metamask.png';
import Nightly from '../../../assets/icons/wallets/nightly.png';
import NovaWallet from '../../../assets/icons/wallets/novawallet.png';
import Nufi from '../../../assets/icons/wallets/nufi.png';
import OkxWallet from '../../../assets/icons/wallets/okxwallet.png';
import OneInchAndroidWallet from '../../../assets/icons/wallets/oneinchandroidwallet.png';
import OneInchIosWallet from '../../../assets/icons/wallets/oneinchioswallet.png';
import Opera from '../../../assets/icons/wallets/opera.png';
import OrangeWallet from '../../../assets/icons/wallets/orangewallet.png';
import Phantom from '../../../assets/icons/wallets/phantom.png';
import Portal from '../../../assets/icons/wallets/portal.png';
import Rabby from '../../../assets/icons/wallets/rabby.png';
import Rainbow from '../../../assets/icons/wallets/rainbow.png';
import Saifu from '../../../assets/icons/wallets/saifu.png';
import Salmon from '../../../assets/icons/wallets/salmon.png';
import Sender from '../../../assets/icons/wallets/sender.png';
import SentinelT4l3ntWallet from '../../../assets/icons/wallets/sentinelt4l3ntwallet.png';
import Solflare from '../../../assets/icons/wallets/solflare.png';
import Solong from '../../../assets/icons/wallets/solong.png';
import Spire from '../../../assets/icons/wallets/spire.png';
import Status from '../../../assets/icons/wallets/status.png';
import Talisman from '../../../assets/icons/wallets/talisman.png';
import Tally from '../../../assets/icons/wallets/tally.png';
import TempleTezosWallet from '../../../assets/icons/wallets/templetezoswallet.png';
import Tokenary from '../../../assets/icons/wallets/tokenary.png';
import Tokenpocket from '../../../assets/icons/wallets/tokenpocket.png';
import TrustWallet from '../../../assets/icons/wallets/trustwallet.png';
import TTWallet from '../../../assets/icons/wallets/ttwallet.png';
import Unisat from '../../../assets/icons/wallets/unisat.png';
import XDEFI from '../../../assets/icons/wallets/xdefi.png';
import Xverse from '../../../assets/icons/wallets/xverse.png';
import Zerion from '../../../assets/icons/wallets/zerion.png';
import { formatWalletName } from '../../../utils/walletIcons';

export const suppertedWallets = {
  apexwallet: {
    icon: ApexWallet,
    tooltip: 'Apex Wallet',
  },
  avalanche: {
    icon: Avalanche,
    tooltip: 'Avalanche',
  },
  backpack: {
    icon: Backpack,
    tooltip: 'Backpack',
  },
  bifrost: {
    icon: Bifrost,
    tooltip: 'Bifrost',
  },
  bitkeep: {
    icon: Bitkeep,
    tooltip: 'Bitkeep',
  },
  bitski: {
    icon: Bitski,
    tooltip: 'Bitski',
  },
  blockwallet: {
    icon: BlockWallet,
    tooltip: 'Block Wallet',
  },
  bravewallet: {
    icon: BraveWallet,
    tooltip: 'Brave Wallet',
  },
  clover: {
    icon: Clover,
    tooltip: 'Clover',
  },
  coin98: {
    icon: Coin98,
    tooltip: 'Coin98',
  },
  coinbase: {
    icon: Coinbase,
    tooltip: 'Coinbase',
  },
  coinbasewallet: {
    icon: CoinbaseWallet,
    tooltip: 'Coinbase Wallet',
  },
  cosmostation: {
    icon: Cosmostation,
    tooltip: 'Cosmostation',
  },
  dawn: {
    icon: Dawn,
    tooltip: 'Dawn',
  },
  defiant: {
    icon: Defiant,
    tooltip: 'Defiant',
  },
  enkrypt: {
    icon: Enkrypt,
    tooltip: 'Enkrypt',
  },
  exodus: {
    icon: Exodus,
    tooltip: 'Exodus',
  },
  frame: {
    icon: Frame,
    tooltip: 'Frame',
  },
  frontier: {
    icon: Frontier,
    tooltip: 'Frontier',
  },
  gamestop: {
    icon: Gamestop,
    tooltip: 'Gamestop',
  },
  halowallet: {
    icon: HaloWallet,
    tooltip: 'Halo Wallet',
  },
  haqqwallet: {
    icon: HaqqWallet,
    tooltip: 'Haqq Wallet',
  },
  hyperpay: {
    icon: Hyperpay,
    tooltip: 'Hyperpay',
  },
  imtoken: {
    icon: Imtoken,
    tooltip: 'Imtoken',
  },
  keplr: {
    icon: Keplr,
    tooltip: 'Keplr',
  },
  leap: {
    icon: Leap,
    tooltip: 'Leap',
  },
  leather: {
    icon: Leather,
    tooltip: 'Leather',
  },
  magicedenwallet: {
    icon: MagicEdenWallet,
    tooltip: 'Magic Eden Wallet',
  },
  mavrykwallet: {
    icon: MavrykWallet,
    tooltip: 'Mavryk Wallet',
  },
  mathwallet: {
    icon: MathWallet,
    tooltip: 'Math Wallet',
  },
  metamask: {
    icon: MetaMask,
    tooltip: 'MetaMask',
  },
  nightly: {
    icon: Nightly,
    tooltip: 'Nightly',
  },
  novawallet: {
    icon: NovaWallet,
    tooltip: 'Nova Wallet',
  },
  nufi: {
    icon: Nufi,
    tooltip: 'Nufi',
  },
  okxwallet: {
    icon: OkxWallet,
    tooltip: 'Okx Wallet',
  },
  oneinchandroidwallet: {
    icon: OneInchAndroidWallet,
    tooltip: 'OneInch Android Wallet',
  },
  oneinchioswallet: {
    icon: OneInchIosWallet,
    tooltip: 'OneInch Ios Wallet',
  },
  opera: {
    icon: Opera,
    tooltip: 'Opera',
  },
  orangewallet: {
    icon: OrangeWallet,
    tooltip: 'Orange Wallet',
  },
  phantom: {
    icon: Phantom,
    tooltip: 'Phantom',
  },
  portal: {
    icon: Portal,
    tooltip: 'Portal',
  },
  rabby: {
    icon: Rabby,
    tooltip: 'Rabby',
  },
  rainbow: {
    icon: Rainbow,
    tooltip: 'Rainbow',
  },
  saifu: {
    icon: Saifu,
    tooltip: 'Saifu',
  },
  salmon: {
    icon: Salmon,
    tooltip: 'Salmon',
  },
  sender: {
    icon: Sender,
    tooltip: 'Sender',
  },
  sentinelt4l3ntwallet: {
    icon: SentinelT4l3ntWallet,
    tooltip: 'Sentinel - T4L3NT Wallet',
  },
  solflare: {
    icon: Solflare,
    tooltip: 'Solflare',
  },
  solong: {
    icon: Solong,
    tooltip: 'Solong',
  },
  spire: {
    icon: Spire,
    tooltip: 'Spire',
  },
  status: {
    icon: Status,
    tooltip: 'Status',
  },
  talisman: {
    icon: Talisman,
    tooltip: 'Talisman',
  },
  tally: {
    icon: Tally,
    tooltip: 'Tally',
  },
  templetezoswallet: {
    icon: TempleTezosWallet,
    tooltip: 'Temple - Tezos Wallet',
  },
  tokenary: {
    icon: Tokenary,
    tooltip: 'Tokenary',
  },
  tokenpocket: {
    icon: Tokenpocket,
    tooltip: 'Tokenpocket',
  },
  trustwallet: {
    icon: TrustWallet,
    tooltip: 'Trust Wallet',
  },
  ttwallet: {
    icon: TTWallet,
    tooltip: 'TT Wallet',
  },
  unisat: {
    icon: Unisat,
    tooltip: 'Unisat',
  },
  xdefi: {
    icon: XDEFI,
    tooltip: 'XDEFI',
  },
  xverse: {
    icon: Xverse,
    tooltip: 'Xverse',
  },
  zerion: {
    icon: Zerion,
    tooltip: 'Zerion',
  },
};

export const isWalletSupported = (wallet) => {
  return suppertedWallets.hasOwnProperty(formatWalletName(wallet))
}
