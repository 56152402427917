import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import DropdownMenu from '../../../base/DropdownMenu';
import styles from '../TitleSection.module.scss';
import { AirdropToWalletsModal } from '../AirdropToWalletsModal';
import { onSettingsChange } from '../../../../store/reducers/flows';
import { getUser } from '../../../../store/reducers/user';

const AirdropButton = ({
  data, id, object, loading, display,
}) => {
  const { network, name } = useParams();
  const [showAirdropToWalletsModal, setShowAirdropToWalletsModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);

  if (loading || !data || data.length === 0 || !display) {
    return null;
  }

  const createAirdropNode = () => {
    const airdropConfig = {
      config_name: 'Airdrop',
      name: 'Airdrop',
      source_of_nfts: 'created_assets',
      nft_address: name,
      nft_blockchain: network,
      asset_name: object?.name,
      nft_amount: '1',
      is_erc1155: false,
      user_token_ids: [],
      assetImg: object?.logo,
      asset_type: 'nft',
      team_name: user?.team,
      node_id: 'node_0',
      testnet: true,
    };

    navigate('/add-flow', { state: { config: [airdropConfig] } });
  };

  const sendToResponders = () => {
    const settings = [{
      config_name: 'Form',
    },
    {
      config_name: 'Airdrop',
      name: 'Airdrop',
      source_of_nfts: 'created_assets',
      nft_address: name,
      nft_blockchain: network,
      asset_name: object?.name,
      nft_amount: '1',
      is_erc1155: false,
      user_token_ids: [],
      assetImg: object?.logo,
      asset_type: 'nft',
      team_name: user?.team,
      node_id: 'node_1',
      testnet: true,
    }];

    navigate('/add-flow', { state: { config: settings } });
  };

  const menuData = [
    {
      name: 'Airdrop Now',
      action: () => {
        setShowAirdropToWalletsModal(true);
      },
    },
    {
      name: 'Create a Flow',
      action: () => {
        createAirdropNode();
      },
    },
    {
      name: 'Send to Form Responders',
      action: () => {
        sendToResponders();
      },
    },
  ];

  return (
    <div
      className={`${styles.row} ${styles.popoverWrapper}`}
    >
      <button
        type="button"
        className={`btn ${styles.assetButton} regular-button`}
      >
        <span className={styles.buttonText}>
          Airdrop
        </span>
      </button>
      <div className={`${styles.popoverContentButton} `}>
        <DropdownMenu menuData={menuData} />
      </div>
      {showAirdropToWalletsModal && (
        <AirdropToWalletsModal
          id={id}
          onCancel={() => setShowAirdropToWalletsModal(false)}
          onSubmit={() => setShowAirdropToWalletsModal(false)}
        />
      )}
    </div>
  );
};

export default AirdropButton;
