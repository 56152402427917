import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import PickDateInput from './PickDateInput';
import styles from './StartStopDateFlowModal.module.scss';
import { handleTimeZone } from '../../../utils/timeout';
import Modal from '../../../components/base/Modal';
import ModalTitle from '../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../components/base/Modal/ModalFooter';

const StartStopDateFlowModal = ({ onCancel, onSubmit }) => {
  const [pickDate, setPickDate] = useState(false);

  const {
    register, setValue, watch, handleSubmit,
  } = useForm();

  const onSubmitDates = useCallback((submitData) => {
    const objDates = { ...submitData };
    Object.keys(submitData)
      .forEach((key) => {
        if (objDates[key]) {
          objDates[key] = handleTimeZone(objDates[key]);
        } else {
          delete objDates[key];
        }
      });
    setPickDate(false);
    onSubmit('scheduled', objDates);
  }, [onSubmit]);

  const title = pickDate ? "Set start and end dates" : "Set up your Flow flight dates";

  const modalContent = useMemo(() => {
    if (pickDate) {
      return (
        <form onSubmit={handleSubmit(onSubmitDates)}>
          <PickDateInput text="start" register={register} setValue={setValue} watch={watch} />
          <PickDateInput text="end" register={register} setValue={setValue} watch={watch} />
          <ModalFooter>
            <button
              type="button"
              className="outline-button border border-1"
              onClick={() => setPickDate(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button"
              disabled={!watch('start_at') && !watch('end_at')}
            >
              Apply dates
            </button>
          </ModalFooter>
        </form>
      );
    }
    return (
      <>
        <div className={`${styles.description} mb-0`}>
          <span>
            Set start and end dates for your flow
          </span>
        </div>
        <ModalFooter>
          <button
            type="button"
            className="outline-button border border-1"
            onClick={() => onSubmit('running', {
              start_at: handleTimeZone(format(new Date(), 'yyyy-MM-dd,HH:mm').replace(',', 'T')),
            })}
          >
            Launch Flow now
          </button>
          <button
            type="button"
            className="regular-button"
            onClick={() => setPickDate(true)}
          >
            Schedule Flow
          </button>
        </ModalFooter>
      </>
    );
  }, [handleSubmit, onSubmit, onSubmitDates, pickDate, register, setValue, watch('start_at'), watch('end_at')]);

  return (
    <Modal onCancel={onCancel}>
      <ModalTitle title={title} />
      {modalContent}
    </Modal>
  );
};

export default StartStopDateFlowModal;
