import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import { LoadingLines } from '../../../components/ui/modals/SearchModal/LoadingList';
import uniqueId from '../../../utils/uniqueId';
import TitleComponent from '../../../components/base/TitleComponent';
import EditCustomDataField from './EditCUstomDataField';
import Spinner from '../../../components/base/Spinner';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import { getIsCustomDataEdit, isCustomDataEdit } from '../../../store/reducers/app';
import { profilesApi } from '../../../api/profiles';
import styles from './CustomData.module.scss';
import { isWalletAddress } from '../../../utils/supportedBlockchains';
import { useNavigate } from "react-router-dom";

const CustomData = ({ address }) => {
  const dispatch = useDispatch();
  const [customData, setCustomData] = useState(null);

  const isCustomBlockEdit = useSelector(getIsCustomDataEdit);
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = profilesApi.useGetWalletCustomDataQuery(
    isWalletAddress(address) ? `?address=${address}` : `?alid=${address}`,
  );

  const {
    register, unregister, watch, setValue, setFocus, handleSubmit, reset,
  } = useForm();

  const [updateCustomData, result] = profilesApi.usePostWalletCustomDataMutation();

  const onSubmit = useCallback((submitData) => {
    const obj = { ...submitData };
    Object.keys(submitData).forEach((elem) => {
      if (obj[elem] === undefined) {
        delete obj[elem];
      }
      if (obj[elem] === '') {
        obj[elem] = null;
      }
    });
    updateCustomData({
      address: isWalletAddress(address) ? `?address=${address}` : `?alid=${address}`,
      body: obj,
    });
  }, [address, updateCustomData]);

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess && data) {
        const propertiesToRemove = [
          'address', 'email', 'twitter_id', 'telegram_id', 'discord_id', 'phone', 'airdrop', 'xmtp', 'telegram',
        ];
        const newData = Object.keys(data)
          .filter((key) => !propertiesToRemove.includes(key))
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
        setCustomData(newData);
      } else if (!data || isError) {
        setCustomData({});
      }
    }
  }, [data, isError, isLoading, isSuccess]);

  useEffect(() => {
    if (result.isSuccess) {
      showSuccessMessage('Custom data was updated successfully');
      dispatch(isCustomDataEdit(false));
      const newData = JSON.parse(JSON.stringify(customData));
      Object.keys(result?.data?.updated || result.originalArgs.body).forEach((item) => {
        newData[item].value = result.originalArgs.body[item];
      });
      if (isWalletAddress(address) && result?.data?.alid) {
        navigate(`/profile/${result?.data?.alid}`);
      }
      setCustomData(newData);
      reset();
      result.reset();
    }
    if (result.isError) {
      showErrorMessage(result?.error?.data?.message || 'Something went wrong');
      result.reset();
    }
  }, [customData, dispatch, reset, result]);

  const customDataInfo = useMemo(() => {
    if (!customData) {
      return (
        <LoadingLines rows={3} />
      );
    }
    if (customData && Object.keys(customData).length) {
      return (
        <div className="d-flex flex-column gap-3 mt-2">
          {Object.entries(customData).map((elem) => (elem[0] === 'absolute_labs_id'
            ? null
            : (
              <div key={uniqueId('custom_info')} className="d-flex flex-column gap-1">
                <span className={styles.title_header}>
                  {elem[0].replace(/[_]/g, ' ').replace(/opt out/g, 'Opt-Out')}
                </span>
                <EditCustomDataField
                  fieldName={elem[0]}
                  type={elem[1].type}
                  value={elem[1].value}
                  register={register}
                  unregister={unregister}
                  setFocus={setFocus}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
            )))}
        </div>
      );
    }
    return (
      <div className="d-flex align-items-center text-center mt-5">
        This profile doesn’t have custom data information
      </div>
    );
  }, [customData, register, setFocus, setValue, unregister, watch]);

  const downHandler = useCallback((event) => {
    if (event.key === 'Enter'
      && isCustomBlockEdit && Object.values(watch()).filter((elem) => elem !== undefined).length) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isCustomBlockEdit, onSubmit, watch]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler, isCustomBlockEdit]);

  return (
    <form
      className={`${styles.wrapper} d-flex flex-column gap-2`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={`d-flex justify-content-between ${styles.wrapper_header}`}>
        <TitleComponent>Custom Data</TitleComponent>
        {result.isLoading || result.isFetching ? (
          <Spinner />
        ) : Object.values(watch()).filter((elem) => elem !== undefined).length && isCustomBlockEdit ? (
          <button
            type="submit"
            className={styles.submit}
            onKeyPress={downHandler}
          >
            Save
          </button>
        ) : null}
      </div>
      <div className={`${styles.wrapper_content} w-100`}>
        {customDataInfo}
      </div>
    </form>
  );
};

export default CustomData;
