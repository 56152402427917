import React from 'react';
import styles from './ResponseDetailsModal.module.scss';
import Modal from '../../../../components/base/Modal';
import ModalTitle from '../../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../../components/base/Modal/ModalFooter';

const ResponseDetailModal = ({
  onCancel, responseData,
}) => {
  // const handleSendToFlow = () => {
  //   // For now we will do nothing here
  // };

  // const handleSeeFullProfile = () => {
  //   // TODO: Create Endpoint that finds the ALID of the user
  //   if (responseData.wallet_address) {
  //     navigate(`/profile/${responseData.wallet_address}`);
  //   }
  // };
  return (
    <Modal onCancel={onCancel}>
      <ModalTitle title={responseData.Email?.trim()
                || (Reflect.has(responseData, 'Wallet Address') && responseData['Wallet Address']?.trim()
                  ? `${responseData['Wallet Address'].substring(0, 29)}...`
                  : false)
                || 'User Response Details'} />
          <div className={`${styles.subtitle}`}>
            Posted on
            {' '}
            {responseData.inserted_at}
          </div>
          {Object.entries(responseData)
            .filter(([key]) => (key !== 'inserted_at' && key !== 'uuid'))
            .map(([key, value]) => (
              <div key={key} className={`${styles.fieldResponseContainer}`}>
                <div className={`${styles.field}`}>
                  {key.split('&^%')[0]}
                </div>
                {typeof value === 'string' && value.includes('First Name:') ? (
                  <div className={`${styles.response}`} dangerouslySetInnerHTML={{ __html: value }} />
                ) : (
                  <div className={`${styles.response}`}>
                    {value?.length > 0 ? value : <div className={styles.noResponse}>Not Provided</div>}
                  </div>
                )}

              </div>
            ))}
        {/* <ModalFooter>
          <button
            type="button"
            className="outline-button border border-1"
            onClick={handleSeeFullProfile}
          >
            See full profile
          </button>
          <button
            type="button"
            className="regular-button"
            onClick={handleSendToFlow}
          >
            Send to Flow
          </button>
        </ModalFooter> */}
    </Modal>
  );
};

export default ResponseDetailModal;
