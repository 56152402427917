import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import styles from './OneBarChart.module.scss';
import Tooltip from '../../ui/Tooltip';
import ReactTooltip from 'react-tooltip';
import uniqueId from '../../../utils/uniqueId';

const OneBarChart = ({ value, max, tooltip }) => {
  const tooltipId = uniqueId('barChart');
  const [shouldRenderChart, setShouldRenderChart] = useState(false);
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRenderChart(true);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  const series = [
    {
      data: [{
        x: '',
        y: value,
      }],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      events: {
        dataPointMouseEnter: () => {
          ReactTooltip.show()
        },
      },
    },
    colors: ['#109cf1'],
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    plotOptions: {
      bar: {
        expandOnClick: false,
        borderRadius: 4,
        horizontal: true,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      min: 0,
      max: Math.ceil(max),
      labels: {
        show: false,
        style: {
          width: 0,
        },
      },
    },
  };

  return (
    <div className={`${styles.chart_container}`}>
      <div className={`${styles.chart}`}>
        {shouldRenderChart ? 
          <>
            <Chart
              type="bar"
              options={options}
              series={series}
              height={55}
              data-for={tooltipId}
              data-tip
            />
            {tooltip ? <Tooltip id={tooltipId} info={tooltip} /> : null}
          </>
        : null}
      </div>
    </div>
  )
}

export default OneBarChart
