import React from 'react';
import { useNavigate } from 'react-router';

const CreateCampaignButton = ({ state }) => {
  const navigate = useNavigate();

  const handleCreateCampaign = () => {
    navigate('/form/campaign', { state });
  }

  return (
    <button
      type="button"
      className="regular-button text-nowrap"
      onClick={handleCreateCampaign}
    >
      Create a Web Campaign
    </button>
  )
}

export default CreateCampaignButton
