import React from 'react'
import ModalTitle from '../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../components/base/Modal/ModalFooter';
import styles from './FormDraftModal.module.scss';

const ErrorModal = ({ closeModal }) => {
  return (
    <>
      <ModalTitle title="Invalid Form" />
      
      <span className={styles.text}>
        Your form is invalid and cannot be published. Please fix and publish your form first in order to then launch your campaign.
      </span>

      <ModalFooter>
        <button
          type="button"
          className="regular-button"
          onClick={closeModal}
        >
          Close
        </button>
      </ModalFooter>
    </>
  )
}

export default ErrorModal
