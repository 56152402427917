import React from 'react';
import ChainLogo from '../../../../components/base/ChainLogo';
import TruncatedText from '../../../../components/base/TruncatedText';
import styles from './WalletNameLabel.module.scss';
import WalletIcon from '../../../../components/base/WalletIcon';
import { getWalletLabel } from '../../../../utils/walletIcons';

const WalletNameLabel = ({ val }) => (
  <div className={styles.wrapper}>
    <WalletIcon wallet={val.wallet} rounded />
    <TruncatedText text={getWalletLabel(val.label)} />
    <div className={styles.network}>
      <ChainLogo chain={val.network} square medium />
    </div>
  </div>
);

export default WalletNameLabel
