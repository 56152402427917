import React from 'react';

import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import styles from './CustomDataLabel.module.scss';

const CustomListDataLabel = ({ val, additional = true }) => (
  <div className="d-flex cursor-pointer justify-content-between align-items-center">
    <div className={`text-truncate ${styles.container}`}>{val?.label || 'No value'}</div>
    {additional && <div className={styles.addresses}>
      {convertToReadableFormat(val?.additionalInfo)}
    </div>}
  </div>
);

export default CustomListDataLabel;
