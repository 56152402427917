import React from 'react';
import styles from './ErrorsModal.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import Warning from '../../../../assets/icons/flows/gray_warning.svg';
import Modal from '../../../base/Modal';
import ModalFooter from '../../../base/Modal/ModalFooter';
import ModalTitle from '../../../base/Modal/ModalTitle';

const ErrorsModal = ({ message, errors, onCancel }) => (
    <Modal onCancel={onCancel}>
      <ModalTitle title="Cannot activate Flow" />
      <div className={`${styles.description} d-flex w-100 mt-3`}>
          <span>
            {message}
          </span>
      </div>
      <div>
        {errors.map((elem) => (
          <div key={uniqueId('errors')} className={styles.error}>
            <div>
              <Warning />
            </div>
            {elem.detail}
          </div>
        ))}
      </div>
      <ModalFooter>
        <button type="button" className="regular-button border border-1" onClick={onCancel}>
            Return to editing
        </button>
      </ModalFooter>
    </Modal>
  );

export default ErrorsModal;
