import React, { useState, useRef, useEffect } from 'react';
import emitter from '../../../../../utils/emitter';
import { showErrorMessage, showSuccessMessage } from '../../../../base/Notifications';
import { foldersApi } from '../../../../../api/folders';
import styles from '../foldersModal.module.scss';
import Modal from '../../../../base/Modal';
import ModalTitle from '../../../../base/Modal/ModalTitle';
import ModalFooter from '../../../../base/Modal/ModalFooter';

const AddFolderModal = ({ closeModal, type }) => {
  const [createFolder] = foldersApi.useCreateFolderMutation();
  const [folderName, setFolderName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleConfirm = async () => {
    if (!folderName) {
      showErrorMessage('Folder name cannot be empty');
      return;
    }
    const result = await createFolder({ name: folderName, type });
    if (result.error) {
      showErrorMessage('Error during folder creation, please try again');
      return;
    }
    showSuccessMessage('Folder was successfully created');
    emitter.emit('refetch_folders');
    closeModal();
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.key === 'Enter') {
        await handleConfirm();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [folderName]);

  return (
    <Modal onCancel={closeModal}>
      <ModalTitle title="Create a new folder" />

      <input
        ref={inputRef}
        type="text"
        maxLength={100}
        className={`form-control mb-4 ${styles.input}`}
        placeholder="Add folder name here"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
      />
      <ModalFooter>
        <button
          type="button"
          className="outline-button border border-1"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="regular-button"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  )
};

export default AddFolderModal;
