import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFilterOption } from '../../../store/reducers/coin';
import { coinApi } from '../../../api/coin';
import DevActivityList from "../../../components/ui/DevActivityList";

const DevActivity = ({holders}) => {

  const {name: id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [orderBy, setOrderBy] = useState(searchParams.get('orderBy') || 'active_30d');
  const [order, setOrder] = useState(searchParams.get('order') !== 'false');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const filterOption = useSelector(selectFilterOption);

  const {
    data: data,
    isLoading: isLoading,
    isFetching: isFetching,
  } = coinApi.useGetTokenDevActivityQuery(
    {
      id,
      limit: +limit,
      offset: (page - 1) * (+limit),
      orderBy,
      order: order ? 'desc' : 'asc',
      audience: filterOption,
    },
  );
  return (
    <DevActivityList
      data={data}
      holders={holders}
      isLoading={isLoading}
      isFetching={isFetching}
      page={page}
      setPage={setPage}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      order={order}
      setOrder={setOrder}
      limit={limit}
      setLimit={setLimit}
    />
  );
};


export default DevActivity;
