import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeImageFile,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
  setImageFile,
} from '../../../../../../store/reducers/forms';
import styles from './ImageSettings.module.scss';
import { formsApi } from '../../../../../../api/forms';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import ModalSettings from '../../../Components/ModalSettings';
import PictureSelector from '../../../Components/PictureSelector';
import SelectionButtons from '../../../../../../components/ui/SelectionButtons';
import { alignmentOptions } from './options';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import Width from './Width';
import Input from '../../../Components/ModalSettings/Input';

const ImageFieldSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const pictureSelectorRef = useRef(null);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [url, setUrl] = useState('');
  const [imageAlign, setImageAlign] = useState(alignmentOptions[1].value);
  const [fileName, setFileName] = useState('');
  const [width, setWidth] = useState(50);
  const [caption, setCaption] = useState('');
  const [errors, setErrors] = useState({});
  const [uploadImage, uploadResult] = formsApi.useUploadImageMutation();
  const initialized = useRef(false);
  const [change, setChange] = useState(false);
  const uploadingFile = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    if ((file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif')
      && (file.name.endsWith('.jpg') || file.name.endsWith('.jpeg') || file.name.endsWith('.png') || file.name.endsWith('.gif'))) {
      uploadingFile.current = file;
      setChange(true);
      const formData = new FormData();
      formData.append('form_image', file);
      await uploadImage(formData);
    } else {
      showErrorMessage('Please select a valid image file. JPG, PNG and GIF are supported.');
    }
  };

  useEffect(() => {
    if (uploadResult.isSuccess && uploadResult.data?.form_image) {
      setUrl(uploadResult.data.form_image);
      setFileName(uploadingFile.current.name);
      dispatch(setImageFile({ fieldId: activeSettings.field_id, file: uploadingFile.current }));
      uploadingFile.current = null;
    } else if (uploadResult.isError) {
      if (pictureSelectorRef.current) {
        pictureSelectorRef.current.reset();
      }
      const message = uploadResult.error?.data?.form_image && uploadResult.error?.data?.form_image.length > 0 ? uploadResult.error?.data?.form_image[0] : '';
      if (message === "Upload a valid image. The file you uploaded was either not an image or a corrupted image.") {
        showErrorMessage('The file you uploaded could not be used: it is either not an image or a corrupted one.');
      } else {
        showErrorMessage('An unknown error occurred when processing your file. Please try again.');
      }
      uploadingFile.current = null;
    }
  }, [uploadResult]);

  const removeImage = () => {
    setChange(true);
    dispatch(removeImageFile({ fieldId: activeSettings.field_id }));
    setUrl('');
    setFileName('');
  };

  const handleChange = useCallback(async () => {
    setChange(false);
    const obj = {
      name: 'image',
      url,
      fileName,
      type: 'image',
      imageAlign,
      width,
      caption,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    url,
    fileName,
    imageAlign,
    width,
    caption,
    dispatch,
    activeSettings,
  ]);

  const setOptions = (config) => {
    setUrl(config.url);
    setFileName(config.fileName);
    setImageAlign(config.imageAlign);
    setWidth(config.width);
    setCaption(config.caption);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [url, imageAlign, width, caption]);

  useEffect(() => {
    const newErrors = { ...errors };
    if (caption.length === 250) {
      newErrors.caption = 'Character limit reached';
    } else {
      newErrors.caption = '';
    }
    setErrors(newErrors);
  }, [caption]);

  return (
    <ModalSettings title="Image Properties">
      <PictureSelector
        ref={pictureSelectorRef}
        title="Upload Image"
        url={url}
        fileName={fileName}
        uploadResult={uploadResult}
        handleFileUpload={handleFileUpload}
        removeImage={removeImage}
        fullWidth
      />

      <div className={styles.row}>
        <div className={`${styles.style_wrapper}`}>
          <div className={settingsStyles.title}>Alignment</div>
          <SelectionButtons
            buttons={alignmentOptions}
            selectedValue={imageAlign}
            setSelectedValue={(value) => {
              setChange(true);
              setImageAlign(value);
            }}
            width={53}
          />
        </div>

        <div className={styles.growth_row}>
          <Width
            width={width}
            setWidth={setWidth}
            setChange={setChange}
          />
        </div>
      </div>

      <Input
        title="Caption"
        value={caption}
        placeholder="Type here"
        onChange={(e) => {
          setChange(true);
          setCaption(e.currentTarget.value);
        }}
        maxLength={250}
        error={errors.caption}
        optional
      />
    </ModalSettings>
  );
};

export default ImageFieldSettings;
