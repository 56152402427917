import React, { useEffect, useState } from 'react';
import styles from '../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import OtherToggle from './OtherToggle';
import EmailToggle from './EmailToggle';
import Modal from '../../../components/base/Modal';
import ModalTitle from '../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../components/base/Modal/ModalFooter';

const UniqueModal = ({
  onCancel, onSubmit, uniqueFields, uniqueField, setUniqueField,
}) => {
  const [state, setState] = useState(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (uniqueFields) {
      const toExclude = ['wallet address', 'email'];
      const containsWallet = uniqueFields.includes('wallet address');
      const containsEmail = uniqueFields.includes('email');
      const containsOthers = uniqueFields.some((item) => !toExclude.includes(item));

      if (containsWallet && !containsEmail && containsOthers) {
        setState('walletOthers');
        setUniqueField('wallet address');
      } else if (!containsWallet && containsEmail && containsOthers) {
        setState('emailOthers');
        setUniqueField('email');
      } else if (containsWallet && containsEmail && !containsOthers) {
        setState('walletEmail');
        setUniqueField('wallet address');
      } else {
        setState('walletEmailOthers');
        setUniqueField('wallet address');
      }
    }
  }, [uniqueFields]);

  useEffect(() => {
    switch (state) {
      case 'walletOthers':
        setDescription(
          'By default, wallet address will be used as the key for the import (recommended). '
          + 'All other imported fields will update the profile(s) of the matching wallet address. '
          + 'If that wallet address is not in the platform today, a new profile will be created.',
        );
        break;
      case 'emailOthers':
        setDescription(
          'By default, email address will be used as the key for the import (recommended). '
          + 'All imported fields will update the existing profile(s) using the matching email address. '
          + 'If that email address is not in the platform today, a new profile will be created.',
        );
        break;
      case 'walletEmail':
      case 'walletEmailOthers':
        setDescription(
          'By default, wallet address will be used as the key for the import (recommended). '
          + 'All other imported fields will update the profile of the matching wallet address.',
        );
        break;
      default:
        setDescription('');
        break;
    }
  }, [state]);

  return (
    <Modal onCancel={onCancel}>
      <ModalTitle title="Import key field"/>
      <div className={`${styles.description} mb-0`}>
        <span>
          { description }
        </span>
        {state === 'walletOthers' && (
          <OtherToggle
            fields={uniqueFields.filter((item) => item !== 'wallet address')}
            uniqueField={uniqueField}
            setUniqueField={setUniqueField}
            defaultValue="wallet address"
          />
        )}
        {state === 'emailOthers' && (
          <OtherToggle
            fields={uniqueFields.filter((item) => item !== 'email')}
            uniqueField={uniqueField}
            setUniqueField={setUniqueField}
            defaultValue="email"
          />
        )}
        {state === 'walletEmail' && (
          <EmailToggle
            uniqueField={uniqueField}
            setUniqueField={setUniqueField}
          />
        )}
        {state === 'walletEmailOthers' && (
          <>
            <EmailToggle
              uniqueField={uniqueField}
              setUniqueField={setUniqueField}
            />
            <OtherToggle
              fields={uniqueFields.filter((item) => item !== 'wallet address' && item !== 'email')}
              uniqueField={uniqueField}
              setUniqueField={setUniqueField}
              defaultValue="wallet address"
            />
          </>
        )}
      </div>
      <ModalFooter>
        {onCancel && (
          <button
            type="button"
            className="outline-button border border-1"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
        )}
        <button
          type="button"
          className="regular-button"
          onClick={() => onSubmit()}
          disabled={!uniqueField}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default UniqueModal;
