import React, { useEffect, useState } from 'react';
import Table from '../../base/Table';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import { counter } from '../../../tools/Counter';
import styles from './DappUsageTable.module.scss';
import Header from '../Table/Header';

const DappUsageTable = ({
  data, isLoading, setDesc, setPage, setLimit, setOrder, limit, desc, orderBy, page, holders,
}) => {
  const [tableData, setTableData] = useState([]);

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: 'dapp_name',
      title: 'Name',
      width: '250px',
    },
    {
      field: 'category',
      title: 'Category',
    },
    {
      field: 'total_number_of_users',
      title: 'Total Users',
    },
    {
      field: 'number_of_users_last_7d',
      title: 'Users Last 7d',
    },
    {
      field: 'number_of_users_last_30d',
      title: 'Users Last 30d',
    },
    {
      field: 'number_of_users_last_90d',
      title: 'Users Last 90d',
    },
  ];

  useEffect(() => {
    if (data?.result?.length) {
      const temp = data.result.map((elem, index) => ({
        pageNumber: (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        ),
        dapp_name: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.dapp_name}
              </span>
            </div>
          </div>
        ),
        category: (
          <div className={`${styles.pill} ${styles[elem.category]} px-3 d-flex align-items-center`}>
            {elem.category}
          </div>
        ),
        total_number_of_users: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.total_number_of_users, 1)}</span>
            <span>
              {(elem.total_number_of_users / holders) * 100 >= 0.01
                ? ethFormat((elem.total_number_of_users / holders) * 100, 1)
                : '<0.01'}
              %
            </span>
          </div>
        ),
        number_of_users_last_7d: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.number_of_users_last_7d, 1)}</span>
            <span>
              {(elem.number_of_users_last_7d / holders) * 100 >= 0.01
                ? ethFormat((elem.number_of_users_last_7d / holders) * 100, 1)
                : (elem.number_of_users_last_7d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        number_of_users_last_30d: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.number_of_users_last_30d, 1)}</span>
            <span>
              {(elem.number_of_users_last_30d / holders) * 100 >= 0.01
                ? ethFormat((elem.number_of_users_last_30d / holders) * 100, 1)
                : (elem.number_of_users_last_30d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        number_of_users_last_90d: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.number_of_users_last_90d, 1)}</span>
            <span>
              {(elem.number_of_users_last_90d / holders) * 100 >= 0.01
                ? ethFormat((elem.number_of_users_last_90d / holders) * 100, 1)
                : (elem.number_of_users_last_90d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
      }));
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data, holders]);

  return (
    <div className={styles.wrapper}>
      <Header 
        title="Dapp Usage"
        info={counter(data?.count, page, limit)}
      />
      <div className="w-100">
        <Table
          total={data?.count || 0}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={orderBy}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isLoading}
          min={25}
        />
      </div>
    </div>
  );
};

export default DappUsageTable;
