import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import { getDefaultForCondition } from '../../../options';
import Trash from '../../../../../assets/icons/trash.svg';
import DropdownMultiInput from '../DropdownMultiInput';
import AddButton from '../../AddButton';
import styles from './Tags.module.scss';

const Tags = ({ conditionId, label, value, data }) => {
  const dispatch = useDispatch();
  const [selectedUtms, setSelectedUtms] = useState([])

  useEffect(() => {
    setSelectedUtms(value?.filter((v) => v.utm).map((v) => v.utm.value) || []);
  }, [value])

  const getDefaultRow = () => {
    const defaultValue = getDefaultForCondition(conditionId, label);
    if (defaultValue && defaultValue.length > 0) {
      return defaultValue[0];
    } else {
      return null;
    }
  };

  const getUtmData = (utm) => {
    return data.filter((item) => item === utm ? true : !selectedUtms.includes(item.value));
  };

  const handleAddRow = () => {
    const newRow = getDefaultRow();
    const leftData = getUtmData();
    if (leftData.length === 1) {
      newRow.utm = leftData[0];
    }
    dispatch(setSelectedValue({ conditionId, label, value: [...value, newRow] }))
  }

  const handleDelete = (id) => {
    dispatch(setSelectedValue({ conditionId, label, value: value.filter((val) => val.id !== id) }))
  }

  return (
    <div className={styles.wrapper}>
      {value.map((row, index) => (
        <div key={row.id} className={styles.row}>
          <DropdownMultiInput 
            id={row.id}
            conditionId={conditionId}
            label={label}
            utm={row.utm} 
            utmData={getUtmData(row.utm)} 
            tags={row.tags} 
            allValues={value}
          />
          {value.length !== 1 && 
            <button className={styles.trash} type='button' onClick={() => handleDelete(row.id)}><Trash /></button>}
          {index === value.length - 1 && index < 4 && 
            <AddButton onClick={handleAddRow} />}
        </div>
      ))}
    </div>
  )
}

export default Tags
