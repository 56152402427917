import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formsApi } from '../../../api/forms';
import AsyncSelect from 'react-select/async';
import { getAbTesting, getForm, setAbTestingEligible, setAbTestingOthersForm } from '../../../store/reducers/campaign';
import TextWithValue from '../Components/TextWithValue';
import { placeholderStyle } from '../../AddSegment/Condition/Condition.constants';
import styles from './AdvancedSettings.module.scss';
import { debounce } from '../../../utils/debounce';
import { parseData } from '../helper';
import { showWarningMessage } from '../../../components/base/Notifications';

const ABTesting = () => {
  const dispatch = useDispatch();
  const abTesting = useSelector(getAbTesting);
  const mainForm = useSelector(getForm);

  useEffect(() => {    
    if (mainForm 
      && abTesting 
      && abTesting.othersForm 
      && abTesting.othersForm.id === mainForm.id) {
        showWarningMessage("The selected form was also set as the alternate form in the advanced settings. It has been removed from there.")
        dispatch(setAbTestingOthersForm({
          value: null,
          label: 'Nothing',
        }));
      }
  }, [mainForm, abTesting])

  const getData = useCallback(async (val) => {
    const value = {
      search: val || '', limit: 100, additionalParams: '&active_draft_only=true'
    };
    const apiData = await dispatch(
      formsApi.endpoints.getForms.initiate(
        typeof value === 'string' ? value : { ...value },
        { forceRefetch: 10 },
      ),
    );

    const parsedData = parseData(apiData, 'forms');

    return [{
      value: null,
      label: 'Nothing',
    },...parsedData];
  }, [dispatch]);

  const loadOptions = useCallback(
    (inputValue) => {
      const filteredOptions = (options) => 
        options.filter(option => mainForm?.id ? mainForm?.id !== option.id : true);

      return debounce(() => getData(inputValue).then(filteredOptions), 1000)();
    },
    [getData, mainForm]
  );
  const handleOthersFormChange = (newVal) => {
    dispatch(setAbTestingOthersForm(newVal));
  }

  const customStyles = {
    ...placeholderStyle,
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === null ? '#90A0B7' : provided.color,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.value === null ? '#90A0B7' : provided.color,
    }),
  };

  return (
    <>
      <TextWithValue 
        textBefore="Display the form to"
        value={abTesting?.eligible} 
        textAfter="% of eligible visitors" 
        onChange={(val) => dispatch(setAbTestingEligible(Number(val)))}
        max={100}
      />
      {abTesting?.eligible < 100 ? (
        <div className={styles.others_form_wrapper}>
          <span className={styles.text}>For other visitors, if this form is not displayed, show</span>
          <AsyncSelect
            key={`unique_key__${abTesting?.othersForm}`}
            className={styles.others_form_dropdown}
            styles={customStyles}
            placeholder='Nothing'
            maxMenuHeight={194}
            value={abTesting?.othersForm}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={(val) => handleOthersFormChange(val)}
            isSearchable
            defaultOptions
          />
          <span className={styles.text}>instead under the same conditions</span>
        </div>
      ) : null}
    </>
  )
}

export default ABTesting
