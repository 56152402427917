/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { metricOptions, conversionEventOptions, conversionWindowOptions } from '../../../../utils/webSDK';
import {
  setWebSDKAttributionParam,
  selectWebSDKAttributionParam,
} from '../../../../store/reducers/webSDK';
import InfoSmall from '../../../../assets/icons/info_small.svg';
import styles from './Filters.module.scss';
import Checkbox from '../../../../components/ui/Checkbox';
import Tooltip from '../../../../components/ui/Tooltip';
import { webSDKApi } from '../../../../api/webSDK';

const Filters = ({ taggedEventsOptions, hasFetchedTaggedEvents }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const attributionParams = useSelector(selectWebSDKAttributionParam);
  const [newVisitors, setNewVisitors] = useState(attributionParams?.new_visitors_only || false);
  const [eventOptions, setEventOptions] = useState(JSON.parse(localStorage.getItem('conversionEventOptions')) || []);
  
  const {
    data: stats,
  } = webSDKApi.useGetWebSDKStatsQuery(id);

  useEffect(() => {
    localStorage.setItem('conversionEventOptions', JSON.stringify(eventOptions));
  }, [eventOptions, setEventOptions]);

  useEffect(() => {
    if (!hasFetchedTaggedEvents) return;
    if (taggedEventsOptions.length > 0) {
      dispatch(setWebSDKAttributionParam({ conversion_event: taggedEventsOptions[0].value, custom_conversion_event: null }));
    } else if (stats?.visitor_wallets_connected > 10) {
      dispatch(setWebSDKAttributionParam({ conversion_event: 'connect-wallet', custom_conversion_event: null }));
    } else {
      dispatch(setWebSDKAttributionParam({ conversion_event: 'purchase', custom_conversion_event: null }));
    }
  }, [stats, dispatch, taggedEventsOptions, hasFetchedTaggedEvents]);

  const handleCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setEventOptions((prev) => [...prev, newOption]);
    dispatch(setWebSDKAttributionParam({ conversion_event: null, custom_conversion_event: inputValue }));
  };

  const handleMetricChange = (selectedOption) => {
    dispatch(setWebSDKAttributionParam({ metric: selectedOption.value }));
  };

  const handleConversionEventChange = (selectedOption) => {
    const isDefaultEvent = conversionEventOptions.some((option) => option.value === selectedOption.value);
    const isTaggedEvent = taggedEventsOptions.some((option) => option.value === selectedOption.value);
    if (selectedOption.value === '' || selectedOption.value === null) return;
    if (isDefaultEvent || isTaggedEvent) {
      dispatch(setWebSDKAttributionParam({ conversion_event: selectedOption.value, custom_conversion_event: null }));
    } else {
      dispatch(setWebSDKAttributionParam({ conversion_event: null, custom_conversion_event: selectedOption.value }));
    }
  };

  const handleConversionWindowChange = (selectedOption) => {
    dispatch(setWebSDKAttributionParam({ conversion_window: selectedOption.value }));
  };

  const handleNewVisitorsOnlyChange = () => {
    setNewVisitors(!newVisitors);
    dispatch(setWebSDKAttributionParam({ new_visitors_only: !newVisitors }));
  };

  const visitorsTooltip = `Only consider visitors’ first-time visits for the source of traffic. 
  If unchecked, visitors might be accounted for in multiple rows.`;

  const SelectMenuButton = (props) => (
    <components.MenuList {...props}>
      {props.children}
      {eventOptions.length > 0 ? (
        <button
          type="button"
          className={`outline-button ${styles.clear_history}`}
          onClick={() => {
            localStorage.removeItem('conversionEventOptions');
            setEventOptions([]);
          }}
        >
          Clear history
        </button>
      ) : ''}
    </components.MenuList>
  );

  return (
    <div className={`${styles.filters} d-flex mb-4`}>
      <div className={styles.filterSelectItem}>
        <span className={styles.title}>
          Primary Metric
        </span>
        <Select
          className={styles.selects}
          value={metricOptions.find((option) => option.value === attributionParams.metric)}
          options={metricOptions}
          onChange={handleMetricChange}
        />
      </div>

      <div className={styles.filterSelectItem}>
        <span className={styles.title}>
          Conversion Event
        </span>
        <CreatableSelect
          className={styles.selects}
          value={
            attributionParams.custom_conversion_event
              ? {
                value: attributionParams.custom_conversion_event,
                label: attributionParams.custom_conversion_event,
                __isNew__: true,
              }
              : taggedEventsOptions.find((option) => option.value === attributionParams.conversion_event)
                || conversionEventOptions.find((option) => option.value === attributionParams.conversion_event)
          }
          isLoading={!hasFetchedTaggedEvents}
          options={[
            { label: '', options: [{ value: '', label: '' }] },
            ...(taggedEventsOptions && taggedEventsOptions.length > 0
              ? [{ 
                label: 'Tagged Events', options: taggedEventsOptions
              }] : []
            ),
            { label: 'Default Events', options: conversionEventOptions },
            { label: 'History', options: eventOptions },
          ]}
          hideSelectedOptions={false}
          onCreateOption={handleCreate}
          onChange={handleConversionEventChange}
          formatCreateLabel={(inputValue) => `"${inputValue}"`}
          formatOptionLabel={(option) => (option.__isNew__
            || (!conversionEventOptions.some((e) => e.value === option.value)
            && !taggedEventsOptions.some((e) => e.value === option.value))
            ? `Visit URL containing "${option.label}"` : option.label)}
          components={{ MenuList: SelectMenuButton }}
          createOptionPosition="first"
        />
      </div>

      <div className={styles.filterSelectItem}>
        <span className={styles.title}>
          Conversion Window
        </span>
        <Select
          className={styles.selects}
          value={conversionWindowOptions.find((option) => option.value === attributionParams.conversion_window)}
          options={conversionWindowOptions}
          onChange={handleConversionWindowChange}
        />
      </div>

      <div className={styles.checkboxContainer}>
        <div className="d-flex align-items-center">
          <Checkbox
            label="New Visitors Only"
            checked={newVisitors}
            onChange={handleNewVisitorsOnlyChange}
          />
          <div
            className={`cursor-pointer ${styles.info_btn}`}
            data-for="tooltipId"
            data-tip
          >
            <InfoSmall />
          </div>
          <Tooltip info={visitorsTooltip} id="tooltipId" position="center" />
        </div>
      </div>
    </div>
  );
};

export default Filters;
