import React, { useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { deleteConditionFromTree } from '../../../../store/reducers/segment';
import { DateSelect, DefaultSelect, TimeSelect } from '../selects';
import { FloatNumberInput, MultipleValuesInput, NumberInput } from '../inputs';
import {
  FormatGroupLabel, MultiValueLabel, MultiValueRemove, Placeholder, SingleValue,
} from '../labels';
import {
  createDefaultSelectStatements,
  createNumberInputStatements,
} from '../../../../utils/segments/createIfStatements';
import { MenuList, placeholderStyle } from '../../Condition/Condition.constants';
import { preventPaste, preventSearch } from '../../../../utils/search/preventSearch';

const datePickerValues = ['is', 'is-not', 'is-before', 'is-after'];
const restrictDatePickerValues = ['is-set', "isn't-set", 'ever', 'and-total-transaction-count',
  'and-total-ethereum-value', 'and-total-NFT-sales', 'and-total-NFT-buys', 'and-total-ethereum-value-for',
  'and-number-of-transactions-for', 'and-transaction-count', 'ever'];

const ControlFormItem = ({
  conditionName,
  edit,
  id,
  data,
  pushSelectedOptionToArray,
  setDebouncedValue,
  setInputValue,
  inputValue,
  loadCEXOptions,
  getCEXLabel,
  showHeader,
  getNftLabel,
  getFormLabel,
  isCustomDataLoading,
  nftOptions,
  setDebounceNFTValue,
  getCoinLabel,
  coinOptions,
  setDebounceCoinValue,
  loadInterestOptions,
  loadSegmentOptions,
  getSegmentLabel,
  loadCustomData,
  loadCustomListData,
  getCustomDataLabel,
  getCustomListDataLabel,
  isTeamDataLoading,
  isNftLoading,
  isCoinLoading,
  loadDappOptions,
  loadFormOptions,
  getDappLabel,
}) => {
  const filterTransactionOptions = (options) => {
    const NftRemove = ['and-total-NFT-buys', 'and-total-NFT-sales'];
    const EthRemove = ['and-total-ethereum-value', 'and-total-ethereum-value-for'];

    const selectedDappNameOption = data.options.find((option) => option.name === 'dapp-name');
    const selectedValues = selectedDappNameOption?.value || [];

    const noNFT = selectedValues.some((value) => value.isNft === false);
    const noETH = selectedValues.some((value) => value.isEth === false);

    return options.filter((option) => {
      if (noNFT && NftRemove.includes(option.value)) return false;
      if (noETH && EthRemove.includes(option.value)) return false;
      return true;
    });
  };
  return useMemo(
    () => data.options.map((option, index) => {
      if (
        option.type === 'select'
          && createDefaultSelectStatements(data, index, option)
          && (
            !['is-set', "isn't-set", 'ever'].includes(
              Array.isArray(data.options[index - 1]?.value)
                ? data.options[index - 1]?.value[0]?.value
                : null,
            )
          && !['is-set', "isn't-set", 'ever'].includes(option.value))

      ) {
        let filteredOptions = option.name === 'select-option'
          ? filterTransactionOptions(option.options) : option.options;
          if (filteredOptions.length !== 0 && filteredOptions[0].value === 'across-all-chains') {
            const desiredOrder = ['on-ethereum', 'on-polygon', 'on-base', 'on-linea', 'on-chiliz', 'on-near', 'on-flow', 'on-ordinals'];
            const sortFilterOptions = (options) => {
              const copiedOptions = [...options];
              return copiedOptions.sort((a, b) => {
                if (a.value === 'across-all-chains') return -1;
                if (b.value === 'across-all-chains') return 1;
                const indexA = desiredOrder.indexOf(a.value);
                const indexB = desiredOrder.indexOf(b.value);
                return indexA - indexB;
              });
            };
            filteredOptions = sortFilterOptions(filteredOptions);
          }
        return (
          <div className='w-100'>
            <DefaultSelect
              key={option.name}
              option={{ ...option, options: filteredOptions }}
              pushSelectedOptionToArray={pushSelectedOptionToArray}
              edit={edit}
            />
          </div>
        );
      }
      if (
        option.type === 'input' && createNumberInputStatements(data, index)
        && !['now'].includes(
          Array.isArray(data.options[index - 1]?.value) ? data.options[index - 1]?.value[0]?.value : null,
        )
      ) {
        return (
          <NumberInput
            checkFirstSelectField={data?.name}
            checkSelectField={data.options[2]?.value || []}
            conditionName={conditionName}
            option={option}
            edit={edit}
            setDebouncedValue={setDebouncedValue}
            setInputValue={setInputValue}
            inputValue={inputValue}
          />
        );
      }

      if (option.type === 'float-input'
        && !['is-set', "isn't-set"].includes(
          Array.isArray(data.options[index - 1]?.value) ? data.options[index - 1]?.value[0].value : null,
        )
      ) {
        return (
          <FloatNumberInput
            option={option}
            edit={edit}
            setDebouncedValue={setDebouncedValue}
            setInputValue={setInputValue}
            inputValue={inputValue}
          />
        );
      }

      if (option.type === 'multi-input'
        && ['contains', 'does-not-contain'].includes(
          Array.isArray(data.options[index - 2]?.value)
            ? data.options[index - 2]?.value[0].value
            : null,
        )) {
        return (
          <div className="w-100">
            <MultipleValuesInput
              deleteConditionFromTree={deleteConditionFromTree}
              conditionName={conditionName}
              id={id}
              edit={edit}
              pushSelectedOptionToArray={pushSelectedOptionToArray}
              option={option}
            />
          </div>
        );
      }

      if (option.type === 'search-cex' && (Array.isArray(data.options[index - 2]?.value)
        ? data.options[index - 2]?.value[0]?.value === 'search-cex'
        : false)) {
        return (
          <div className="w-100">
            <AsyncSelect
              styles={placeholderStyle}
              maxMenuHeight={194}
              components={
                {
                // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} />,
                }
              }
              selectProps={{ val: option.value }}
              disabled={!edit}
              className="w-100"
              key={option.type}
              isDisabled={!edit}
              value={option.value}
              loadOptions={loadCEXOptions}
              getOptionLabel={(val) => getCEXLabel(val)}
              isSearchable
              cacheOptions={false}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              defaultOptions
              isMulti
            />
          </div>
        );
      }

      if (option.type === 'search-dapp') {
        return (
          <div className="w-100">
            <AsyncSelect
              styles={placeholderStyle}
              maxMenuHeight={194}
              components={
                {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} />,
                }
              }
              selectProps={{ val: option.value }}
              disabled={!edit}
              className="w-100"
              key={option.type}
              isDisabled={!edit}
              value={option.value}
              loadOptions={loadDappOptions}
              getOptionLabel={(val) => getDappLabel(val)}
              isSearchable
              cacheOptions={false}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              defaultOptions
              isMulti
            />
          </div>
        );
      }

      if (option.type === 'search-form') {
        return (
          <div className="w-100">
            <AsyncSelect
              styles={placeholderStyle}
              maxMenuHeight={194}
              components={
                {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} />,
                }
              }
              selectProps={{ val: option.value }}
              disabled={!edit}
              className="w-100"
              key={option.type}
              isDisabled={!edit}
              value={option.value}
              loadOptions={loadFormOptions}
              hideSelectedOptions
              getOptionLabel={(val) => getFormLabel(val)}
              isSearchable
              cacheOptions={false}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              defaultOptions
              isMulti
            />
          </div>
        );
      }
      if (option.type === 'search-nft') {
        return (
          <div className="w-100">
            <Select
              maxMenuHeight={194}
              styles={placeholderStyle}
              components={
                {
                // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MenuList: (props) => <MenuList props={props} showHeader={showHeader} />,
                }
              }
              disabled={!edit}
              className="w-100"
              key={option.type}
              isDisabled={!edit}
              placeholder="Start typing to search all assets..."
              value={option.value}
              selectProps={{ val: option.value }}
              getOptionLabel={(val) => getNftLabel(val)}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              filterOption={false}
              cacheOptions={false}
              isLoading={isTeamDataLoading || isNftLoading}
              options={isTeamDataLoading || isNftLoading
                ? []
                : nftOptions}
              onInputChange={(value) => setDebounceNFTValue(value)}
              defaultOptions
              isMulti
            />
          </div>
        );
      }

      if (option.type === 'search-coin') {
        return (
          <div className="w-100">
            <Select
              components={
                {
                // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MenuList: (props) => <MenuList props={props} showHeader={showHeader} />,
                }
              }
              disabled={!edit}
              className="w-100"
              styles={placeholderStyle}
              key={option.type}
              isDisabled={!edit}
              placeholder="Start typing to search all assets..."
              value={option.value}
              getOptionLabel={(val) => getCoinLabel(val)}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              filterOption={false}
              cacheOptions={false}
              isLoading={isTeamDataLoading || isCoinLoading}
              options={isTeamDataLoading || isCoinLoading
                ? []
                : coinOptions}
              onInputChange={(value) => setDebounceCoinValue(value)}
              defaultOptions
              maxMenuHeight={194}
              isMulti
            />
          </div>
        );
      }

      if (
        option.type === 'date-picker'
        && !data.options.some((opt) => restrictDatePickerValues
          .includes(
            Array.isArray(opt?.value) ? opt?.value[0]?.value : false,
          ))
        && (
          (['date', 'timestamp', undefined].includes(data.type) && data.options.some((opt) => datePickerValues.includes(
            Array.isArray(opt?.value) ? opt?.value[0]?.value : false,
          )))
        )
      ) {
        return (
          <DateSelect
            option={option}
            pushSelectedOptionToArray={pushSelectedOptionToArray}
            edit={edit}
          />
        );
      }

      if (
        option.type === 'time-picker' && !data.options.some((opt) => ['is-set', "isn't-set"].includes(
          Array.isArray(opt?.value) ? opt.value[0].value : false,
        ))
        && data.options.some((opt) => datePickerValues.includes(
          Array.isArray(opt?.value) ? opt?.value[0].value : false,
        ))
      ) {
        return (
          <TimeSelect
            option={option}
            pushSelectedOptionToArray={pushSelectedOptionToArray}
            edit={edit}
          />
        );
      }

      if (option.type === 'search') {
        return (
          <div className="w-100">
            <AsyncSelect
              styles={placeholderStyle}
              maxMenuHeight={194}
              disabled={!edit}
              isDisabled={!edit}
              key={option.label}
              className="w-100"
              value={option.value}
              loadOptions={loadInterestOptions}
              defaultOptions
              isSearchable
              onPaste={(e) => {
                preventPaste(e);
              }}
              onKeyDown={(e) => {
                preventSearch(e);
              }}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              components={{
                SingleValue,
                Placeholder,
                IndicatorSeparator: () => null,
                // eslint-disable-next-line react/no-unstable-nested-components
                MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                // eslint-disable-next-line react/no-unstable-nested-components
                MultiValueLabel: (props) => <MultiValueLabel props={props} />,
              }}
              isMulti
            />
          </div>
        );
      }

      if (option.type === 'search-segment') {
        return (
          <div className="w-100">
            <AsyncSelect
              styles={placeholderStyle}
              maxMenuHeight={194}
              components={
                {
                // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} show="label" />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} show="label" />,
                }
              }
              disabled={!edit}
              className="w-100"
              key={data.label}
              formatGroupLabel={FormatGroupLabel}
              isDisabled={!edit}
              value={option.value}
              loadOptions={loadSegmentOptions}
              getOptionLabel={(val) => getSegmentLabel(val)}
              isSearchable
              cacheOptions={false}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              defaultOptions
              isShowValue
              isMulti
            />
          </div>
        );
      }

      if (option.type === 'custom-search'
        && ['is', 'is-not'].includes(Array.isArray(data.options[0]?.value)
          ? data.options[0].value[0]?.value : false)) {
        return (
          <div className="w-100">
            <AsyncSelect
              styles={placeholderStyle}
              maxMenuHeight={194}
              components={
                {
                // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} />,
                }
              }
              disabled={!edit}
              className="w-100"
              key={data.label}
              formatGroupLabel={FormatGroupLabel}
              isDisabled={!edit}
              value={option.value}
              loadOptions={loadCustomData}
              getOptionLabel={(val) => getCustomDataLabel(val)}
              isSearchable
              cacheOptions={false}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              defaultOptions
              isShowValue
              isMulti
            />
          </div>
        );
      }

      if (option.type === 'search-list') {
        return (
          <div className="w-100">
            <AsyncSelect
              styles={placeholderStyle}
              maxMenuHeight={194}
              components={
                {
                // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => <MultiValueRemove props={props} />,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => <MultiValueLabel props={props} show="label" />,
                }
              }
              disabled={!edit}
              className="w-100"
              key={data.label}
              formatGroupLabel={FormatGroupLabel}
              isDisabled={!edit || isCustomDataLoading}
              value={option.value}
              loadOptions={loadCustomListData}
              getOptionLabel={(val) => getCustomListDataLabel(val)}
              isSearchable
              cacheOptions={false}
              onChange={(val) => {
                pushSelectedOptionToArray(val, option.name, option.type);
              }}
              defaultOptions
              isShowValue
              isMulti
            />
          </div>
        );
      }

      return null;
    }),
    [
      data,
      pushSelectedOptionToArray,
      edit,
      inputValue,
      loadCEXOptions,
      isTeamDataLoading,
      isNftLoading,
      nftOptions,
      isCoinLoading,
      coinOptions,
      loadInterestOptions,
      loadCustomData,
      conditionName,
      id,
      loadCustomListData,
      isCustomDataLoading,
      loadSegmentOptions,
      showHeader,
      getCEXLabel,
      getCoinLabel,
      getCustomDataLabel,
      getNftLabel,
      getCustomListDataLabel,
      getSegmentLabel,
    ],
  );
};

export default ControlFormItem;
