import React, { useState } from 'react';
import { components } from 'react-select';

import ChainLogo from '../../../../components/base/ChainLogo';
import styles from './MultiValueLabel.module.scss';
import sharedStyles from './Shared.module.scss';
import DeleteIcon from '../../../../assets/icons/delete.svg';
import DefaultIcon from '../../../../components/ui/DefaultIcon';
import Tooltip from '../../../../components/ui/Tooltip'; // Assuming the path to Tooltip
import WalletIcon from '../../../../components/base/WalletIcon';
import { getWalletLabel } from '../../../../utils/walletIcons';

export const MultiValueRemove = ({
  props,
}) => {
  const propsToSet = { ...props };
  // propsToSet.innerProps.className = null;
  return (
    <components.MultiValueRemove {...propsToSet}>
      <div className={`${styles.icon_container} d-flex flex-column align-items-center justify-content-center h-100`}>
        <DeleteIcon />
      </div>
    </components.MultiValueRemove>
  );
};

export const MultiValueLabel = ({ props, show = 'value', isWalletName = false }) => {
  const [imgError, setImgError] = useState(false);

  const labelValue = props?.selectProps?.isShowValue && show !== 'label'
    ? Array.isArray(props?.data?.value) ? props?.data?.value[0].value : props?.data?.value || 'No Value'
    : Array.isArray(props?.data?.value) ? props?.data?.value[0].label : props?.data?.label || 'No Value';

  return (
    <components.MultiValueLabel {...props}>
      <div
        className={`${styles.container} d-flex align-items-center`}
      >
        <div className={sharedStyles.indicatorWrapper}>
          <ChainLogo chain={props?.data?.network} small square />
          {props?.data?.network && <span className={sharedStyles.separator} />}
          {props?.data?.wallet ? <WalletIcon wallet={props?.data.wallet} rounded /> : null}
          {props?.data?.additionalData && !imgError ? (
            <img
              src={props?.data?.additionalData}
              onError={() => setImgError(true)}
              className="rounded-full"
              width="20"
              height="20"
              alt="coin"
            />
          ) : (
            ['coin', 'nft'].includes(props?.data?.type)
            && <DefaultIcon size="20px" type={props?.data?.type} />
          )}
        </div>
        <span
          className={`${styles.content} text-truncate ms-1`}
          data-tip={labelValue}
          data-for={`tooltip-${labelValue}`}
        >
          {isWalletName ? getWalletLabel(labelValue) : labelValue}
        </span>
        <Tooltip id={`tooltip-${labelValue}`} info={labelValue} />
      </div>
    </components.MultiValueLabel>
  );
};
