import React, {
  useState, useMemo, useEffect, useCallback, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { Helmet } from 'react-helmet';
import { audiencesApi } from '../../api/audiences';
import uniqueId from '../../utils/uniqueId';
import { supportedBlockchains } from '../../utils/supportedBlockchains';
import {
  createCondition,
  setTeamDataset,
  getCreatedConditions,
  getUserSegment,
  getUserSegmentName,
  getEditMode,
  changeEditMode,
  mapCreatedSegmentToConditions,
  setCreatedSegment,
  clearState,
  selectSegmentRequestStatus,
  getBaseStructure,
} from '../../store/reducers/segment';
import { uuidv4 } from '../../utils/uuidv4';
import RoundSpinner from '../../components/base/RoundSpinner';
import { useCallbackPrompt } from '../../components/hooks/app/useCallbackPrompt';
import { userApi } from '../../api/user';
import ConfirmModal from '../../components/ui/modals/ConfirmModal';
import { convertNumber } from '../../tools/NumberConverterTool';
import TitleControls from './TitleControls';
import PageContent from './PageContent';
import { ethFormat } from '../../utils/singleAssetPage/parseData';
import Discord from '../../assets/icons/discord_segment.svg';
import Twitter from '../../assets/icons/twitter_segment.svg';
import Email from '../../assets/icons/email_segment.svg';
import Phone from '../../assets/icons/phone_segment.svg';
import Telegram from '../../assets/icons/telegram_segment.svg';
import XMTP from '../../assets/icons/xmtp.svg';
import { LoadingLines } from '../../components/ui/modals/SearchModal/LoadingList';
import SegmentsFooter from '../../components/Layout/Footer/Segments';
import { getGroup } from '../../utils/segments/getGroup';
import styles from './AddSegment.module.scss';

const pages = [
  { label: 'Conditions', id: 1 },
  { label: 'Profile List', id: 2 },
];

const AddSegment = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [inputValue, setInputValue] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [conditionNames, setConditionNames] = useState([]);
  const [datasetError, setDatasetError] = useState();
  const [errorModal, setErrorModal] = useState(false);
  const [formattedTeamData, setFormattedTeamData] = useState();

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const timestampRef = useRef(Date.now()).current;

  const name = useSelector(getUserSegmentName);
  const editMode = useSelector(getEditMode);
  const userSegment = useSelector(getUserSegment);
  const createdConditions = useSelector(getCreatedConditions);
  const onRequest = useSelector(selectSegmentRequestStatus);
  const baseStructure = useSelector(getBaseStructure);

  const hasConditions = createdConditions?.length > 0 || false;

  const handleCondition = () => {
    const findCondition = createdConditions.map((elem) => elem.category);
    return !!(findCondition.includes('Investments') || findCondition.includes('History'));
  };

  const {
    currentData: segment,
    isLoading: isSegmentLoading,
  } = audiencesApi.useGetSegmentByIdQuery(
    {
      id: pathname.split('/')[2],
      timestampRef,
    },
    { skip: !pathname.split('/')[2] },
  );

  const { data: segmentData, isLoading: isSegmentDataLoading } = audiencesApi.useGetSegmentDataQuery();

  useEffect(() => {
    dispatch(setTeamDataset(segmentData?.segment?.team_dataset || null));
  }, [segmentData, dispatch]);

  const {
    data: totalWalletReach,
    isLoading: isTotalWalletReachLoading,
    isFetching: isTotalWalletReachFetching,
    error: walletReachError,
  } = audiencesApi.useGetSegmentWalletsReachQuery(
    userSegment,
    {
      skip: !userSegment?.segment?.team_dataset,
    },
  );

  const {
    data: teamData,
    isLoading: teamDataIsLoading,
    isFetching: teamDataIsFetching,
  } = userApi.useGetAssetsSearchQuery(
    undefined,
    {
      skip: !handleCondition(),
    },
  );

  // get and convert the data
  const convertTeamData = useCallback((data) => {
    const coins = data.filter((elem) => elem.asset_type === 'coin');
    const nfts = data.filter((elem) => elem.asset_type === 'nft');
    const formattedCoins = [];
    coins.forEach((coin) => {
      supportedBlockchains(coin.platforms).map((network) => {
        formattedCoins.push({ ...coin, platform: coin.platforms[network], network });
      });
    });
    setFormattedTeamData({ ...data, tokens: formattedCoins, nfts });
  }, []);

  useEffect(() => {
    if (teamData) {
      convertTeamData(teamData);
    }
  }, [teamData, convertTeamData]);

  useEffect(() => {
    dispatch(changeEditMode(searchParams.get('edit') !== 'false'));
  }, [searchParams, dispatch]);

  // TODO: handler for counting

  const alertData = useMemo(() => ({
    id: 1,
    content: isTotalWalletReachLoading || isTotalWalletReachFetching
      ? <div className="p-2"><LoadingLines width="30%" height="30px" flexDirection="row" /></div>
      : `${!walletReachError
        ? convertNumber(totalWalletReach?.profile_count) : '?'}
        ${totalWalletReach?.profile_count > 1 || totalWalletReach?.profile_count === 0 ? 'profiles' : 'profile'}`,
    header: 'Total Profile Count:',
  }), [isTotalWalletReachFetching, isTotalWalletReachLoading, totalWalletReach?.profile_count, walletReachError]);

  const alertReachData = useMemo(() => ({
    id: 1,
    content: isTotalWalletReachLoading || isTotalWalletReachFetching
      ? <div className="p-2"><LoadingLines width="30%" height="30px" flexDirection="row" /></div>
      : !walletReachError && totalWalletReach?.profile_count
        ? `${ethFormat((totalWalletReach.reach / totalWalletReach.profile_count) * 100)}%`
        : '0%',
    header: 'Reach:',
  }), [isTotalWalletReachFetching, isTotalWalletReachLoading, totalWalletReach, walletReachError]);

  const alertChannelsData = useMemo(() => {
    const data = [{
      icon: <Discord />,
      value: totalWalletReach?.discord,
      header: 'Discord',
    }, {
      icon: <Twitter />,
      value: totalWalletReach?.twitter,
      header: 'Twitter',
    }, {
      icon: <Email />,
      value: totalWalletReach?.email,
      header: 'Email',
    }, {
      icon: <Phone />,
      value: totalWalletReach?.phone,
      header: 'Phone',
    }, {
      icon: <Telegram />,
      value: totalWalletReach?.telegram,
      header: 'Telegram',
    }, {
      icon: <XMTP />,
      value: totalWalletReach?.xmtp,
      header: 'XMTP',
    }];

    return ({
      id: 1,
      content: isTotalWalletReachLoading || isTotalWalletReachFetching
        ? <div className="p-2"><LoadingLines width="30%" height="30px" flexDirection="row" /></div>
        : !walletReachError && data.map((item) => item.value).filter((elem) => !!elem).length
          ? (
            <div className={`${styles.wrap_channels} d-flex align-items-center w-100`}>
              {data.slice().sort((a, b) => (a.value > b.value ? -1 : 1)).map((elem, i) => (
                elem.value && totalWalletReach?.profile_count ? (
                  <div key={`id-${i}`} className={`${styles.channels} d-flex flex-column align-items-center mt-2`}>
                    <div>
                      {elem.icon}
                    </div>
                    <span className={styles.header}>{elem.header}</span>
                    <span className={styles.value}>
                      {(elem.value / totalWalletReach.profile_count) * 100 < 1
                        ? '<1%'
                        : `${ethFormat((elem.value / totalWalletReach.profile_count) * 100)}%`}
                    </span>
                  </div>
                ) : null
              ))}
            </div>
          )
          : '-',
      header: 'Top channels:',
    });
  }, [isTotalWalletReachFetching, isTotalWalletReachLoading, totalWalletReach, walletReachError]);

  useEffect(() => {
    if (segmentData) {
      setConditionNames(segmentData.segment.categories.map((category) => category.name));
    }
  }, [segmentData]);

  // parse from this huge json the names of certain conditioners (Investments, History and etc.)
  const menuData = useMemo(() => {
    const data = conditionNames && conditionNames.map((conditionName) => ({
      id: conditionName,
      name: conditionName,
      group: getGroup(conditionName),
      action: (condName) => {
        const idToSet = uuidv4();
        dispatch(createCondition({ id: idToSet, category: condName }));
      },
    }))

    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.group]) {
          acc[item.group] = [];
      }
      acc[item.group].push(item);
      return acc;
    }, {});

    const orderedGroupedData = Object.entries(groupedData).sort((a, b) => {
      const order = [
        "On-Chain Profile", 
        "Holdings", 
        "CRM", 
        "Behavior Tracking"
      ];
      return order.indexOf(a[0]) - order.indexOf(b[0]);
    }).map(([key, value]) => ({ [key]: value }));

    return orderedGroupedData;
  }, [conditionNames, dispatch]);

  // after selecting a category, we get the category we need from json
  const getFilters = useCallback((categoryName) => {
    if (segmentData) {
      const { filters } = segmentData.segment.categories.filter((category) => category.name === categoryName)[0];
      return filters;
    }
    return null;
  }, [segmentData]);

  // Dialogue when exiting the page if you haven't finished making changes
  useEffect(() => {
    const checkIfShowWarning = () => {
      if (baseStructure && isEqual(baseStructure, createdConditions)) {
        setShowDialog(false);
        return;
      }

      if (segment?.IsInRunning || onRequest || !editMode) {
        setShowDialog(false);
        return;
      }
      if (createdConditions && createdConditions.length > 0) {
        setShowDialog(true);
        return;
      }
      setShowDialog(false);
    };

    checkIfShowWarning();
  }, [createdConditions, segment?.IsInRunning, onRequest, editMode, baseStructure]);

  // the already created segment is received, checked and parsed
  useEffect(() => {
    if (segment && segment.segment && segmentData) {
      if (segment.is_restricted) {
        setDatasetError('The segment is not available');
        setErrorModal(true);
        return;
      }
      dispatch(mapCreatedSegmentToConditions({ segment: segment.segment, data: segmentData }));
      dispatch(setCreatedSegment({
        segment: segment.segment,
        name: segment.name,
        id: segment.id,
        isInRunning: segment.IsInRunning,
      }));
      setInputValue(segment.name);
    }
  }, [segment, dispatch, segmentData]);

  useEffect(() => () => dispatch(clearState()), [dispatch]);

  // if there are no created conditions or wallets are being counted, we don’t let you into the wallet tab
  const isWalletListTabDisabled = useMemo(
    () => !userSegment.segment.categories.some((category) => category.filters.length)
    || !totalWalletReach?.profile_count
    || isTotalWalletReachLoading
    || isTotalWalletReachFetching,
    [totalWalletReach, userSegment, isTotalWalletReachLoading, isTotalWalletReachFetching],
  );

  const areConditionsValid = useMemo(() => {
    const allValidIds = userSegment.segment?.categories?.flatMap(category => category.filters.map(filter => filter.id)) || [];
    return createdConditions.every((condition) => 
      allValidIds.includes(condition.id)
    );
  }, [createdConditions, userSegment?.segment?.categories]);

  const mainContent = useMemo(
    () => (
      <PageContent
        isSegmentDataLoading={isSegmentDataLoading}
        editMode={editMode}
        createdConditions={createdConditions}
        selectedPage={selectedPage}
        userSegment={userSegment}
        setShowDialog={setShowDialog}
        showDropdown={showDropdown}
        isInRunning={segment?.IsInRunning}
        getFilters={getFilters}
        formattedTeamData={formattedTeamData}
        teamDataIsLoading={teamDataIsLoading}
        teamDataIsFetching={teamDataIsFetching}
        setShowDropdown={setShowDropdown}
        menuData={menuData}
        alertData={alertData}
        alertReachData={alertReachData}
        alertChannelsData={alertChannelsData}
        totalWalletReach={totalWalletReach}
        isWalletTotalReachLoading={isTotalWalletReachLoading || isTotalWalletReachFetching}
      />
    ),
    [
      isSegmentDataLoading,
      selectedPage,
      createdConditions,
      segment?.IsInRunning,
      editMode,
      showDropdown,
      menuData,
      alertData,
      alertReachData,
      alertChannelsData,
      getFilters,
      teamDataIsLoading,
      teamDataIsFetching,
      formattedTeamData,
      userSegment,
    ],
  );

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {name}
          {' '}
          Audiences
          {' '}
          - Absolute Labs Platform
        </title>
      </Helmet>
      {showPrompt && (
        <ConfirmModal
          onSubmit={confirmNavigation}
          onCancel={cancelNavigation}
          buttonName="Proceed"
          title="Unsaved values"
          description="All unsaved values will not be saved! Are you sure?"
        />
      )}
      {
        errorModal
           && (
             <ConfirmModal
               title="Dataset was deleted"
               description="This segment cannot be accessed because the dataset, that it used, was deleted or edited."
               onSubmit={() => { navigate('/audiences'); }}
             />
           )
      }
      {onRequest || isSegmentLoading || isSegmentDataLoading
        ? <RoundSpinner />
        : null}
      <TitleControls
        inputValue={inputValue}
        setInputValue={setInputValue}
        isInRunning={segment?.IsInRunning}
      />
      {datasetError
        ? (
          <div className={styles.edit}>
            {datasetError}
          </div>
        )
        : null}
      {segment?.IsInRunning
        ? (
          <div className={styles.edit}>
            You cannot edit the segment it is used in the running or stopped flow
          </div>
        )
        : null}
      <div className="row asset-section mt-4">
        <div className="no-padding">
          <div className="title-gap navigation">
            <ul className="nav nav-pills gap-2 no-select">
              {pages.map((item) => (
                <li
                  role="presentation"
                  className={
                    `nav-item
                  ${item.id === 2 && isWalletListTabDisabled ? styles.disabled : ''}`
                  }
                  key={uniqueId(item.label)}
                  onClick={() => {
                    if (item.id === 2 && isWalletListTabDisabled) {
                      return;
                    }
                    setSelectedPage(item.id);
                  }}
                >
                  <div
                    className={`nav-link ${
                      item.id === selectedPage ? 'active' : ''
                    }`}

                  >
                    {item.label}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="divider" />
        </div>
        {mainContent}
        {!userSegment?.isInRunning ? (
          <SegmentsFooter 
            folder={state && state.folder ? state.folder : null} 
            disabled={!hasConditions || !areConditionsValid}
            setTitle={setInputValue}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddSegment;
