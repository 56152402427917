import React from 'react';
import Input from '../../../../components/base/Input';
import Select from '../../../../components/base/Select';
import styles from './EditModal.module.scss';
import Modal from '../../../../components/base/Modal';
import ModalTitle from '../../../../components/base/Modal/ModalTitle';

const options = [
  { label: 'Select', value: '' },
  { label: 'Admin', value: 'team_admin' },
  { label: 'Member', value: 'team_member' },
];
const EditModal = ({
  userInfo,
  setShowModal,
  handleSumbitEdit,
  registerEdit,
  onChangeMember,
  editFormState,
  editId,
  isTouched,
  setIsTouched,
}) => {
  return (
    <Modal onCancel={() => setShowModal(false)}>
      <ModalTitle title="Team member info editing" />
      <form
        className={`d-flex flex-column justify-content-center ${styles.modalBodyWrapper}`}
        onSubmit={handleSumbitEdit(onChangeMember)}
      >
        <Input
          name="first_name"
          label="First name*"
          validation={{ required: 'This is a required field' }}
          register={registerEdit}
          error={editFormState && editFormState.errors.first_name}
        />
        {editFormState && editFormState.errors.first_name && (
          <div className={styles.errorMessage}>
            {editFormState.errors.first_name.message}
          </div>
        )}
        <Input
          name="last_name"
          validation={{ required: 'This is a required field' }}
          label="Last name*"
          register={registerEdit}
        />
        {editFormState && editFormState.errors.last_name && (
          <div className={styles.errorMessage}>
            {editFormState.errors.last_name.message}
          </div>
        )}
        <Select
          name="category"
          label="Role*"
          options={options}
          register={registerEdit}
          onChange={() => setIsTouched(true)}
        />
        {editFormState && editFormState.errors.category && (
          <div className={styles.errorMessageSelect}>
            {editFormState.errors.category.message}
          </div>
        )}
        {isTouched && userInfo.id === editId && (
          <div className={styles.warningMessage}>
            If you change the role to member, you will be logged out.
          </div>
        )}
        <button
          type="submit"
          className={`btn btn-primary ${styles.button}`}
        >
          <span className={styles.buttonText}>Save changes</span>
        </button>
      </form>
    </Modal>
  );
};

export default EditModal;
