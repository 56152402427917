export const submitTokenAirdrop = (
  submitData,
  tokenAirdropPayload,
  selectedItem,
) => {
  const newObj = { ...submitData };

  if (selectedItem?.image_url) {
    newObj.assetImg = selectedItem.image_url;
  }
  if (selectedItem?.name) {
    newObj.assetName = selectedItem.name;
  }
  if (tokenAirdropPayload?.selectedContract) {
    newObj.token_address = tokenAirdropPayload.selectedContract;
  }

  newObj.asset_id = selectedItem?.id;
  newObj.token_raw_amount = newObj.token_amount * 10 ** newObj.decimals;
  return newObj;
};

export const submitNftAirdrop = (
  submitData,
  selectedItem,
) => {
  const newObj = { ...submitData };
  if (selectedItem.image_url) {
    newObj.assetImg = selectedItem.image_url;
  }
  if (selectedItem.name) {
    newObj.assetName = selectedItem.name;
  }
  newObj.asset_id = selectedItem?.id;
  return newObj;
};

export const submitCustomAirdrop = (submitData, customAirdropPayload) => {
  const newObj = { ...submitData };

  newObj.data = {};

  if (customAirdropPayload.contractAddress) {
    newObj.data.contract_address = customAirdropPayload.contractAddress;
  }

  if (customAirdropPayload.selectedFunction) {
    newObj.data.selected_function = customAirdropPayload.selectedFunction;
  }

  if (customAirdropPayload.etherscanStatus) {
    newObj.data.etherscan_status = customAirdropPayload.etherscanStatus;
  }

  if (customAirdropPayload.abi) {
    newObj.data.abi = customAirdropPayload.abi;
  }

  if (customAirdropPayload.contractFunctions) {
    newObj.data.contract_functions = customAirdropPayload.contractFunctions;
  }

  if (customAirdropPayload.selectedParameters) {
    newObj.data.selected_parameters = customAirdropPayload.selectedParameters;
  }

  return newObj;
};
