import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { audiencesApi } from '../../../api/audiences';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import Snapshot from '../../../components/ui/Buttons/Snapshot'
import SaveSnapshotModal from '../../../components/ui/modals/SaveSnapshotModal';

const ProfileSnapshot = ({ segment }) => {
  const { id } = useParams();
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [snapshotName, setSnapshotName] = useState('');
  const [snapshotSegment, snapshotSegmentResult] = audiencesApi.useSnapshotSegmentsMutation();
  const [snapshotDraftSegment, snapshotDraftSegmentResult] = audiencesApi.useSaveCustomListFromSegmentDraftMutation();

  useEffect(() => {
    if (snapshotDraftSegmentResult.isSuccess) {
      showSuccessMessage('Segment was successfully saved as List');
      setShowSnapshotModal(false);
      snapshotDraftSegmentResult.reset();
    }
    if (snapshotDraftSegmentResult.isError) {
      showErrorMessage(snapshotDraftSegmentResult?.error?.data?.error?.message || 'Something went wrong');
      setShowSnapshotModal(false);
      snapshotDraftSegmentResult.reset();
    }
  }, [snapshotDraftSegmentResult]);

  useEffect(() => {
    if (snapshotSegmentResult.isSuccess) {
      showSuccessMessage('Segment was successfully saved as List');
      setShowSnapshotModal(false);
      snapshotSegmentResult.reset();
    }
    if (snapshotSegmentResult.isError) {
      showErrorMessage(snapshotSegmentResult?.error?.data?.message || 'Something went wrong');
      setShowSnapshotModal(false);
      snapshotSegmentResult.reset();
    }
  }, [snapshotSegmentResult]);

  return (
    <>
      <Snapshot tooltip="Save snapshot as List" onClick={() => setShowSnapshotModal(true)} />
      {showSnapshotModal && (
              <SaveSnapshotModal
                value={snapshotName}
                setValue={setSnapshotName}
                onSubmit={() => {
                  try {
                    if (id) {
                      snapshotSegment({ id, name: snapshotName });
                    } else {
                      snapshotDraftSegment({ ...segment, name: snapshotName });
                    }
                  } catch (err) {
                    showErrorMessage(err);
                  }
                }}
                onCancel={() => setShowSnapshotModal(false)}
                loading={
                  (snapshotSegmentResult.isLoading || snapshotSegmentResult.isFetching
                    || snapshotDraftSegmentResult.isLoading || snapshotDraftSegmentResult.isFetching)
                }
              />
            )}
    </>
  )
}

export default ProfileSnapshot
