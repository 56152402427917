import React from 'react';
import { useDispatch } from 'react-redux';
import { removeCondition } from '../../../../store/reducers/campaign';
import Trash from '../../../../assets/icons/trash.svg';
import styles from './ConditionWrapper.module.scss';

const ConditionWrapper = ({ id, label, children }) => {
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(removeCondition({conditionId: id}));
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {label}
        <button className={styles.trash} type='button' onClick={handleRemove}><Trash /></button>
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default ConditionWrapper
