import React from 'react';
import TickCircle from '../../../../assets/icons/tick_circle.svg';
import XCircle from '../../../../assets/icons/x-circle.svg';
import styles from './DownloadModal.module.scss';
import Loader from '../../../base/Loader';
import Modal from '../../../base/Modal';

const DownloadModal = ({ setIsOpen, result }) => {
  const onCancel = () => {
    if (!result.isLoading) {
      setIsOpen(false);
      result.reset();
    }
  }

  return (
    <Modal onCancel={onCancel}>
      <div className={styles.content}>
        <div className={styles.loading}>
          {result.isLoading
            ? <Loader />
            : result.isSuccess
              ? <TickCircle className="" />
              : <XCircle className="" />}
        </div>
        <div className="text-h3-regular text-center mb-2 mt-1 fw-bold d-flex flex-column">
          {result.isLoading
            ? (
              <>
                <span>Your export is being generated.</span>
                <span>Please hold, this can take up to a minute.</span>
              </>
            )
            : result.isSuccess
              ? 'The file has been generated.'
              : (
                <>
                  <span>File generation failed.</span>
                  <span>Please try again later.</span>
                </>
              )}
        </div>
        {result.isSuccess
          && (
            <div className={`${styles.message} text-center`}>
              If the download does not start in a couple of seconds, please check your browser settings.
            </div>
          )}
      </div>
    </Modal>
  );
};

export default DownloadModal;
