import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useWeb3Modal } from '@web3modal/react';
import {
  mainnet,
  polygon,
  sepolia,
} from 'wagmi/chains';
import {
  amoy, base, baseSepolia, linea, lineaSepolia, chiliz, chilizSpicy,
} from '../../../../../../utils/web3/customChains.ts';
import { ethFormat } from '../../../../../../utils/singleAssetPage/parseData';
import ProvideTokensModal from './ProvideTokensModal';
import { getERC20Address, setERC20Address } from '../../../../../../store/reducers/web3';
import { getAirdropContractAddress } from '../../../../../../utils/web3/loadContract';
import { coinApi } from '../../../../../../api/coin';
import { userApi } from '../../../../../../api/user';
import McapLabel from '../../../Components/McapLabel';
import { debounce } from '../../../../../../utils/debounce';
import { isValidContractAddress } from '../../../../../../tools/EtherTool';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import { getFormattedCoinData } from '../../../../../../utils/segments/getCoinNftData';
import { getUser } from '../../../../../../store/reducers/user';
import warning from '../../../../../../assets/icons/warning_rounded.svg?url';
import styles from './TokenAirdrop.module.scss';
import { setDecimalsReducer, selectDecimalsReducer, setTeamBalanceReducer } from '../../../../../../store/reducers/flows';
import { ControlComponent, onChainInputStyles, sourceInputStyles } from '../SelectComponentStyling';
import { blockchainApi } from '../../../../../../api/blockchain';
import Warning from '../../../../../../assets/icons/warning_rounded.svg';
import { blockChainOptionsWithTestnets } from '../../../../../../components/base/ChainLogo/chains';
import { excludedBlockchainsForFlows } from '../../../../../../utils/supportedBlockchains';
import { submitTokenAirdrop } from '../../../../../../utils/airdrop/submitUtils';

const TokensAirdrop = ({
  airdropSavedData,
  isDisabled,
  onSettingsChange,
}) => {
  const dispatch = useDispatch();
  const selectedContract = useSelector(getERC20Address);
  const decimals = useSelector(selectDecimalsReducer)
  const savedTokenAirdropData = airdropSavedData?.asset_type === "tokens" ? airdropSavedData : null;
  const { open, setDefaultChain } = useWeb3Modal();
  
  const [selectedItem, setSelectedItem] = useState(savedTokenAirdropData ? {
    value: savedTokenAirdropData.assetName || savedTokenAirdropData.address,
    label: savedTokenAirdropData.asset_name
      || `${savedTokenAirdropData.address?.slice(0, 5)}...${savedTokenAirdropData.address?.slice(-5)}`,
    image_url: savedTokenAirdropData.assetImg,
    id: savedTokenAirdropData.asset_id,
    blockchain: savedTokenAirdropData.blockchain,
    contract_address: savedTokenAirdropData.address,
  } : null);
  const [selectedBlockchain, setSelectedBlockchain] = useState(blockChainOptionsWithTestnets.find(
    (blockchain) => blockchain.value === savedTokenAirdropData?.blockchain)?.network);
  const [selectedAddress, setSelectedAddress] = useState(savedTokenAirdropData?.address);
  const [tokenAmount, setTokenAmount] = useState(savedTokenAirdropData?.token_amount);
  
  const [showProvideTokenModal, setShowProvideTokenModel] = useState(false);
  const [showAirDropWarning, setShowAirDropWarning] = useState(false);
  const [teamBalance, setTeamBalance] = useState(null);
  const [isLoadingBalance, setIsLoadingBalance] = useState (false);
  const [coinOptions, setCoinOptions] = useState([]);
  const user = useSelector(getUser);

  const openWeb3Modal = useCallback(async () => {
    switch (selectedBlockchain) {
      case 'homestead': setDefaultChain(mainnet); break;
      case 'sepolia': setDefaultChain(sepolia); break;
      case 'matic': setDefaultChain(polygon); break;
      case 'amoy': setDefaultChain(amoy); break;
      case 'base': setDefaultChain(base); break;
      case 'base-sepolia': setDefaultChain(baseSepolia); break;
      case 'linea': setDefaultChain(linea); break;
      case 'linea-sepolia': setDefaultChain(lineaSepolia); break;
      case 'chiliz': setDefaultChain(chiliz); break;
      case 'chiliz-spicy': setDefaultChain(chilizSpicy); break;
    }
    await open();
  }, [selectedBlockchain, open, setDefaultChain]);

  useEffect(() => {
    if (!isLoadingBalance
      && selectedBlockchain 
      && selectedAddress
      && tokenAmount
      && +tokenAmount > 0
      && +tokenAmount <= teamBalance) {
        let newObj = {};
        newObj = submitTokenAirdrop(
          { 
            address: selectedAddress,
            blockchain: blockChainOptionsWithTestnets
            .find((elem) => elem.network === selectedBlockchain)?.value,
            assetType: 'tokens',
            asset_name: selectedItem?.name || '',
            token_amount: +tokenAmount,
            decimals: decimals || 18,
          },
          {
            selectedContract
          },
          selectedItem,
        );
        onSettingsChange(newObj);
      } else {
        onSettingsChange(null);
      }
  }, [
    isLoadingBalance,
    selectedBlockchain,
    selectedAddress,
    selectedItem,
    tokenAmount,
    teamBalance,
  ])

  const {
    data: searchCoin,
    isLoading: searchCoinIsLoading,
    isFetching: searchCoinIsFetching,
  } = coinApi.useSearchERC20TokenQuery(
    {
      query: '',
    },
  );

  const {
    data: teamData,
    isLoading: teamDataIsLoading,
    isFetching: teamDataIsFetching,
  } = userApi.useGetAssetsSearchQuery();

  const fetchCoinOptions = useCallback(() => {
    const filteredSearchCoin = searchCoin?.filter((item) => !excludedBlockchainsForFlows.includes(item.blockchain));
    const filteredTeamData = teamData?.filter((item) => !excludedBlockchainsForFlows.includes(item.network));
    const formattedCoinData = getFormattedCoinData(filteredTeamData, filteredSearchCoin);
    setCoinOptions(formattedCoinData[0] && formattedCoinData[0].length
      ? formattedCoinData[0] : formattedCoinData[1].slice(0, 5));
  }, [teamData, searchCoin]);

  useEffect(() => {
    fetchCoinOptions();
  }, [fetchCoinOptions, teamData, searchCoin]);

  const getBalance = async () => {
    setIsLoadingBalance(true);
    if (user.team && selectedAddress && selectedBlockchain) {
      const selectedBlockChainValue = blockChainOptionsWithTestnets
        .find((elem) => elem.network === selectedBlockchain)?.value;
      const airdropContractAddress = getAirdropContractAddress(selectedBlockChainValue);
      const response = await dispatch(
        blockchainApi
          .endpoints
          .getTeamTokenBalance
          .initiate({
            blockchain: selectedBlockChainValue,
            userContractAddress: selectedAddress,
            teamContractAddress: airdropContractAddress,
          }, { forceRefetch: true }),
      );
      if (response?.error) {
        setTeamBalance(0);
        dispatch(setTeamBalanceReducer(null));
        setIsLoadingBalance(false);
      } else {
        const { balance, decimal } = response.data;
        if (!Number.isNaN(balance) && !Number.isNaN(decimal)) {
          const floatBalance = balance / 10 ** decimal
          setTeamBalance(floatBalance);
          dispatch(setTeamBalanceReducer(floatBalance));
          dispatch(setDecimalsReducer(decimal));
        }
        setIsLoadingBalance(false);
      }
    }
    setIsLoadingBalance(false);
  };

  useEffect(() => {
    getBalance();
  }, [dispatch, user.team, selectedAddress, selectedBlockchain]);

  const getUserInputData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        head: true,
      }]);
    }

    const result = await dispatch(
      coinApi.endpoints.searchERC20Token.initiate({ query: val }),
    );

    if (result.status === 'fulfilled') {
      const filteredData = result.data.filter((item) => !excludedBlockchainsForFlows.includes(item.blockchain));
      return filteredData.map((item) => ({
        ...item,
        value: item.contract_address,
        label: item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const loadUserInputData = useCallback(debounce((val) => getUserInputData(val), 1000), [getUserInputData]);
  const getMcapLabel = (val) => <McapLabel val={val} type="token" />;
  const getChainLabel = useCallback((val) => <IconNearby val={val} />, []);

  return (
    <div className={styles.wrapper}>
      {
        showProvideTokenModal && window.ethereum
        && (
          <ProvideTokensModal
            selectedItem={selectedItem}
            setShowProvideTokenModel={setShowProvideTokenModel}
            setTeamBalanceOnSettings={setTeamBalance}
            openWeb3Modal={openWeb3Modal}
            teamBalance={teamBalance}
            getTeamBalance={getBalance}
          />
        )
      }

      <AsyncSelect
        className={styles.chain_select}
        styles={onChainInputStyles}
        maxMenuHeight={300}
        isOptionSelected={false}
        loadOptions={loadUserInputData}
        components={
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            Control: (props) => <ControlComponent props={props} onChain />,
          }
        }
        selectProps={selectedItem}
        getOptionLabel={(val) => getMcapLabel(val)}
        onChange={(e) => {
          if (e) {
            if (!e.blockchain) {
              setSelectedAddress(e.value);
              setSelectedBlockchain(null);
              setTokenAmount('');
              setSelectedItem({
                ...e,
                label: `${e.label.slice(0, 21)}...${e.label.slice(-5)}`,
              });
            } else {
              setSelectedItem(e);
              setSelectedBlockchain(blockChainOptionsWithTestnets.find(
                (blockchain) => blockchain.value === e.blockchain)?.network);
              setSelectedAddress(e.value);
            }
            dispatch(setERC20Address(e.platforms?.ethereum || e.value));
          } else {
            setSelectedItem(null);
            setSelectedAddress(null);
            setSelectedBlockchain(null);
            setTokenAmount('');
          }
        }}
        isLoading={teamDataIsLoading || teamDataIsFetching || searchCoinIsLoading || searchCoinIsFetching}
        placeholder="Token name or contract address"
        value={selectedItem}
        defaultOptions={coinOptions}
        isDisabled={isDisabled}
        isClearable
      />
      {!selectedItem?.image_url && selectedItem?.value && (
        <div>
          <div className={`mb-1 ${styles.block_title}`}>Select blockchain</div>
          <div>
            <Select
              className="w-100"
              styles={sourceInputStyles}
              value={blockChainOptionsWithTestnets.find((elem) => elem.network === selectedBlockchain)}
              onChange={(val) => {
                setSelectedBlockchain(val.network);
              }}
              getOptionLabel={(val) => getChainLabel(val)}
              options={blockChainOptionsWithTestnets}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      )}

      {selectedBlockchain && selectedAddress && (
        <div>
          <div className={styles.balance_title}>
            <div className={styles.block_title}>
              Balance available for airdrop:
            </div>
            <div className={styles.balance_count}>
              {teamBalance}
              {' '}
              {!selectedItem?.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''}
            </div>
          </div>
          <button
            type="button"
            className={`${styles.wallet_btn}
                outline-blue-button`}
            onClick={() => {
              if (!user.air_drop) {
                setShowAirDropWarning(true);
              } else {
                setShowProvideTokenModel(true);
              }
            }}
            disabled={isDisabled}
          >
            {`Provide ${!selectedItem?.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''} tokens`}
          </button>
          {showAirDropWarning && (
            <div className={`d-flex w-100 gap-2 mt-2 ${styles.wrap_warning}`}>
              <img src={warning} alt="warning" width="24px" height="24px" className="mt-1 align-self-start" />
              <div className="d-flex text-start w-75">
                Your account is not allowed to launch a Flow performing airdrops.
                Please communicate with your CSM for details.
              </div>
            </div>
          )}
        </div>
      )}

      {selectedBlockchain && selectedAddress && (
        <div>
          <div className={`${styles.block_title} mb-1`}>
            Number of tokens to send to each wallets entering
          </div>
          <div className="d-flex align-items-center gap-3">
            <input
              type="number"
              step=".00000001"
              className={styles.input_number}
              value={tokenAmount}
              onChange={(e) => {
                setTokenAmount(e.target.value);
              }}
              min="0"
              onWheel={(e) => e.target.blur()}
              disabled={isDisabled}
            />
            <div className={styles.price}>
              {selectedItem?.current_price
                && `$${ethFormat(selectedItem.current_price * tokenAmount)}`}
            </div>
          </div>
          {!isLoadingBalance && tokenAmount && +tokenAmount > teamBalance
        ? (
          <div className={`${styles.warning} d-flex gap-2 align-items-center`}>
            <Warning />
            Balance is too low for this amount
          </div>
        )
        : null}
        </div>
      )}
    </div>
  );
};

export default TokensAirdrop;
