import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCampaign } from '../../../store/reducers/campaign';
import { webCampaignApi } from '../../../api/webCampaign';
import AiInsight from '../../../assets/icons/insights/Ai_Insight_with_Back.svg?url';
import { isCampaignValid, parseCampaignToBE } from '../helper';
import SummaryModal from './SummaryModal';
import Markdown from '../../.././components/base/Markdown';
import styles from './AISummary.module.scss';

const AISummary = () => {
  const campaign = useSelector(getCampaign);
  const [summary, setSummary] = useState('');
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (campaign?.website
      && campaign?.form) {
      const payload = parseCampaignToBE(campaign);
      getCampaignAiSummary(payload);
      setIsValid(isCampaignValid(campaign));
    } else {
      setSummary('');
      setIsValid(false);
    }
  }, [campaign])

  const [getCampaignAiSummary, getCampaignAiSummaryResult] = webCampaignApi.useGetCampaignAiSummaryMutation();
  const isLoading = getCampaignAiSummaryResult?.status === 'pending';

  useEffect(() => {
    if (getCampaignAiSummaryResult.status !== 'fulfilled') {
      setSummary('');
    } else {
      setSummary(getCampaignAiSummaryResult.data);
    }
  }, [getCampaignAiSummaryResult])

  const onReadMoreClick = () => {
    setShowSummaryModal(true);
  }

  const closeSummaryModal = () => {
    setShowSummaryModal(false);
  }

  if (!campaign?.website || !campaign?.form) return null;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <img src={AiInsight} alt="Ai Insight" className={styles.icon} />
            {!isValid || isLoading || !summary ? (
              <span className={styles.blinkingDot} />
            ) : (
              <div className={styles.md_wrapper}>
                <Markdown content={summary} className={styles.markdown} />
                <span className={styles.link} onClick={onReadMoreClick}>Read more</span>
              </div>
            )}
        </div>
      </div>
      {showSummaryModal && <SummaryModal summary={summary} closeModal={closeSummaryModal} />}
    </>
  )
}

export default AISummary
