/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { ethers } from 'ethers';
import {
  useAccount,
  useProvider,
  useNetwork,
  useSigner,
  useSwitchNetwork,
} from 'wagmi';
import {
  mainnet,
  polygon,
  sepolia,
} from 'wagmi/chains';
import {
  amoy, base, baseSepolia, linea, lineaSepolia, chiliz, chilizSpicy,
} from '../../../../../../utils/web3/customChains.ts';
import { erc721Abi } from '../../../../../../utils/web3/erc721abi';
import styles from './NFTAirdrop.module.scss';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import RoundSpinner from '../../../../../../components/base/RoundSpinner';
import { truncateAddress } from '../../../../../../utils/web3/truncateAddress';
import {
  clearPendingTxn,
  fetchPendingTxns,
  getPendingTransactions,
  isPendingTxn,
} from '../../../../../../store/reducers/web3';
import { getGasPrice } from '../../../../../../utils/web3/getGasPrice';
import { metamaskErrorWrap } from '../../../../../../utils/web3/metamaskErrorWrap';
import { loadNFTAirdropContract } from '../../../../../../utils/web3/loadContract';
import { coinApi } from '../../../../../../api/coin';
import { userApi } from '../../../../../../api/user';
import { blockchainApi } from '../../../../../../api/blockchain';
import McapLabel from '../../../Components/McapLabel';
import { debounce } from '../../../../../../utils/debounce';
import { isValidContractAddress } from '../../../../../../tools/EtherTool';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import { getNftData } from '../../../../../../utils/segments/getCoinNftData';
import { getUser } from '../../../../../../store/reducers/user';
import { useDebounce } from '../../../../../../components/hooks/app';
import { selectFlowStatus } from '../../../../../../store/reducers/flows';
import Warning from '../../../../../../assets/icons/warning_rounded.svg';
import NFTCollectionCreation from '../../../../../NFTCreation/NFTCollectionCreation';
import { ControlComponent, onChainInputStyles, sourceInputStyles } from '../SelectComponentStyling';
import { dividerCommaFormat } from '../../../../../../tools/NumberConverterTool';
import { blockChainOptionsWithTestnets } from '../../../../../../components/base/ChainLogo/chains';
import { excludedBlockchainsForFlows } from '../../../../../../utils/supportedBlockchains';
import { useWeb3Modal } from '@web3modal/react';
import { submitNftAirdrop } from '../../../../../../utils/airdrop/submitUtils.js';

const approveForAllText = 'Approve for all tokens';
const approveForAllType = 'approve_for_all_tokens';

const NFTAirdrop = ({
  airdropSavedData,
  isDisabled,
  onSettingsChange,
  nodeID,
}) => {
  const dispatch = useDispatch();
  const savedNFTAirdropData = airdropSavedData?.asset_type === "nft" ? airdropSavedData : null;
  const { open, setDefaultChain } = useWeb3Modal();

  const [selectedItem, setSelectedItem] = useState(
    savedNFTAirdropData ? {
      value: savedNFTAirdropData.assetName || savedNFTAirdropData.nft_address,
      label: savedNFTAirdropData.asset_name
        || `${savedNFTAirdropData.nft_address?.slice(0, 5)}...${savedNFTAirdropData.nft_address?.slice(-5)}`,
      image_url: savedNFTAirdropData.assetImg,
      id: savedNFTAirdropData.asset_id,
      blockchain: savedNFTAirdropData.nft_blockchain,
      testnet: savedNFTAirdropData.testnet || false,
      created_assets: savedNFTAirdropData.created_assets || false,
      verified: savedNFTAirdropData.verified || false,
    } : null);
  const [selectedBlockchain, setSelectedBlockchain] = useState(
    blockChainOptionsWithTestnets.find(
      (blockchain) => blockchain.value === savedNFTAirdropData?.nft_blockchain,
    )?.network
  );
  const [amount, setAmount] = useState(savedNFTAirdropData?.nft_amount);
  const [sourceOfNfts, setSourceOfNfts] = useState(savedNFTAirdropData?.source_of_nfts)

  const { address } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useSigner({ chainId: chain?.id });
  const { switchNetwork } = useSwitchNetwork();
  const [debouncedText] = useDebounce('');
  const pendingTxns = useSelector(getPendingTransactions);
  const [selectedAddress, setSelectedAddress] = useState(savedNFTAirdropData?.nft_address);
  const [nftContract, setNftContract] = useState();
  const [airdropContract, setAirdropContract] = useState();
  const [userBalance, setUserBalance] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [nftOptions, setNftOptions] = useState([]);
  const [createdAssets, setCreatedAssets] = useState();
  const [isERC1155, setIsERC1155] = useState();
  const provider = useProvider();
  const user = useSelector(getUser);
  const status = useSelector(selectFlowStatus);
  const blockchainValue = blockChainOptionsWithTestnets
    .find((elem) => elem.network === selectedBlockchain)?.value;

  const openWeb3Modal = useCallback(async () => {
    switch (selectedBlockchain) {
      case 'homestead': setDefaultChain(mainnet); break;
      case 'sepolia': setDefaultChain(sepolia); break;
      case 'matic': setDefaultChain(polygon); break;
      case 'amoy': setDefaultChain(amoy); break;
      case 'base': setDefaultChain(base); break;
      case 'base-sepolia': setDefaultChain(baseSepolia); break;
      case 'linea': setDefaultChain(linea); break;
      case 'linea-sepolia': setDefaultChain(lineaSepolia); break;
      case 'chiliz': setDefaultChain(chiliz); break;
      case 'chiliz-spicy': setDefaultChain(chilizSpicy); break;
    }
    await open();
  }, [selectedBlockchain, open, setDefaultChain]);

  const {
    data: nfts,
    isLoading: isNftLoading,
    isFetching: isNftFetching,
  } = coinApi.useSearchNftTestnetsQuery(debouncedText, {
    skip: !debouncedText.length,
  });

  const {
    data: top5nfts,
    isLoading: isTop5NFTsLoading,
    isFetching: isTop5NFTsFetching,
  } = coinApi.useSearchNftQuery('', {
    skip: isDisabled,
  });

  const {
    data: teamAssets,
    isLoading: teamAssetsIsLoading,
    isFetching: teamAssetsIsFetching,
  } = userApi.useGetAssetsSearchQuery();

  const {
    data: nftsTestnets,
    isLoading: isNftTestnetsLoading,
    isFetching: isNftTestnetsFetching,
  } = coinApi.useSearchNftTestnetsQuery('', {
    skip: isDisabled,
  });

  const {
    data: isERC1155Response,
    isSuccess: isSuccessERC1155,
  } = blockchainApi.useCheckIsERC1155Query(
    {
      blockchain: blockchainValue,
      contractAddress: selectedAddress,
    },
    { skip: !blockchainValue || !selectedAddress },
  );

  const {
    data: userBalanceResponse,
    isLoading: isLoadingUserBalance,
    isSuccess: isSuccessUserBalance,
  } = blockchainApi.useGetNFTBalanceQuery(
    {
      blockchain: blockchainValue,
      walletAddress: address,
      contractAddress: selectedAddress,
    },
    {
      skip:
            !blockchainValue || !address || !selectedAddress || !isSuccessERC1155,
    },
  );

  useEffect(() => {
    if (isSuccessUserBalance
      && isSuccessERC1155 
      && isERC1155 !== undefined
      && selectedItem
      && amount
      && +amount > 0
      && +amount <= userBalance) {
        let newObj = {};
        newObj = submitNftAirdrop(
          { 
            asset_name: selectedItem.label,
            created_assets: !!selectedItem.created_assets,
            is_erc1155: isERC1155,
            nft_address: selectedAddress,
            nft_amount: amount,
            nft_blockchain: selectedItem.blockchain,
            source_of_nfts: sourceOfNfts,
            testnet: !!selectedItem.testnet,
            user_address: address,
            verified: selectedItem.verified,
            assetType: 'nft',
          },
          selectedItem,
        );
        onSettingsChange(newObj);
      } else {
        onSettingsChange(null);
      }
  }, [
    isSuccessUserBalance,
    isSuccessERC1155,
    isERC1155,
    selectedItem,
    amount,
    userBalance,
    sourceOfNfts,
    address,
  ])

  const fetchNftOptions = useCallback(() => {
    if (!isTop5NFTsFetching && !isNftFetching && !isNftTestnetsFetching) {
      const formattedNftData = getNftData(
        teamAssets !== undefined && teamAssets.length
          ? teamAssets.filter((x) => x.asset_type === 'nft' && !excludedBlockchainsForFlows.includes(x.network))
          : top5nfts !== undefined ? top5nfts.slice(0, 5) : [],
        nfts !== undefined ? nfts : [],
        createdAssets !== undefined ? createdAssets : [],
      );
      setNftOptions(formattedNftData[0] ? formattedNftData[0] : formattedNftData[1]);
    }
  }, [teamAssets, nfts, top5nfts, nftsTestnets, isTop5NFTsFetching, isNftFetching, isNftTestnetsFetching,
    createdAssets]);

  const getApprovalStatus = useCallback(async (_NftContract, _airdropContract) => {
    if (_NftContract && address && _airdropContract) {
      const approved = await _NftContract.isApprovedForAll(address, _airdropContract.address);
      setIsApproved(approved);
    }
  }, [address]);

  const getAirdropContract = useCallback(async (_NftContract) => {
    if (selectedAddress && selectedBlockchain && address) {
      try {
        const _airdropContract = loadNFTAirdropContract(signer || provider, isERC1155, chain?.id);
        setAirdropContract(_airdropContract);
        if (_airdropContract) {
          if (_airdropContract.address) {
            // Check user approval status for the Airdrop contract
            getApprovalStatus(_NftContract, _airdropContract);
          }
        }
      } catch (err) {
        showErrorMessage('We couldn\'t load the airdrop contract. This blockchain might not be supported.');
      }
    }
  }, [chain?.id, address, signer, provider, selectedAddress, getApprovalStatus, selectedBlockchain, isERC1155]);

  const approveForAll = async (approve) => {
    let approveTx;
    try {
      const gasPrice = await getGasPrice(provider);

      approveTx = await nftContract.setApprovalForAll(airdropContract.address, approve, { gasPrice });

      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, approveForAllText, type: approveForAllType }));
      await approveTx.wait();
    } catch (err) {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
      setIsApproved(approve);
    }
  };

  useEffect(() => {
    if (isERC1155Response) {
      setIsERC1155(isERC1155Response.is_erc1155);
    }
  }, [isERC1155Response]);

  useEffect(() => {
    if (nftsTestnets) {
      setCreatedAssets(nftsTestnets?.filter((x) => x.created_assets));
    }
  }, [nftsTestnets]);

  useEffect(() => {
    // Check if the user is connected and to the right network
    if (selectedBlockchain && chain) {
      const selectedChainId = blockChainOptionsWithTestnets.find(
        (elem) => elem.network === selectedBlockchain,
      )?.networkId;
      if (selectedChainId !== chain.id) {
        switchNetwork(selectedChainId);
      }
    }
  }, [selectedBlockchain, chain, switchNetwork]);

  useEffect(() => {
    if (isSuccessUserBalance && userBalanceResponse !== null) {
      setUserBalance(userBalanceResponse.balance);
    }
  }, [userBalanceResponse, isSuccessUserBalance]);

  useEffect(() => {
    const nftAddress = selectedAddress;
    fetchNftOptions();
    // Set source of NFT when it is not created assets but the response from BE shows it is created assets
    if (createdAssets && nftAddress && sourceOfNfts !== 'created_assets') {
      const ifCreatedItem = createdAssets?.find((obj) => obj.contract_address === nftAddress);
      if (ifCreatedItem) {
        setSourceOfNfts('created_assets');
      } else {
        setSourceOfNfts('users_wallet');
      }
    }
  }, [fetchNftOptions, isERC1155, createdAssets, selectedAddress]);

  useEffect(() => {
    // Set a default source of NFTs
    getApprovalStatus(airdropContract);
  }, [getApprovalStatus, airdropContract]);

  useEffect(() => {
    const nftAddress = selectedAddress;
    // Load NFT contract
    if (signer && nftAddress && (selectedBlockchain || selectedItem?.blockchain)) {
      // check if NFT contract is ERC721 or ERC1155
      const contract = new ethers.Contract(nftAddress, erc721Abi, signer);
      setNftContract(contract);
      // Load Airdrop contract
      getAirdropContract(contract);
    }
  }, [signer, selectedAddress, selectedBlockchain]);

  const getUserInputData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        head: true,
      }]);
    }
    const result = await dispatch(
      coinApi.endpoints.searchNftTestnets.initiate(val),
    );
    if (result.status === 'fulfilled') {
      const filteredData = result.data.filter((item) => !excludedBlockchainsForFlows.includes(item.blockchain));
      return filteredData.map((item) => ({
        ...item,
        value: item.opensea_slug_contract_count > 1
          ? item.contract_name || item.contract_address
          : item.contract_address,
        label: item.opensea_slug_contract_count > 1 ? item.contract_name || item.name : item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const loadUserInputData = useCallback(debounce((val) => getUserInputData(val), 1000), [getUserInputData]);

  const getMcapLabel = (val) => (
    <McapLabel val={val} type={val.type === 'nft' ? 'nft' : ''} />
  );
  const getChainLabel = useCallback((val) => <IconNearby val={val} />, []);

  return (
    <div className={styles.wrapper}>
      <AsyncSelect
        className={styles.chain_select}
        styles={onChainInputStyles}
        maxMenuHeight={300}
        isOptionSelected={false}
        loadOptions={loadUserInputData}
        components={
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            Control: (props) => <ControlComponent props={props} onChain />,
          }
        }
        selectProps={selectedItem}
        getOptionLabel={(val) => getMcapLabel(val)}
        onChange={(e) => {
          if (e) {
            if (!e.blockchain) {
              setSelectedAddress(e.value);
              setSelectedBlockchain(null);
              setSelectedItem({
                ...e,
                label: `${e.label.slice(0, 21)}...${e.label.slice(-5)}`,
              });
            } else {
              setSelectedItem(e);
              setSelectedAddress(e.contract_address ? e.contract_address : e.value);
              setSelectedBlockchain(blockChainOptionsWithTestnets
                .find((elem) => elem.value === e.blockchain)?.network);
            }
          } else {
            setSelectedItem(null);
            setSelectedBlockchain(null);
            setSelectedAddress(null);
            setAmount(null);
          }
        }}
        isLoading={isNftLoading || isNftFetching || teamAssetsIsLoading
          || teamAssetsIsFetching || isTop5NFTsLoading || isTop5NFTsFetching
          || isNftTestnetsLoading || isNftTestnetsFetching}
        placeholder="NFT collection name or contract address"
        value={selectedItem}
        defaultOptions={nftOptions}
        isDisabled={isDisabled}
        isClearable
      />
      {user?.nft_creation
          && (status !== 'running' || status !== 'scheduled' || status !== 'stopped')
          && !selectedItem?.value
        ? (
          <NFTCollectionCreation
            id={nodeID}
            flow
          />
        )
        : null}
      {!selectedItem?.image_url && selectedItem?.value && (
        <div>
          <div className={`my-1 ${styles.block_title}`}>Select blockchain</div>
          <Select
            className="w-100"
            styles={sourceInputStyles}
            value={
              blockChainOptionsWithTestnets.find((elem) => elem.value === selectedItem?.blockchain)
            }
            onChange={(val) => {
              setSelectedBlockchain(val.network);
              setSelectedItem({
                ...selectedItem,
                blockchain: val.value,
                testnet: !!val?.testnet,
              });
            }}
            getOptionLabel={(val) => getChainLabel(val)}
            options={blockChainOptionsWithTestnets}
            isDisabled={isDisabled}
          />
        </div>
      )}
      {selectedItem?.blockchain && selectedItem?.value && !isERC1155 && (
        <div>
          <div className={styles.balance_title}>
            <div className={styles.block_title}>
                        Balance available in wallet:
            </div>
            <div className={`${styles.balance_count} ${!address ? styles.not_connected : ''}`}>
              {address ? dividerCommaFormat(userBalance || 0) : 'Not connected'}
            </div>
          </div>
          <div className="d-flex gap-3">
            {
              address ? (
                !isApproved ? (
                  isPendingTxn(pendingTxns, approveForAllType) ? (
                    <div>
                      <RoundSpinner position="position-absolute" theme="light" />
                                Approving...
                    </div>
                  ) : (
                    <div>
                      <button
                        type="button"
                        className={`${styles.wallet_btn} outline-blue-button`}
                        onClick={() => approveForAll(true)}
                        disabled={isDisabled}
                      >
                                  Allow Absolute Labs to use the NFTs
                      </button>
                    </div>
                  )
                ) : (
                  <div>
                    <button
                      type="button"
                      className={`${styles.wallet_btn} outline-blue-button`}
                      onClick={() => openWeb3Modal()}
                      disabled={isDisabled}
                    >
                      {truncateAddress(address)}
                      {' '}
                                linked
                    </button>
                  </div>
                )
              ) : (
                window.ethereum
                  ? (
                    <button
                      type="button"
                      className={`${styles.wallet_btn} outline-blue-button`}
                      onClick={async () => { openWeb3Modal(); }}
                    >
                                  Connect Wallet
                    </button>
                  )
                  : (
                    <div>
                                  It seems like you don&apos;t have any wallet available.
                    </div>
                  )
              )
            }
          </div>
        </div>
      )}
      {selectedItem?.blockchain
        && selectedItem?.value
        && !isERC1155
        && (
          <div className="mb-3">
            <div className={`${styles.block_title} mb-1`}>
              Number of NFTs to send to each wallets entering
            </div>
            <div className="d-flex align-items-center gap-3">
              <input
                type="number"
                className={styles.input_number}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                min="0"
                onWheel={(e) => e.target.blur()}
                disabled={isDisabled}
              />
            </div>
          </div>
        )}
      {isERC1155
        ? (
          <div className={`${styles.warning} d-flex gap-2 align-items-center`}>
            <Warning />
            ERC1155 NFT airdrop is not yet supported
          </div>
        )
        : null}
        {!isLoadingUserBalance && amount && +amount > userBalance
        ? (
          <div className={`${styles.warning} d-flex gap-2 align-items-center`}>
            <Warning />
            Balance is too low for this amount
          </div>
        )
        : null}
    </div>
  );
};

export default NFTAirdrop;
