import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import Trash from '../../../../../assets/icons/trash.svg';
import DropdownInput from '../DropdownInput';
import AddButton from '../../AddButton';
import { getDefaultForCondition } from '../../../options';
import styles from './DropdownInputList.module.scss';

const DropdownInputList = ({ conditionId, label, value, data }) => {
  const dispatch = useDispatch();
  const getDefaultRow = () => {
    const defaultValue = getDefaultForCondition(conditionId, label);
    if (defaultValue && defaultValue.length > 0) {
      return defaultValue[0];
    } else {
      return null;
    }
  };
  
  const handleAddRow = () => {
    dispatch(setSelectedValue({ conditionId, label, value: [...value, getDefaultRow()] }))
  }

  const handleDelete = (id) => {
    dispatch(setSelectedValue({ conditionId, label, value: value.filter((val) => val.id !== id) }))
  }

  return (
    <div className={styles.wrapper}>
      {value.map((row, index) => (
        <div key={row.id} className={styles.row}>
          <DropdownInput 
            id={row.id}
            conditionId={conditionId}
            label={label}
            condition={row.condition} 
            conditionData={data} 
            text={row.text} 
            allValues={value}
          />
          {value.length !== 1 && 
            <button className={styles.trash} type='button' onClick={() => handleDelete(row.id)}><Trash /></button>}
          {index === value.length - 1 && 
            <AddButton onClick={handleAddRow} />}
        </div>
      ))}
    </div>
  )
}

export default DropdownInputList
