
import React from 'react';
import Tooltip from '../../ui/Tooltip';
import uniqueId from '../../../utils/uniqueId';
import InfoIcon from '../../../assets/icons/info_small_raw.svg';
import styles from './Modal.module.scss';

const ModalSection = ({ 
  title, 
  children,
  titleInfo = null, 
  optional = false, 
  half = false,
  row = false,
}) => {
  const tooltipId = uniqueId('section_title');
  
  return (
    <div className={`${styles.content_section} ${half ? styles.content_half : ''} ${row ? styles.content_row : ''}`}>
      <div className={styles.section_title}>
        <span>
          {title}
          {titleInfo ? (
            <>
              <span className={styles.info_icon} data-for={tooltipId} data-tip>
                <InfoIcon 
                  height="15px" 
                  width="15px" 
                  color="#C2CFE0" 
                />
              </span>
              <Tooltip
                id={tooltipId}
                info={titleInfo}
              />
            </>
          ) : null}
        </span>
        {optional && <span className={styles.optional}>Optional</span>}
      </div>
      {children}
    </div>
  )
}

export default ModalSection;