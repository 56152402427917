import React, { useEffect, useState } from 'react';
import CircleChart from '../../CircleChart';
import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import { getGrowth } from '../../../../utils/values';

const PortfolioValue = ({
  activityData, previousHighlight, isLoading, className = '',
}) => {
  const [data, setData] = useState([]);
  const [isSettingData, setIsSettingData] = useState(true);
  const [avgShare, setAvgShare] = useState('');

  useEffect(() => {
    if (activityData && !isLoading) {
      setIsSettingData(true);
      const total = activityData.buckets.reduce((sum, { value }) => sum + value, 0);
      if (total === 0) {
        setData([]);
        setTimeout(() => setIsSettingData(false), 100);
        return;
      }
      const newData = activityData.buckets.map((bucket) => {
        const percent = total ? ((bucket.value * 100) / total).toFixed(2) : 0;
        return {
          label: bucket.label,
          count: bucket.value,
          percent: +percent,
        };
      });

      const { median } = activityData;
      setAvgShare(`$${convertToReadableFormat(median)}`);
      setData(newData);
      setTimeout(() => setIsSettingData(false), 100);
    }
  }, [activityData, isLoading]);

  return (
    <div className={className || ''}>
      <CircleChart
        title="Portfolio Value"
        highlightText="Median Portfolio Value"
        highlightValue={avgShare}
        data={data}
        isLoading={isLoading || isSettingData}
        growth={previousHighlight
          ? getGrowth(
            activityData?.median,
            previousHighlight?.median,
            `$${previousHighlight?.median}`,
          )
          : null}
      />
    </div>
  );
};

export default PortfolioValue;
