import React, { useEffect, useRef } from 'react';
import styles from './SaveSnapshotModal.module.scss';
import { useDebounce } from '../../../hooks/app';
import Modal from '../../../base/Modal';
import ModalFooter from '../../../base/Modal/ModalFooter';
import ModalTitle from '../../../base/Modal/ModalTitle';

const SaveSnapshotModal = ({
  setValue,
  onSubmit,
  onCancel,
  loading,
  value,
}) => {
  const [debouncedText, setDebouncedText] = useDebounce('', 100);
  const inputRef = useRef();

  useEffect(() => {
    setValue(debouncedText);
  }, [debouncedText]);

  return (
    <Modal onCancel={onCancel} loading={loading}>
      <ModalTitle title="Save snapshot" />

      <div className={`${styles.description}`}>
        <span>
          To save a snapshot of this audience, please provide a name for the list to be created
        </span>
      </div>
      <div className={`${styles.description}`}>
        <input
          ref={inputRef}
          onChange={(e) => {
            setDebouncedText(e.target.value.trim());
          }}
          className="shadow-none form-control"
          maxLength={50}
          autoFocus
          placeholder="Add name for List "
        />
      </div>

      <ModalFooter>
        <button
          type="button"
          className="outline-button border border-1"
          onClick={() => onCancel()}
        >
          Cancel
        </button>
        <button
          disabled={!value}
          type="button"
          className="regular-button"
          onClick={() => {
            setValue(inputRef?.current?.value);
            onSubmit();
          }}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SaveSnapshotModal;
