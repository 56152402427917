import React, { useEffect, useState } from 'react';
import AssetImg from '../NotableInvestments/AssetImg';
import styles from './ExchangesTable.module.scss';
import Table from '../../base/Table';
import { counter } from '../../../tools/Counter';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import { getTablePercent } from '../../../utils/percent';
import Header from '../Table/Header';
import { getMaxValueByProperty } from '../../../utils/object';
import { getTextWidth } from '../../../utils/numbers';

const ExchangesTable = ({
  data, totalUsers, isLoading, setDesc, setPage, setLimit, setOrder, limit, desc, orderBy, page,
}) => {
  const [tableData, setTableData] = useState([]);
  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: 'exchange_name',
      title: 'Name',
      width: '250px',
    },
    {
      field: 'total_number_of_users',
      title: 'Total Users',
    },
    {
      field: 'number_of_users_last_7d',
      title: 'Users Last 7d',
    },
    {
      field: 'number_of_users_last_30d',
      title: 'Users Last 30d',
    },
    {
      field: 'number_of_users_last_90d',
      title: 'Users Last 90d',
    },
  ];

  useEffect(() => {
    if (data?.result?.length && !isLoading) {
      const totalUsersWidth = `${getTextWidth(getMaxValueByProperty(data.result, "total_number_of_users"), 14) + 12}px`;
      const users7dWidth = `${getTextWidth(getMaxValueByProperty(data.result, "number_of_users_last_7d"), 14) + 12}px`;
      const users30dWidth = `${getTextWidth(getMaxValueByProperty(data.result, "number_of_users_last_30d"), 14) + 12}px`;
      const users90dWidth = `${getTextWidth(getMaxValueByProperty(data.result, "number_of_users_last_90d"), 14) + 12}px`;

      const temp = data.result.map((elem, index) => ({
        pageNumber: (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        ),
        exchange_name: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <AssetImg logo={elem.image_url} type="exchange" text="exchange" />
              <span className={`${styles.content_name} text-truncate`}>
                {elem.exchange_name}
              </span>
            </div>
          </div>
        ),
        total_number_of_users: (
          <div className="d-flex align-items-baseline">
            <span style={{ minWidth: totalUsersWidth }}>{ethFormat(elem.total_number_of_users, 1)}</span>
            <span className={styles.gray}>{getTablePercent(elem.total_number_of_users, totalUsers)}</span>
          </div>
        ),
        number_of_users_last_7d: (
          <div className="d-flex align-items-baseline">
            <span style={{ minWidth: users7dWidth }}>{ethFormat(elem.number_of_users_last_7d, 1)}</span>
            <span className={styles.gray}>{getTablePercent(elem.number_of_users_last_7d, totalUsers)}</span>
          </div>
        ),
        number_of_users_last_30d: (
          <div className="d-flex align-items-baseline">
            <span style={{ minWidth: users30dWidth }}>{ethFormat(elem.number_of_users_last_30d, 1)}</span>
            <span className={styles.gray}>{getTablePercent(elem.number_of_users_last_30d, totalUsers)}</span>
          </div>
        ),
        number_of_users_last_90d: (
          <div className={`${styles.elements} d-flex align-items-baseline`}>
            <span style={{ minWidth: users90dWidth }}>{ethFormat(elem.number_of_users_last_90d, 1)}</span>
            <span className={styles.gray}>{getTablePercent(elem.number_of_users_last_90d, totalUsers)}</span>
          </div>
        ),
      }));
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data, totalUsers, isLoading]);

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column`}>
      <Header
        title="Exchanges"
        info={counter(data?.count, page, limit)}
      />
      <div className="w-100">
        <Table
          total={data?.count || 0}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={orderBy}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isLoading}
          min={25}
        />
      </div>
    </div>
  );
};

export default ExchangesTable;
