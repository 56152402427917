import React from 'react';
import uniqueId from '../../../../utils/uniqueId';
import Tooltip from '../../Tooltip';
import InfoIcon from '../../../../assets/icons/info_small_raw.svg';
import styles from './Header.module.scss';

const Header = ({ title, tooltip, info, actions }) => {
  const tooltipId = uniqueId("table_header");
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.title_wrapper}>
          <span className={styles.title}>{title}</span>
          {tooltip ? (
            <>
              <span data-for={tooltipId} data-tip>
                <InfoIcon height="20px" width="20px" color="#C2CFE0" />
              </span>
              <Tooltip info={tooltip} id={tooltipId} />
            </>
          ) : null}
        </div>
        {info ? <span className={styles.info}>{info}</span> : null}
      </div>
      {actions ? (
        <div className={styles.right}>
          {actions}
        </div>
      ) : null}
    </div>
  )
}

export default Header
