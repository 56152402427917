import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import { debounce } from '../../../../../utils/debounce';
import { placeholderStyle } from '../../../../AddSegment/Condition/Condition.constants';
import { FormatGroupLabel, MultiValueLabel, MultiValueRemove } from '../../../../AddSegment/components/labels';
import styles from './LabeledAsyncMultiSelect.module.scss';
import { getUrl, parseData, getLabelComponent } from '../../../helper';

const LabeledAsyncMultiSelect = ({ conditionId, label, displayedLabel, value, exclusionValue, type }) => {
  const dispatch = useDispatch();
  const [defaultOptions, setDetaultOptions] = useState([]);
    
  const handleChange = (newValue) => {
    dispatch(setSelectedValue({ conditionId, label, value: newValue }))
  }

  const getData = useCallback(async (val) => {
    const handler = getUrl(type, val);
    const apiData = await dispatch(
      handler.url.initiate(
        typeof handler.value === 'string' ? handler.value : { ...handler.value },
        { forceRefetch: 10 },
      ),
    );
    return parseData(apiData, type);
  }, [dispatch]);

  useEffect(() => {
    getData('').then((result) => {
      setDetaultOptions(result);
    })
  }, [])


  const loadOptions = useCallback(
    (inputValue) => {
      const filteredOptions = (options) => 
        options.filter(option => exclusionValue ? !exclusionValue.some(excluded => excluded.value === option.value) : true);

      return debounce(() => getData(inputValue).then(filteredOptions), 1000)();
    },
    [getData, exclusionValue]
  );
  const getLabel = useCallback((val) => getLabelComponent(type, val), []);

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{displayedLabel}</span>
        <AsyncSelect
          styles={placeholderStyle}
          maxMenuHeight={194}
          components={
            {
            // eslint-disable-next-line react/no-unstable-nested-components
              MultiValueRemove: (props) => <MultiValueRemove props={props} show="label" />,
              // eslint-disable-next-line react/no-unstable-nested-components
              MultiValueLabel: (props) => <MultiValueLabel props={props} show="label" />,
            }
          }
          className="w-100"
          formatGroupLabel={FormatGroupLabel}
          value={value}
          loadOptions={loadOptions}
          getOptionLabel={(val) => getLabel(val)}
          isSearchable
          cacheOptions={false}
          onChange={handleChange}
          defaultOptions={exclusionValue ? defaultOptions.filter(option => !exclusionValue.some(excluded => excluded.value === option.value)) : defaultOptions}
          isShowValue
          isMulti
        />
    </div>
  )
}

export default LabeledAsyncMultiSelect
