import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Profile.module.scss';
import { webSDKApi } from '../../../api/webSDK';
import uniqueId from '../../../utils/uniqueId';
import WalletDetected from '../../../assets/icons/trackedWebsite/walletDetected.svg?url';
import { selectWebSDKFilterParam } from '../../../store/reducers/webSDK';
import WidgetCard from '../../../components/ui/WidgetCard';
import { getCardValue, getFormattedValue } from '../../../utils/values';
import ECommerceContainer from './ECommerceContainer';
import PagesList from './PageList';

const ECommerceTab = ({ id }) => {
  const [widgetData, setWidgetData] = useState([]);
  const [containerData, setContainerData] = useState([]);
  const filterParams = useSelector(selectWebSDKFilterParam);
  const {
    currentData: eCommerceStatsData,
    isLoading: eCommerceStatsIsLoading,
    isFetching: eCommerceStatsIsFetching,
  } = webSDKApi.useGetWebSDKECommerceStatsQuery({
    id,
    ...filterParams,
  }, {
    skip: !filterParams || !filterParams?.day
  });

  useEffect(() => {
    if (eCommerceStatsData) {
      setWidgetData([
        {
          title: 'Crypto Shoppers Conversion Rate',
          value: eCommerceStatsData?.likelihood_of_purchase_bump
            ? getFormattedValue(eCommerceStatsData?.likelihood_of_purchase_bump, { decimals: 2, suffix: 'x'})
            : '-',
          icon: WalletDetected,
          info: 'Increase in conversion rate for visitors having at least 1 crypto wallet extension detected in their browser, compared to non-crypto visitors.',
        },
        {
          title: 'Bounce Rate Improvement',
          value: eCommerceStatsData?.bounce_rate_reduction && eCommerceStatsData?.bounce_rate_reduction !== '-'
            ? getFormattedValue(
              eCommerceStatsData?.bounce_rate_reduction, 
              { 
                decimals: 1, 
                suffix: `% ${eCommerceStatsData?.bounce_rate_reduction > 0 ? 'lower' : 'higher'}` 
              }
            )
            : '-',
          icon: WalletDetected,
          info: 'Change in bounce rate for visitors with a crypto wallet compared to non-crypto visitors. Bounce rate is computed as the percentage of visitors who visit only one page.'
        },
        {
          title: 'Detected Wallets',
          value: getCardValue(eCommerceStatsData?.detected_wallets),
          icon: WalletDetected,
        },
        {
          title: 'Est. Total Wallets',
          value: getCardValue(eCommerceStatsData?.est_total_wallets),
          icon: WalletDetected,
          info: 'Based on the adoption rate over compatible devices detected, applied to all unique visitors of the website.'
        },
      ]);
      setContainerData([
        {
          title: 'Conversion Rate Increase',
          value: eCommerceStatsData?.conversion_likelihood,
          info: 'Increase in conversion rate for various types of visitors, with baseline being non-crypto visitors, at 1x.'
        },
        {
          title: 'Web3 Adoption per Visitor Type',
          value: eCommerceStatsData?.web3_adoption_by_type,
          info: 'Percentage of crypto visitors detected in various groups of visitors based on their activity on the website.'
        },
        {
          title: 'Web3 Adoption by Country',
          value: eCommerceStatsData?.top_countries,
        }]
      )
    }
  }, [eCommerceStatsData]);

  return (
    <div className={styles.wrapper}>
      <div className="d-flex w-100 gap-4 pb-4">
        {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
          <WidgetCard
            key={uniqueId('website-widget')}
            icon={item.icon}
            info={item.info}
            growth={item.growth}
            title={item.title}
            value={item.value}
            isLoading={eCommerceStatsIsLoading || eCommerceStatsIsFetching}
          />
        ))}
      </div>
      <div className="row pb-4">
        {(containerData.length ? containerData : [{}, {}, {}]).map((elem) => (
          <ECommerceContainer
            title={elem.title}
            isLoading={eCommerceStatsIsLoading || eCommerceStatsIsFetching}
            data={elem.value}
            info={elem.info}
          />
        ))}
      </div>
      <div className="row pb-4">
        <PagesList />
      </div>
    </div>
  );
};

export default ECommerceTab;
