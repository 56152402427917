import React from 'react';
import { useDispatch } from 'react-redux';

import {
  setSegmentName,
} from '../../../store/reducers/segment';
import EditableTitle from '../../../components/base/EditableTitle';

const TitleControls = ({ inputValue, setInputValue, isInRunning }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="d-flex gap-2 align-items-center">
        <EditableTitle
          defaultTitle="Add the segment name"
          titleValue={inputValue}
          setTitleValue={setInputValue}
          onSave={() => dispatch(setSegmentName(inputValue))}
          edit={!isInRunning}
          triggerNotification={false}
        />
      </div>
    </div>
  );
};

export default TitleControls;
