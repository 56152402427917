import React from 'react';
import ConditionBlock from '../Components/ConditionBlock';
import styles from './Conditions.module.scss';
import ConditionSeparator from '../Components/ConditionSeparator';
import WalletDetected from './WalletDetected';

const Conditions = ({ conditions }) => {
  const conditionsMap = {
    wallet: <WalletDetected />,
  };

  return (
    <div className={styles.wrapper}>
      {conditions.map((condition, index) => (
        <React.Fragment key={condition}>
          {conditionsMap[condition] || <ConditionBlock id={condition} />}
          {index < conditions.length - 1 && <ConditionSeparator />}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Conditions
