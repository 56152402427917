/* eslint-disable camelcase */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import { coinApi } from '../../../../../api/coin';
import { isValidContractAddress } from '../../../../../tools/EtherTool';
import { preventSearch } from '../../../../../utils/search/preventSearch';
import EditableTitle from '../../../../../components/base/EditableTitle';
import styles from './TransactionNodeSettings.module.scss';
import { showErrorMessage } from '../../../../../components/base/Notifications';
import AssetSelect from './AssetSelect';
import Info from '../../../../../assets/icons/flows/info.svg';
import Plus from '../../../../../assets/icons/flows/plus-circle.svg';
import PlusDisabled from '../../../../../assets/icons/flows/plus-circle-disabled.svg';
import Checkbox from '../../../../../components/ui/Checkbox';
import { blockChainOptions } from '../../../../../components/base/ChainLogo/chains';
import { excludedBlockchainsForFlows } from '../../../../../utils/supportedBlockchains';

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    paddingLeft: 1,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
    fontSize: '14px',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const typeOptions = [
  { label: 'ERC-721', value: 'erc721' },
  { label: 'ERC-1155', value: 'erc1155' },
];

const eventOptions = [
  { label: 'Send', value: 'Send' },
  { label: 'Receive', value: 'Receive' },
  { label: 'Mint', value: 'Mint' },
  { label: 'Other', value: 'Other', isDisabled: true },
];

const TransactionNodeSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();

  const [titleValue, setTitleValue] = useState('');
  const [assetType, setAssetType] = useState('');
  const [event, setEvent] = useState('');
  const [selectedType, setSelectedType] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {
      include_mints: true,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'assets',
  });

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const { include_mints, ...submitDataToSet } = submitData;
    const newObj = { ...submitDataToSet };
    if (titleValue) {
      newObj.name = titleValue;
    } else {
      newObj.name = 'New transaction source';
    }
    if (selectedType) {
      newObj.contract_type = selectedType.value || selectedType;
    } else {
      delete newObj.contract_type;
    }
    if (submitData.event === 'Receive') {
      newObj.include_mints = include_mints;
    }
    if (assetType) {
      newObj.asset_type = assetType;
    }
    if (watch('min_amount') && watch('max_amount') && Number(watch('max_amount')) < Number(watch('min_amount'))) {
      showErrorMessage('Maximum amount cannot be lower than the minimum.');
      return;
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...newObj, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  const {
    data: top5tokens,
    isLoading: isTop5TokensLoading,
    isFetching: isTop5TokensFetching,
  } = coinApi.useSearchERC20TokenQuery({ query: '', limit: 5 }, {
    skip: disableChangeStatus,
  });

  const {
    data: top5nfts,
    isLoading: isTop5NFTsLoading,
    isFetching: isTop5NFTsFetching,
  } = coinApi.useSearchNftTransactionOnlyQuery('', {
    skip: disableChangeStatus,
  });

  const getTokensData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        description: 'Using token at address',
      }]);
    }
    const data = await dispatch(
      coinApi.endpoints.searchERC20Token.initiate({ query: val, transaction_blockchain_only: true }),
    );

    if (data.status === 'fulfilled') {
      const filteredData = data.data.filter((item) => !excludedBlockchainsForFlows.includes(item.blockchain));
      return filteredData.map((item) => ({
        ...item,
        value: item.contract_address,
        label: item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const getNFTsData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        description: 'Using token at address',
      }]);
    }

    const data = await dispatch(
      coinApi.endpoints.searchNftTransactionOnly.initiate(val),
    );

    if (data.status === 'fulfilled') {
      const filteredData = data.data.filter((item) => item.blockchain !== 'near');
      return filteredData.map((item) => ({
        ...item,
        value: item.opensea_slug_contract_count > 1
          ? item.contract_name || item.contract_address
          : item.contract_address,
        label: item.opensea_slug_contract_count > 1 ? item.contract_name || item.name : item.name,
      }));
    }
    return null;
  }, [dispatch]);

  useEffect(() => {
    if (assetType && fields.length === 0) {
      append({
        blockchain: '',
        asset: '',
        min_amount: null,
        max_amount: null,
        contract_address: '',
      });
    }
  }, [append, assetType, fields.length]);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        const tempObj = {};
        if (configList[index].assets) {
          setValue('assets', configList[index].assets);
          tempObj.name = configList[index].name;
        }
        if (configList[index].contract_type) {
          setSelectedType(
            typeOptions.filter((type) => type.value === configList[index].contract_type)[0],
          );
        }
        if (configList[index].event) {
          setEvent({ label: configList[index].event, value: configList[index].event });
        }
        setAssetType(configList[index].asset_type);
        setTitleValue(configList[index].name);
        setValue('event', configList[index].event);
        setValue('include_mints', configList[index].include_mints);
      }
    }
  }, [activeSettings.node_id, configList, setValue]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="New transaction source"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <div className={`${styles.select} mb-3`}>
            <div className={`${styles.select_title} mb-1`}>Choose the event to track</div>
            <Select
              {...register('event')}
              placeholder="Select"
              styles={inputStyles}
              value={event}
              onKeyDown={(e) => { preventSearch(e); }}
              onChange={(val) => {
                setValue('event', val.value);
                setEvent(val);
              }}
              name="types"
              isSearchable={false}
              options={eventOptions}
              isDisabled={disableChangeStatus}
            />
            {watch('event') === 'Receive' && (
              <div className="mt-2">
                <Checkbox
                  label="Include mints"
                  useFormData={register('include_mints')}
                  disabled={disableChangeStatus}
                />
              </div>
            )}
          </div>
          <div className="d-flex flex-column gap-3 mb-3">
            <div className={styles.type_title}>
              Choose the asset type
            </div>
            <div className="d-flex gap-3">
              <button
                type="button"
                className={assetType === 'Token' ? 'check-button-checked' : 'check-button'}
                onClick={() => {
                  if (assetType !== 'Token') {
                    setAssetType('Token');
                    remove();
                  }
                }}
                disabled={disableChangeStatus}
              >
                Tokens
              </button>
              <div>
                <button
                  type="button"
                  className={assetType === 'NFT' ? 'check-button-checked' : 'check-button'}
                  onClick={() => {
                    if (assetType !== 'NFT') {
                      setAssetType('NFT');
                      remove();
                    }
                  }}
                  disabled={disableChangeStatus}
                >
                  NFTs
                </button>
              </div>
            </div>
          </div>
          <div
            className={`${styles.info} d-flex`}
          >
            <div className="me-3">
              <Info />
            </div>
            Note that every transfer will trigger an entry in the flow.
            If there&apos;s a transfer of multiple selected assets as part of a single transaction,
            the profile will be sent multiple times
          </div>
          {fields.map((elem, index) => (
            <AssetSelect
              assetType={assetType}
              disableChangeStatus={disableChangeStatus}
              isLoading={isTop5TokensLoading || isTop5TokensFetching || isTop5NFTsLoading || isTop5NFTsFetching}
              blockChainOptions={blockChainOptions.filter((elem) => elem.network !== 'linea'
                && elem.network !== 'chiliz')}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              typeOptions={typeOptions}
              top5tokens={top5tokens}
              top5nfts={top5nfts}
              setValue={setValue}
              watch={watch}
              getTokensData={getTokensData}
              getNFTsData={getNFTsData}
              onDelete={() => remove(index)}
              index={index}
              key={elem.id}
            />
          ))}
          {assetType && fields.length <= 9
            ? (
              <div
                role="presentation"
                className={`
                    d-flex align-items-center gap-2 cursor-pointer
                    ${styles.add_field} ${disableChangeStatus ? styles.blocked : ''}
                    `}
                onClick={() => !disableChangeStatus && append({
                  blockchain: '',
                  asset: '',
                  min_amount: null,
                  max_amount: null,
                  contract_address: '',
                })}
              >
                {disableChangeStatus ? <PlusDisabled /> : <Plus />}
                Add&nbsp;
                {assetType === 'Token' ? 'Token' : 'NFT'}
              </div>
            )
            : null}
        </div>
        <div>
          <div className="d-flex gap-4 mt-4">
            <button
              type="button"
              className={`${styles.btn_cancel}`}
              onClick={() => dispatch(clearActiveSettings())}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button w-100"
              disabled={!fields.length || disableChangeStatus}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TransactionNodeSettings;
