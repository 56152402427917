import React from 'react';
import styles from '../SegmentDetails.module.scss';
import SegmentAnalyticsLoader from '../SegmentAnalyticsLoader';

const SegmentLoader = () => {
  return (
    <div className={styles.loader_wrapper}>
      <div className={styles.modal}>
        <div>
          <SegmentAnalyticsLoader />
        </div>
        <span className={styles.text}>
          Please hold while we generate your report…
        </span>
      </div>
    </div>
  )
}

export default SegmentLoader
