import React, { useState } from 'react';
import Modal from '../../../base/Modal';
import ModalTitle from '../../../base/Modal/ModalTitle';
import ModalFooter from '../../../base/Modal/ModalFooter';
import styles from './NameModal.module.scss';


const NameModal = ({ title, description, placeholder, onCancel, onSave, onChange }) => {
  const [value, setValue] = useState('');
  
  const handleChange = (newValue) => {
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }
  
  const handleSave = () => {
    if (onSave) {
      onSave(value);
    }
  }

  return (
    <Modal onCancel={onCancel}>
      <ModalTitle title={title} />
        <span className={styles.description}>{description}</span>
        <input
          type="text"
          className={`form-control ${styles.input}`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        />
        <ModalFooter>
          <button
            type="button"
            className="outline-button border border-1"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="regular-button"
            onClick={handleSave}
            disabled={!value}
          >
            Save
          </button>
        </ModalFooter>
    </Modal>
  )
}

export default NameModal
