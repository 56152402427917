import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formsApi } from '../../../../../api/forms';
import { counter } from '../../../../../tools/Counter';
import Loader from '../../../../../components/base/Loader';
import NoCampaign from './NoCampaign';
import useSetSearchParams from '../../../../../utils/useSetSearchParams';
import PopupMenuButton from '../../../../../components/ui/PopupMenuButton';
import Settings from '../../../../../assets/images/dashboard/settings.png';
import Trash from '../../../../../assets/icons/table/trash.svg';
import Copy from '../../../../../assets/icons/table/copy.svg';
import AllIcon from '../../../../../assets/icons/box.svg';
import RunningIcon from '../../../../../assets/icons/status_running.svg';
import StoppedIcon from '../../../../../assets/icons/status_stopped.svg';
import DraftIcon from '../../../../../assets/icons/status_draft.svg';
import Table from '../../../../../components/base/Table';
import tableStyles from '../../../../../components/base/Table/Table.module.scss';
import styles from './WebCampaigns.module.scss';
import { webCampaignApi } from '../../../../../api/webCampaign';
import { format } from 'date-fns';
import ListFilter from '../../../../../components/ui/ListFilter';
import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter';
import Header from '../../../../../components/ui/Table/Header';
import CreateCampaignButton from '../../../../../components/ui/CreateCampaignButton';

const WebCampaigns = ({ id }) => {
  const campaignListRef = useRef();
  const navigate = useNavigate();
  const setParams = useSetSearchParams();
  const [searchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') || true);
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'start_at');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const [status, setStatus] = useState(capitalizeFirstLetter(searchParams.get('status')) || '');
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [loaderHeight, setLoaderHeight] = useState('auto');

  const [deleteCampaign, deleteCampaignResult] = webCampaignApi.useDeleteCampaignMutation();
  const [stopCampaign, stopCampaignResult] = webCampaignApi.useStopCampaignMutation();
  const [resumeCampaign, resumeCampaignResult] = webCampaignApi.useResumeCampaignMutation();

  const {
    data: campaigns,
    isLoading: isCampaignsLoading,
    isFetching: isCampaignsFetching,
    refetch: refetchCampaigns,
  } = formsApi.useGetFormCampaignsQuery({ 
    id,
    limit,
    order: desc ? 'desc' : 'asc',
    orderBy: order,
    offset: (page - 1) * limit,
    status
  }, { skip: !id });
  
  const isLoading = isCampaignsLoading || isCampaignsFetching;

  const headers = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'website',
      title: 'Website',
    },
    {
      field: 'start_at',
      title: 'Start Date',
    },
    {
      field: 'end_at',
      title: 'End Date',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      title: (
        <div className="d-flex justify-content-center">
          Actions
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    navigate(`/form/campaign/${id}`, { state: { edit: true } });
  }

  const handleDuplicate = (id) => {
    navigate(`/form/campaign/`, { state: { duplicate: true, id } });
  }

  const handleDelete = (id) => {
    deleteCampaign({ id });
  }

  const handlePause = (id, status) => {
    if (status === 'Active') {
      stopCampaign({ id });
    } else {
      resumeCampaign({ id });
    }
  }

  useEffect(() => {
    if (campaigns) {
      setTableData(campaigns.results.map((campaign) => ({
        name: campaign.name,
        website: campaign.website.name,
        start_at: campaign.start_at ? format(new Date(campaign.start_at), 'eeee, MMM dd, yyyy h:mm a') : '-',
        end_at: campaign.end_at ? format(new Date(campaign.end_at), 'eeee, MMM dd, yyyy h:mm a') : '-',
        status: campaign.status,
        button: (
          <div className={`ms-auto ${tableStyles.popover_wrapper} text-center position-relative cursor-pointer`}>
            <img
              src={Settings}
              className="cursor-pointer"
              height="25"
              width="25"
              alt="settings"
            />
            <div className={`${tableStyles.invisible_dropdown} position-absolute top-0 right-0`}>
              <div className={`${tableStyles.popover_content} position-absolute`}>
                <PopupMenuButton onClick={() => handleEdit(campaign.id)}>
                  Edit
                </PopupMenuButton>

                <PopupMenuButton onClick={() => handlePause(campaign.id, campaign.status)}>
                  {campaign.status === 'Active' ? 'Stop' : 'Resume'}
                </PopupMenuButton>

                <PopupMenuButton onClick={() => handleDuplicate(campaign.id)}>
                  <Copy />
                  Duplicate
                </PopupMenuButton>

                <PopupMenuButton onClick={() => handleDelete(campaign.id)}>
                  <Trash />
                  Delete
                </PopupMenuButton>

              </div>
            </div>
          </div>
        )
      })));
    } else {
      setTableData([]);
    }
  }, [campaigns]);

  useEffect(() => {
    setIsTableLoading(!isTableLoading && deleteCampaignResult.status === 'pending');

    if (deleteCampaignResult.status === 'fulfilled') {
      refetchCampaigns();
    }
  }, [deleteCampaignResult])

  useEffect(() => {
    setIsTableLoading(!isTableLoading && stopCampaignResult.status === 'pending');

    if (stopCampaignResult.status === 'fulfilled') {
      refetchCampaigns();
    }
  }, [stopCampaignResult])

  useEffect(() => {
    setIsTableLoading(!isTableLoading && resumeCampaignResult.status === 'pending');

    if (resumeCampaignResult.status === 'fulfilled') {
      refetchCampaigns();
    }
  }, [resumeCampaignResult])

  const setFilter = (id) => {
    if (campaignListRef.current) {
      setLoaderHeight(`${campaignListRef.current.getBoundingClientRect().height}px`);
    } else {
      setLoaderHeight('auto');
    }

    switch (id) {
      case 'Active':
      case 'Draft':
      case 'Stopped':
        setParams({ status: id, page: 1 });
        setStatus(id);
        break;
      case 'All':
      default:
        setParams({ status: '', page: 1 });
        setStatus('');
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header
        title="Web Campaigns"
        info={counter(campaigns?.count, page, limit)}
        actions={<CreateCampaignButton state={{ form: id }} />}
      />
      <div className={styles.filters}>
        <ListFilter
          filters={[
            {
              id: 'All',
              label: 'All',
              icon: <AllIcon color="#323C47" />,
              selected: status === '',
            },
            {
              id: 'Active',
              label: 'Active',
              icon: <RunningIcon color="#323C47" />,
              selected: status === 'Active',
            },
            {
              id: 'Draft',
              label: 'Draft',
              icon: <DraftIcon color="#323C47" />,
              selected: status === 'Draft',
            },
            {
              id: 'Stopped',
              label: 'Stopped',
              icon: <StoppedIcon color="#323C47" />,
              selected: status === 'Stopped',
            },
          ]}
          setFilter={setFilter}
        />
      </div>

      {isLoading ? (
        <div className={styles.loader_wrapper} style={{ height: loaderHeight }}>
          <Loader />
        </div>
      ) : (
        campaigns?.results?.length > 0 || status !== '' ? (
          <div ref={campaignListRef}>
            <div className={`${isTableLoading ? styles.loading_table : ''}`}>
              <Table
                total={0}
                data={tableData}
                desc={desc}
                setDesc={setDesc}
                ordering={order}
                setOrdering={setOrder}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={headers}
                isLoading={isTableLoading}
                min={25}
              />
            </div>
          </div>
        ) : (
          <NoCampaign formId={id} />
        )
      )}
    </div>
  )
}

export default WebCampaigns
