import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/coins';

export const coinApi = createApi({
  reducerPath: 'coinAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Coin'],
  endpoints: (build) => ({
    /* token */
    getCoinById: build.query({
      query: (id) => ({
        url: `${BASE_API}/ft/${id}/detail/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    getCoinFromMarketData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/market-data/?days=${arg.days}&interval=${
          arg.days !== '1' ? 'daily' : 'hourly'
        }&vs_currency=usd`,
        method: 'GET',
      }),
    }),
    getCoinHolders: build.query({
      query: (id) => ({
        url: `${BASE_API}/ft/${id}/holders/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    getCoinWallets: build.query({
      query: (id) => ({
        url: `${BASE_API}/ft/${id}/wallets/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    searchCoin: build.query({
      query: (coinName) => ({
        url: `${BASE_API}/ft/search/?query=${coinName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    searchERC20Token: build.query({
      query: (body) => ({
        url: `${BASE_API}/ft/erc20/search/?query=${body.query}${body.limit
          ? `&limit=${body.limit}` : ''}${body.blockchain 
          ? `&blockchain=${body.blockchain}` : ''}${body.transaction_blockchain_only
          ? `&transaction_blockchain_only=${body.transaction_blockchain_only}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getTokenExchangeData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.address}/cex/usage/?order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getTokenAudienceProfile: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/audience-profile/${arg.audience
          ? `?audience=${arg.audience}` : ''}`,
      }),
    }),
    getTokenNotableInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/notable-investments/${
          arg.limit ? `?limit=${arg.limit}` : ''
        }${arg.chain ? `?platform=${arg.chain}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getTokenBuyingPower: build.query({
      query: (id) => ({
        url: `${BASE_API}/ft/${id}/buying-power/`,
        method: 'GET',
      }),
    }),
    getTokenTopHolders: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/top-holders/${arg.audience
          ? `?audience=${arg.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getTokenHistoricalHolders: build.query({
      query: (data) => ({
        url: `${BASE_API}/ft/${data.id}/historical-holders/${
          data.period ? `?period=${data.period}` : ''}${data.audience
          ? `&audience=${data.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getGroupInterest: build.query({
      query: (args) => ({
        url: `${BASE_API}/ft/${args.address}/group-interest/${args.audience
          ? `?audience=${args.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getTokenInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/investor-portfolio/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.chain
          ? `&platform=${arg.chain}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getSharePortfolio: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/share-portfolio/${arg.audience
          ? `?audience=${arg.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getTokenHoldersList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/table-token-holders/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getTokenHolderListCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/token-holders-csv/?limit=${arg.limit || 50000}${arg.sort
          ? `&sort=${arg.sort}`
          : ''}${arg.sortBy
          ? `&sort_by=${arg.sortBy}`
          : ''}&wait=${arg.wait}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    reachToken: build.query({
      query: (name) => ({
        url: `${BASE_API}/ft/${name}/token-reach/`,
        method: 'GET',
      }),
    }),
    getTokenDappUsageData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/dapps/usage/?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getTokenDeployer: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.tokenId}/deployer/`,
      }),
    }),
    postSnapshotOfCoinHolderList: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/save-as-custom-list/`,
        method: 'POST',
        body: {
          name: arg.name,
          audience: arg.audience,
        },
      }),
    }),
    sendTokenAiOverviewByEmail: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/ai-overview/send-by-email/`,
        method: 'POST',
      }),
    }),
    getTokenLookup: build.query({
      query: (coinId) => ({
        url: `${BASE_API}/ft/${coinId}/token-lookup/`,
      }),
    }),
    getTokenDevActivity: build.query({
      query: (arg) => ({
        url: `${BASE_API}/ft/${arg.id}/dev-activity/?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),

    /* NFT */
    getNFTExchangeData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/cex/usage/?order=${arg.order}&blockchain=${arg.chain}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getNFTAudienceProfile: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/audience-profile/?blockchain=${arg.chain}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    searchNft: build.query({
      query: (nftName) => ({
        url: `${BASE_API}/nft/search/?query=${nftName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    searchNftTestnets: build.query({
      query: (nftName) => ({
        url: `${BASE_API}/nft/search/?query=${nftName}&include_testnets=true`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    searchNftTransactionOnly: build.query({
      query: (nftName) => ({
        url: `${BASE_API}/nft/search/?query=${nftName}&transaction_blockchain_only=true`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getNftBasicInfo: build.query({
      query: ({ address, network }) => ({
        url: `${BASE_API}/nft/${address}/detail/?blockchain=${network}`,
        method: 'GET',
      }),
    }),
    getNftOwnerInfo: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/owners/?blockchain=${
          data.chain}&limit=${data.limit}&offset=${data.offset}`,
        method: 'GET',
      }),
    }),
    getNftTopHolders: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/top-holders/?blockchain=${
          data.chain}${data.audience ? `&audience=${data.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getNftHistoricalHolders: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/historical-holders/?blockchain=${
          data.chain}${data.period ? `&period=${data.period}` : ''}${data.audience
          ? `&audience=${data.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getNftStatsTrading: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/trading-stats/?blockchain=${
          arg.network}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1800,
    }),
    getNftNotableInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/notable-investments/${
          arg.limit ? `?limit=${arg.limit}` : ''}${arg.network
          ? `?platform=${arg.network}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getNftBuyingPower: build.query({
      query: (args) => ({
        url: `${BASE_API}/nft/${args.address}/buying-power/?blockchain=${args.blockchain || 'ethereum'}`,
        method: 'GET',
      }),
    }),
    getNftGroupInterest: build.query({
      query: (args) => ({
        url: `${BASE_API}/nft/${args.address}/group-interest/?blockchain=${args.blockchain || 'ethereum'}${args.audience
          ? `&audience=${args.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getNftInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/investor-portfolio/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.chain
          ? `&blockchain=${arg.chain}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getNftSharePortfolio: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/share-portfolio/?blockchain=${arg.network}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    getNftTopTraders: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/top-traders/?blockchain=${data.chain}`,
        method: 'GET',
      }),
    }),
    getNFTsHoldersList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/table-nft-holders/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.blockchain
          ? `&blockchain=${arg.blockchain}` : 'ethereum'}${arg.order
          ? `&order=${arg.order}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getNftHolderListCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.id}/nft-holders-csv/?limit=${arg.limit || 50000}${arg.sort
          ? `&sort=${arg.sort}`
          : ''}${arg.sortBy
          ? `&sort_by=${arg.sortBy}`
          : ''}${arg.blockchain
          ? `&blockchain=${arg.blockchain}`
          : 'ethereum'}&wait=${arg.wait}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
        method: 'GET',
      }),
    }),
    postSnapshotOfNftHolderList: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.id}/nft-save-as-custom-list/`,
        method: 'POST',
        body: {
          name: arg.name,
          blockchain: arg.network,
          audience: arg.audience,
        },
      }),
    }),
    getAddressesCollection: build.query({
      query: (slug) => ({
        url: `${BASE_API}/nft/${slug}/addresses/`,
        method: 'GET',
      }),
    }),
    reachNFT: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/nft-reach/?blockchain=${arg.network}`,
        method: 'GET',
      }),
    }),
    getNFTLookup: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/nft-lookup/?blockchain=${arg.blockchain}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 180,
    }),
    getNftDappUsageData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.name}/dapps/usage/?limit=${arg.limit}&blockchain=${arg.network}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
    getNFTDeployer: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/deployer/?blockchain=${arg.network}`,
      }),
    }),
    sendNFTAiOverviewByEmail: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.id}/ai-overview/send-by-email/?blockchain=${arg.blockchain}`,
        method: 'POST',
      }),
    }),
    getNFTDevActivity: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/dev-activity/?limit=${arg.limit}&blockchain=${arg.blockchain}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.audience
          ? `&audience=${arg.audience}` : ''}`,
      }),
    }),
  }),
});
