import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DndContext,
  DragOverlay,
  useSensors,
  useSensor,
  PointerSensor,
  closestCorners,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as Bootstrap from 'bootstrap';
import ToolBox, { ToolboxOverlayField } from './ToolBox';
import { useCallbackPrompt } from '../../../components/hooks/app/useCallbackPrompt';
import {
  clearFormState,
  clearActiveSettings,
  selectCurrPage,
  selectFormName,
  selectFormUrl,
  selectAccessType,
  selectFormStyle,
  selectFieldSettings,
  setErrors,
  setFormName,
  setFormUrl,
  loadFieldSettings,
  removeFieldSettings,
  initializeFieldSettings,
  setActiveSettings,
  updateFieldSettingsForPage,
  selectBackgroundImage,
  selectFormImage,
  selectFormColor,
  selectFormTextColor,
  selectButtonColor,
  selectButtonTextColor,
  selectBackgroundColor,
  selectFormImageUrl,
  selectBackgroundImageUrl,
  setFormStyle,
  setAllowMultipleSubmissions,
  selectAllowMultipleSubmissions,
} from '../../../store/reducers/forms';
import styles from './CreateForm.module.scss';
import FormSettings from './FormSettings';
import Navigation from './Components/Navigation';
import FormsBuilder from './Components/FormsBuilder';
import './Style/Styles.scss';
import { formsApi } from '../../../api/forms';
import { showErrorMessage } from '../../../components/base/Notifications';
import { getUser } from '../../../store/reducers/user';
import { trackFormCreated } from '../../../utils/mixpanel/mixpanelEvents';
import { SaveFormNameModals } from '../Modals/SaveFormNameModals';
import { ConfirmSaveFormModal } from '../Modals/ConfirmSaveFormModal';
import Loader from '../../../components/base/Loader';
import OverlayField from './Components/OverlayField';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import { STATIC_FIELDS } from '../Constants';

function getData(prop) {
  return prop?.data?.current ?? {};
}

const CreateForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [sidebarFieldsRegenKey, setSidebarFieldsRegenKey] = useState(Date.now());
  const currentDragFieldRef = useRef();
  const isAdding = useRef();
  const [activeSidebarField, setActiveSidebarField] = useState();
  const [activeField, setActiveField] = useState();
  const [data, updateData] = useImmer({ fields: [] });
  const dispatch = useDispatch();
  const { formId } = useParams();
  const currPage = useSelector(selectCurrPage);
  const formName = useSelector(selectFormName);
  const formurl = useSelector(selectFormUrl);
  const allowMultipleSubmissions = useSelector(selectAllowMultipleSubmissions);
  const accessType = useSelector(selectAccessType);
  const formStyle = useSelector(selectFormStyle);
  const fieldSettings = useSelector(selectFieldSettings);
  const [saveForm, result] = formsApi.useSaveFormMutation();
  const [changeForm, resultChangeForm] = formsApi.useChangeFormMutation();
  const [prevPage, setPrevPage] = useState(null);
  const backgroundImage = useSelector(selectBackgroundImage);
  const formImage = useSelector(selectFormImage);
  const formColor = useSelector(selectFormColor);
  const formTextColor = useSelector(selectFormTextColor);
  const buttonColor = useSelector(selectButtonColor);
  const buttonTextColor = useSelector(selectButtonTextColor);
  const backgroundColor = useSelector(selectBackgroundColor);
  const formImageUrl = useSelector(selectFormImageUrl);
  const backgroundImageUrl = useSelector(selectBackgroundImageUrl);
  const user = useSelector(getUser);
  const [showSaveFormNameModal, setShowSaveFormNameModal] = useState(false);
  const [showConfirmSaveFormModal, setShowConfirmSaveFormModal] = useState(false);
  const [status, setStatus] = useState('Active');
  const [isNewForm, setIsNewForm] = useState(true);
  const [isPointerOutsideBuilder, setIsPointerOutsideBuilder] = useState(false);
  const builderRef = useRef();
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const isMinimized = localStorage.getItem('toolbox_minimized');
  const isStaticForm = (fieldSettings.find((page) => page.pageName === 'main-form')?.fields.every(
    (field) => STATIC_FIELDS.includes(field.name.toLowerCase()),
  )) || false;
  const [leftPosition, setLeftPosition] = useState(0);

  useEffect(() => {
    const updateSettingsPosition = () => {
      if (builderRef.current) {
        const rect = builderRef.current.getBoundingClientRect();
        setLeftPosition(rect.left + rect.width / 2);
      }
    };

    updateSettingsPosition();

    window.addEventListener('resize', updateSettingsPosition);

    return () => {
      window.removeEventListener('resize', updateSettingsPosition);
    };
  }, [builderRef.current]);

  const {
    currentData: form,
    isLoading: isFormLoading,
    isFetching: isFormFetching,
  } = formsApi.useGetFormByIdQuery(
    {
      id: formId,
    },
    { skip: !formId },
  );

  const { fields } = data;

  useEffect(() => {
    setPrevPage(currPage);
  }, [currPage]);

  // Page Rendering Logic
  const mainContainer = document.getElementsByClassName('main');
  mainContainer[0].style.padding = 0;
  useEffect(() => () => {
    if (mainContainer.length > 0) {
      mainContainer[0].style.padding = '0 4em';
    }
  }, [mainContainer]);

  useEffect(() => {
    if (formColor) {
      document.documentElement.style.setProperty('--form-background', formColor);
    }
    if (backgroundColor) {
      document.documentElement.style.setProperty('--modal-background', backgroundColor);
    }
  }, [formColor, backgroundColor]);

  useEffect(() => {
    if (backgroundImage && backgroundImageUrl) {
      document.documentElement.style.setProperty('--modal-background', `url(${backgroundImageUrl})`);
    } else if (backgroundColor) {
      document.documentElement.style.setProperty('--modal-background', backgroundColor);
    }
    if (formImage && formImageUrl) {
      document.documentElement.style.setProperty('--form-background', `url(${formImageUrl})`);
    } else if (formColor) {
      document.documentElement.style.setProperty('--form-background', formColor);
    }
    if (formTextColor) {
      document.documentElement.style.setProperty('--form-text-color', formTextColor);
    }
    if (buttonColor) {
      document.documentElement.style.setProperty('--form-button-background', buttonColor);
    }
    if (buttonTextColor) {
      document.documentElement.style.setProperty('--form-button-text-color', buttonTextColor);
    }
  }, [
    formColor,
    backgroundColor,
    backgroundImage,
    formImage,
    formImageUrl,
    backgroundImageUrl,
    formTextColor,
    buttonTextColor,
    buttonColor]);

  useEffect(() => () => dispatch(clearFormState()), [dispatch]);

  // Loads Existing Form Data
  useEffect(() => {
    if (form && !isFormLoading && !isFormFetching) {
      dispatch(setFormName(form.name));
      dispatch(setFormUrl(form.url));
      dispatch(setAllowMultipleSubmissions(form.allow_multiple_submissions));
      form.field_settings.forEach((pageSetting) => {
        pageSetting.fields.forEach((field) => {
          dispatch(loadFieldSettings({ pageName: pageSetting.pageName, field }));
        });
      });
      const mainPageSetting = form.field_settings.find((o) => o.pageName === 'main-form');
      if (mainPageSetting) {
        updateData((draft) => {
          draft.fields = mainPageSetting.fields;
        });
      }
      // Load Form Styles
      dispatch(setFormStyle({
        formStyle: {
          formColour: form.style.formStyle.formColour,
          formImageUrl: form.style.formStyle.formImageUrl,
          colourTheme: form.style.formStyle.colourTheme,
          textColour: form.style.formStyle.textColour,
        },
        buttonStyle: {
          buttonColour: form.style.buttonStyle.buttonColour,
          textColour: form.style.buttonStyle.textColour,
        },
        backgroundStyle: {
          backgroundColour: form.style.backgroundStyle.backgroundColour,
          backgroundImageUrl: form.style.backgroundStyle.backgroundImageUrl,
        },
        fieldStyle: {
          invertColours: form.style.fieldStyle.invertColours,
        },
      }));
    } else {
      dispatch(initializeFieldSettings());
    }
  }, [isFormLoading, isFormFetching]);

  useEffect(() => {
    setIsNewForm(form === undefined);
  }, [form]);

  const handleFieldRearrange = (updatedFields) => {
    if (!prevPage) {
      return;
    }
    const orderedFieldIds = updatedFields.map((field) => field.id);
    const previousPageSettings = fieldSettings.find((page) => page.pageName === prevPage);
    if (previousPageSettings) {
      const reorderedFields = orderedFieldIds.map((fieldId) => {
        const foundField = previousPageSettings.fields.find((field) => field.field_id === fieldId);
        if (!foundField) {
          return null;
        }
        return foundField;
      }).filter(Boolean);
      dispatch(updateFieldSettingsForPage({
        pageName: prevPage,
        fields: reorderedFields,
      }));
    }
  };

  const onPointerMove = (event) => {
    if (!builderRef.current) return;
    const offset = 100;

    const rect = builderRef.current.getBoundingClientRect();
    const mouseX = event.clientX;

    const isOutside = (
      mouseX < rect.left - offset
      || mouseX > rect.right + offset
    );

    setIsPointerOutsideBuilder(isOutside);
  };

  useEffect(() => {
    const currentPageFieldsSettings = fieldSettings.find((page) => page.pageName === currPage);
    if (currentPageFieldsSettings && currentPageFieldsSettings.fields) {
      const transformedFields = currentPageFieldsSettings.fields.map((field) => {
        const {
          // eslint-disable-next-line camelcase
          field_id, type, name, ...restOfFields
        } = field;

        return {
          // eslint-disable-next-line camelcase
          id: field_id,
          type,
          name: `${name}`,
          options: restOfFields,
        };
      });
      updateData((draft) => {
        draft.fields = transformedFields;
      });
      setPrevPage(currPage);
    } else {
      updateData((draft) => {
        draft.fields = [];
      });
    }
  }, [currPage]);

  // Saving: Perform Navgation after Save (or Draft)
  const onResultReceive = useCallback((response) => {
    if (response.isLoading || response.isFetching) {
      // setStartAndStopDateFlow(false);
    }
    if (response.isSuccess) {
      if (response.status === 'fulfilled') {
        trackFormCreated(user, response.data);
      }
      if (response.data.errors) {
        dispatch(setErrors(response.data.errors));
        const myModal = new Bootstrap.Modal(document.getElementById('errorsModal'));
        myModal.toggle();
      }
      if ((!response.data.errors)
        || response.originalArgs.status === 'draft'
        || response.originalArgs.payload?.status === 'draft') {
        navigate('/forms');
      }
      response.reset();
    }
    if (response.isError) {
      let errorMessage = '';
      if (response?.error?.status === 400) {
        if (response?.error?.data?.name && response?.error?.data?.name.length > 0) {
          [errorMessage] = response.error.data.name;
        } else if (response.error.data.errors && response.error.data.errors.length > 0) {
          [errorMessage] = response.error.data.errors;
        }
      }
      const hasErrorData = Object.keys(data).length !== 0;
      showErrorMessage(hasErrorData ? errorMessage : 'Something went wrong');
      response.reset();

      if (errorMessage.trim() === 'Name must be unique') {
        setShowSaveFormNameModal(true);
      }
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    onResultReceive(result);
  }, [result, onResultReceive]);

  useEffect(() => {
    onResultReceive(resultChangeForm);
  }, [resultChangeForm, onResultReceive]);

  const onSubmit = (statusToSave, isConfirmed = false) => {
    setStatus(statusToSave);
    if (!formName || formName === '') {
      setShowSaveFormNameModal(true);
      return;
    }
    if (statusToSave === 'Active' && !isConfirmed) {
      setShowConfirmSaveFormModal(true);
      return;
    }
    handleFieldRearrange(data.fields);

    const updatedFieldSettings = [];
    fieldSettings.forEach((page) => {
      if (isStaticForm && page.pageName === 'thank-you') {
        return;
      }
      const formattedFields = page.fields.map((field) => {
        const {
          field_id: fieldId, name, type, ...options
        } = field;
        return {
          id: `R${fieldId.replace(/[-_]/g, 'e').substring(1)}`,
          name,
          type,
          options,
        };
      });

      updatedFieldSettings.push({
        ...page,
        fields: formattedFields,
      });
    });

    const dataToUpload = {
      name: formName,
      allow_multiple_submissions: allowMultipleSubmissions,
      field_settings: updatedFieldSettings,
      accessType,
      style: formStyle,
      errors: [],
      isUnsaved: false,
      status: statusToSave,
    };

    if (!form || (form.url !== formurl)) {
      dataToUpload.url = formurl;
    }

    if (statusToSave === 'Active') {
      // Check if there are any fields in the main page
      const mainPage = fieldSettings.find((page) => page.pageName === 'main-form');
      if (mainPage && mainPage.fields.length === 0) {
        showErrorMessage('Main page cannot be empty');
        return;
      }

      // Check if there any fields in thank you page
      if (!isStaticForm) {
        const thankYouPage = fieldSettings.find((page) => page.pageName === 'thank-you');
        if (thankYouPage && thankYouPage.fields.length === 0) {
          showErrorMessage('Thank you page cannot be empty');
          return;
        }
      }

      const serializedData = JSON.stringify(dataToUpload);
      const sizeInBytes = new TextEncoder().encode(serializedData).length;
      const sizeInMB = sizeInBytes / (1024 * 1024);
      if (sizeInMB > 2) {
        showErrorMessage('Form size exceeds 2MB limit. Please remove some fields and try again.');
        return;
      }
    }

    if (isNewForm) {
      if (state && state.folder) {
        saveForm({ ...dataToUpload, folders: [state.folder] });
      } else {
        saveForm(dataToUpload);
      }
    } else {
      changeForm({
        id: form.id,
        payload: dataToUpload,
      });
    }
  };

  // Clean after drag and drop
  const cleanUp = () => {
    setActiveSidebarField(null);
    setActiveField(null);
    currentDragFieldRef.current = null;
    isAdding.current = null;
  };

  const isFieldTypeAdded = (fieldType) => {
    const uniqueFields = [
      'email',
      'twitter',
      'connectWallet',
      'instagram',
      'phone',
      'name',
      'discord',
      'telegram',
      'language',
      'country'];
    return uniqueFields.includes(fieldType) && data.fields.some((f) => f.type === fieldType);
  };

  const handleDragStart = ({ active }) => {
    cleanUp();
    dispatch(clearActiveSettings());

    const activeData = getData(active);

    if (activeData.fromSidebar) {
      const { field } = activeData;
      const { type } = field;
      if (isFieldTypeAdded(type)) {
        return;
      }
      setActiveSidebarField(field);
      currentDragFieldRef.current = {
        id: active.id,
        type,
        name: `${type}${fields.length + 1}`,
        options: {},
      };
    } else {
      const { field } = activeData;
      setActiveField(field);
      currentDragFieldRef.current = field;
    }
  };

  const handleDragOver = ({ over, activatorEvent }) => {
    if (!activeSidebarField || isAdding.current || !over) {
      return;
    }

    updateData((draft) => {
      if (!draft.fields.length) {
        draft.fields.push(currentDragFieldRef.current);
      } else {
        const overData = getData(over);
        let modifier = 1;
        const itemCollision = over.id !== 'canvas_droppable' ? over : null;

        if (itemCollision) {
          const { top, height } = itemCollision.rect;
          const middleOfLastItem = top + (height / 2);
          const pointerPosition = activatorEvent.clientY;
          modifier = pointerPosition < middleOfLastItem ? 0 : 1;
        }

        const newIndex = overData.index > -1 ? overData.index + modifier : draft.fields.length;
        draft.fields.splice(newIndex, 0, currentDragFieldRef.current);
      }
    });

    isAdding.current = true;
  };

  const handleDragCancel = () => {
    cleanUp();
  };

  const handleRemove = (id) => {
    updateData((draft) => {
      draft.fields = draft.fields.filter((f) => f.id !== id);
    });
    dispatch(removeFieldSettings({ pageName: currPage, field: { id } }));
  };

  const handleDragEnd = ({ over }) => {
    if (!over || !currentDragFieldRef.current) return;

    if (isPointerOutsideBuilder) {
      handleRemove(currentDragFieldRef.current.id);
      return;
    }

    const overData = getData(over);

    const activeIndex = data.fields.findIndex((field) => field.id === currentDragFieldRef.current.id);

    updateData((draft) => {
      draft.fields = arrayMove(
        draft.fields,
        activeIndex,
        overData.index,
      );
    });

    setSidebarFieldsRegenKey(Date.now());
    if (activeSidebarField) {
      dispatch(setActiveSettings({ type: currentDragFieldRef.current.type, field_id: currentDragFieldRef.current.id }));
    }

    if (!activeSidebarField) {
      handleFieldRearrange(data.fields);
    }
    setShowDialog(true);
    cleanUp();
  };

  useEffect(() => {
    const currentPageFields = fieldSettings.find((page) => page.pageName === prevPage)?.fields || [];
    if (currentPageFields.length === 0 || currentPageFields.length !== data.fields.length) return;
    let needToRearrange = false;
    currentPageFields.forEach((field, index) => {
      if (field.field_id !== data.fields[index]?.id) {
        needToRearrange = true;
      }
    });
    if (needToRearrange) handleFieldRearrange(data.fields);
  }, [fieldSettings]);

  const getClosest = (args) => {
    const { droppableContainers, pointerCoordinates } = args;

    const collisions = [];
    droppableContainers.filter((container) => container.id !== 'canvas_droppable').forEach((container) => {
      const topSpace = Math.abs(container.rect.current.top - pointerCoordinates.y);
      const bottomSpace = Math.abs(container.rect.current.bottom - pointerCoordinates.y);
      const smaller = topSpace < bottomSpace ? topSpace : bottomSpace;

      collisions.push({
        id: container.id,
        data: {
          droppableContainer: container,
          value: smaller,
        },
      });
    });

    return collisions.sort((a, b) => a.data.value - b.data.value);
  };

  const customCollisionDetectionAlgorithm = (args) => {
    const closestContainers = getClosest(args);
    if (closestContainers.length > 0) {
      return closestContainers;
    }
    return closestCorners(args);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const onSaveNameCancel = () => {
    setShowSaveFormNameModal(false);
    dispatch(setFormName(''));
  }

  if ((!form && (isFormLoading || isFormFetching)) || (result.isLoading || result.isFetching)) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Forms - Absolute Labs Platform
        </title>
      </Helmet>
      <div className={styles.wrapper}>
        {showSaveFormNameModal && (
          <SaveFormNameModals
            onCancel={onSaveNameCancel}
            onSubmit={() => {
              setShowSaveFormNameModal(false);
              onSubmit(status);
            }}
            formName={formName}
            status={status}
          />
        )}
        {showPrompt && (
          <ConfirmModal
            onSubmit={() => {
              confirmNavigation();
            }}
            onCancel={cancelNavigation}
            buttonName="Proceed"
            title="Unsaved values"
            description="All unsaved values will not be saved! Are you sure?"
          />
        )}
        {(showConfirmSaveFormModal && !showSaveFormNameModal) && (
          <ConfirmSaveFormModal
            onCancel={() => setShowConfirmSaveFormModal(false)}
            onSubmit={onSubmit}
            formName={formName}
            status={status}
          />
        )}
        <div className={styles.dndform}>
          <div
            className={styles.reactform_wrapper}
            onPointerMove={onPointerMove}
          >
            <FormSettings />
            <DndContext
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragCancel={handleDragCancel}
              onDragEnd={handleDragEnd}
              collisionDetection={customCollisionDetectionAlgorithm}
              sensors={sensors}
              autoScroll
            >
              <ToolBox fieldsRegKey={sidebarFieldsRegenKey} />
              <div className="w-100" style={{ overflowY: 'auto' }}>
                <div
                  className={`${styles.dndformbuilder} card mx-auto my-5 p-4`}
                  ref={builderRef}
                >
                  <SortableContext
                    strategy={verticalListSortingStrategy}
                    items={fields.map((f) => f.id)}
                    removable
                    handle
                  >
                    <FormsBuilder fields={fields} onRemove={handleRemove} isPointerOutside={isPointerOutsideBuilder} />
                  </SortableContext>
                  {!isStaticForm && currPage === 'main-form' ? (
                    <button
                      type="button"
                      className={styles.submitButton}
                      style={{ background: buttonColor, color: buttonTextColor }}
                    >
                      Submit
                    </button>
                  ) : null}
                  <Navigation isStatic={isStaticForm} left={leftPosition} />
                </div>
                <DragOverlay dropAnimation={false}>
                  {activeSidebarField ? (
                    <ToolboxOverlayField overlay field={activeSidebarField} isMinimized={isMinimized} />
                  ) : null}
                  {activeField
                    ? (
                      <OverlayField
                        overlay
                        field={activeField}
                        isPointerOutsideBuilder={isPointerOutsideBuilder}
                      />
                    ) : null}
                </DragOverlay>
              </div>
            </DndContext>
          </div>
        </div>
      </div>
      <div className={`${styles.nav} d-flex justify-content-end align-items-center`}>
        <button
          type="button"
          className="outline-button"
          onClick={() => navigate('/forms')}
        >
          Cancel
        </button>
        <div className={styles.nav_save}>
          <button
            type="submit"
            className="outline-blue-button"
            disabled={
              (fieldSettings.find((page) => page.pageName === 'main-form')?.fields?.length === 0)
              || result.isLoading
              || result.isFetching
            }
            onClick={() => {
              onSubmit('Draft');
              setShowDialog(false);
            }}
          >
            Save as Draft
          </button>
          <button
            type="submit"
            className="regular-button"
            disabled={
              (fieldSettings.find((page) => page.pageName === 'main-form')?.fields?.length === 0)
              || result?.isLoading
              || result?.isFetching
            }
            onClick={() => {
              onSubmit('Active');
              setShowDialog(false);
            }}
          >
            Publish Form
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateForm;
