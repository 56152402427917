import React, { useEffect, useMemo, useState } from 'react';
import {
  Link, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigationType } from 'react-router';
import { audiencesApi } from '../../api/audiences';
import InvestorProfile from './InvestorProfile';
import InvestorPortfolio from './InvestorPortfolio';
import List from './List';
import Owned from '../../assets/images/asset/member.png';
import Chart from '../../assets/images/asset/chart.png';
import Cal from '../../assets/images/asset/Icon.png';
import uniqueId from '../../utils/uniqueId';
import { userApi } from '../../api/user';
import styles from './SegmentDetails.module.scss';
import Reach from '../../components/ui/Reach';
import ConfirmModal from '../../components/ui/modals/ConfirmModal';
import Exchanges from './Exchanges';
import DappUsage from './DappUsage';
import Title from '../../components/base/Title';
import EditableFoldersList from '../../components/ui/EditableFoldersList';
import WidgetCard from '../../components/ui/WidgetCard';
import { getCardValue, getEthPrice } from '../../utils/values';
import DevActivity from "./DevActivity";
import Loader from '../../components/base/Loader';
import SegmentLoader from './SegmentLoader';
import { onSettingsChange } from '../../store/reducers/flows';
import { useDispatch } from 'react-redux';

const SegmentDetails = () => {
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [routineLoaded, setRoutineLoaded] = useState(false);
  
  const id = pathname.split('/')[2];

  const {
    data: runRoutine,
  } = audiencesApi.useRunRoutineQuery(
    {
      id,
    },
    {
      skip: routineLoaded,
      pollingInterval: 5000,
    },
  );

  useEffect(() => {
    if (runRoutine?.status === 'updated' && !routineLoaded) {
      setRoutineLoaded(true);
    }
  }, [runRoutine?.status, routineLoaded]);

  const {
    data: segment,
    isLoading: isSegmentLoading,
    isFetching: isSegmentFetching,
  } = audiencesApi.useGetSegmentByIdQuery(
    {
      id,
    },
  );

  const {
    data: segmentAnalytics,
    isLoading: isSegmentAnalyticsLoading,
    isFetching: isSegmentAnalyticsFetching,
  } = audiencesApi.useGetSegmentAnalyticsQuery(
    {
      id,
    },
    {
      skip: !routineLoaded,
    },
  );

  const {
    data: analytics,
    isLoading: isAnalyticsLoading,
    isFetching: isAnalyticsFetching,
  } = audiencesApi.useGetSegmentMedianAnalyticsQuery(
    {
      id,
    },
    {
      skip: !routineLoaded,
    },
  );

  const {
    data: reachData,
    isLoading: isReachDataLoading,
    isFetching: isReachDataFetching,
  } = audiencesApi.useSegmentReachQuery({
    id,
  }, {
    skip: !routineLoaded,
  });

  const {
    data: insightData,
    isLoading: isInsightDataLoading,
    isFetching: isInsightDataFetching,
  } = audiencesApi.useGetSegmentListInsightQuery(id);

  const {
    refetch,
  } = userApi.useGetAssetsQuery();

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        500,
      );
    }
  }, [navType, searchParams]);

  const pages = [
    {
      label: 'Audience Profile',
      component: <InvestorProfile
        holders={segmentAnalytics?.count_of_addresses}
        isHoldersLoading={isSegmentAnalyticsLoading || isSegmentAnalyticsFetching}
        audienceProfileData={insightData}
        audienceProfileIsLoading={isInsightDataLoading || isInsightDataFetching}
      />,
      id: 1,
    },
    {
      label: 'Audience Portfolio',
      component: <InvestorPortfolio holders={insightData?.wallet_count} assetsRefetch={refetch} />,
      id: 2,
    },
    { label: 'List', component: <List walletCounts={insightData?.wallet_count} />, id: 3 },
    { label: 'Dapp Usage', component: <DappUsage holders={insightData?.wallet_count} />, id: 4 },
    {
      label: 'Centralized Exchanges',
      component: <Exchanges holders={insightData?.wallet_count} />,
      id: 5,
    },
    {
      label: "Developer activity",
      component: <DevActivity holders={insightData?.wallet_count} />,
      id: 6
    }
  ];

  const selectedTab = searchParams.get('tab') || 'Audience Profile';

  const widgetData = [
    {
      title: 'Profile Count',
      value: getCardValue(segmentAnalytics?.count_of_addresses),
      icon: Owned,
    },
    {
      title: 'Cumulated Portfolio Value',
      value: getEthPrice(segmentAnalytics?.sum_of_portfolio_value),
      icon: Chart,
    },
    {
      title: 'Median Portfolio Value',
      value: getEthPrice(analytics?.total_pct),
      icon: Cal,
    },
  ];

  const useInFlow = () => {
    if (!segment) return;

    const segmentConfig = {
      config_name: 'Segment',
      frequency: "",
      segment_name: segment.name,
      segment_id: segment.id,
      user_input: false,
      population: segment.population,
      name: segment.name,
      node_id: "node_0"
    }

    navigate('/add-flow', { state: { config: [segmentConfig] } });
  }

  return useMemo(() => {
    if (!segment || isSegmentLoading || isSegmentFetching) {
      return (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {segment?.name}
            {' '}
            Audiences
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
        <div className={styles.title_wrapper}>
          <div>
            <div className="d-flex gap-2 align-items-center">
              <div className={styles.title}>
                {segment?.name || ''}
              </div>
            </div>

            <div className="mt-2">
              <EditableFoldersList itemId={Number(id)} itemType="audiences" />
            </div>
          </div>
          <div className={styles.actions_wrapper}>
            <button
              type="button"
              className="outline-blue-button"
              onClick={() => navigate(`/add-segment/${id}?edit=true`)}
            >
              Edit
            </button>
            <button
              type="button"
              className="regular-button text-nowrap"
              onClick={useInFlow}
            >
              Use in Flow
            </button>
          </div>
        </div>

        <div className={styles.portfolio_wrapper}>
          <div className="asset-section">
            <div className="mt-4 mx-4 pt-3 pb-2">
              <Title>Portfolio Value</Title>
            </div>
            <div className="dropdown-divider w-100" />
            <div className={`${styles.wrapper} d-gap-3 d-flex flex-column`}>
              {segment?.is_restricted && (
                <ConfirmModal
                  title="Dataset was deleted"
                  description="This segment cannot be accessed because the dataset,
                    that it used, was deleted or edited."
                  onSubmit={() => { navigate('/audiences'); }}
                />
              )}
              <div className="d-flex gap-3 flex-column flex-sm-row pb-4">
                {widgetData.map((item) => (
                  <WidgetCard
                    key={uniqueId('segment-analytics-widget')}
                    icon={item.icon}
                    info={item.info}
                    growth={item.growth}
                    title={item.title}
                    value={item.value}
                    isLoading={!routineLoaded || isSegmentAnalyticsLoading
                      || isSegmentAnalyticsFetching}
                  />
                ))}
              </div>
              <Reach
                data={reachData}
                isLoading={!routineLoaded || isReachDataFetching || isReachDataLoading || isSegmentLoading || isSegmentFetching}
                totalCount={segmentAnalytics?.count_of_addresses}
              />
            </div>
          </div>
          {!routineLoaded ? (
            <SegmentLoader />
          ) : null}
        </div>
        {routineLoaded ? (
          <div className="asset-section m-o mt-4">
            <div className="title-gap asset-navigation">
              <ul className="nav nav-pills gap-2">
                {pages.map((elem) => (
                  <li
                    role="presentation"
                    className="nav-item"
                    key={uniqueId('segment-analytics-tab')}
                    onClick={() => {
                      setSearchParams({
                        tab: elem.label,
                        scroll: '',
                      });
                    }}
                  >
                    <Link
                      className={`nav-link ${
                        elem.label === selectedTab ? 'active' : ''
                      }`}
                      to={`${pathname}${search}`}
                    >
                      {elem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dropdown-divider w-100" />
              {pages.find((page) => page.label === selectedTab)?.component}
          </div>
        ) : null}
      </>
    );
  }, [
    analytics?.total_pct,
    isAnalyticsFetching,
    isAnalyticsLoading,
    routineLoaded,
    isReachDataFetching,
    isReachDataLoading,
    isSegmentAnalyticsFetching,
    isSegmentAnalyticsLoading,
    isSegmentFetching,
    isSegmentLoading,
    selectedTab,
    navType,
    navigate,
    pathname,
    reachData,
    refetch,
    search,
    segment,
    segmentAnalytics?.count_of_addresses,
    segmentAnalytics?.sum_of_portfolio_value,
    setSearchParams,
    insightData,
    isInsightDataLoading,
    isInsightDataFetching,
  ]);
};

export default SegmentDetails;
