import React from 'react';
import Modal from '../../../components/base/Modal';
import ModalTitle from '../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../components/base/Modal/ModalFooter';
import Markdown from '../../../components/base/Markdown';

const SummaryModal = ({ summary, closeModal }) => {
  if (!summary) return null;

  return (
    <Modal onCancel={closeModal}>
      <ModalTitle title="Campaign AI Summary" />
      <Markdown content={summary} />
      <ModalFooter>
        <button type="button" className="regular-button border border-1" onClick={closeModal}>
            Close
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default SummaryModal
