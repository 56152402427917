import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AirDropSettings.module.scss';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList, selectFlowStatus,
} from '../../../../../store/reducers/flows';
import TokensAirdrop from './TokensAirdrop';
import CustomAirdrop from './CustomAirdrop';
import { getUser } from '../../../../../store/reducers/user';
import NFTAirdrop from './NFTAirdrop';
import EditableTitle from '../../../../../components/base/EditableTitle';

const AirDropSettings = ({ activeSettings }) => {
  const configList = useSelector(selectConfigsList);
  const airdropSavedData = configList.find(item => item.name === "Airdrop");
  const dispatch = useDispatch();

  const [assetType, setAssetType] = useState(airdropSavedData ? airdropSavedData.asset_type : null);
  const user = useSelector(getUser);
  const status = useSelector(selectFlowStatus);

  const [titleValue, setTitleValue] = useState('');
  const [settingsToSave, setSettingsToSave] = useState(null);

  const disableChangeStatus = status === 'running' || status === 'scheduled' || status === 'stopped';

  const {
    handleSubmit, 
  } = useForm();

  const handleSettingsChange = (settings) => {
    setSettingsToSave(settings);
  }

  const onSubmit = () => {
    let newObj = {};
    newObj.name = titleValue || 'Airdrop';
    newObj.team_name = user?.team;
    newObj.asset_type = assetType;
    newObj = { ...newObj, ...settingsToSave }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...newObj, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  const contentToRender = useMemo(() => {
    if (assetType === 'custom') {
      return (
        <CustomAirdrop
          airdropSavedData={airdropSavedData}
          isDisabled={disableChangeStatus}
          onSettingsChange={handleSettingsChange}
        />
      );
    }
    if (assetType === 'tokens') {
      return (
        <TokensAirdrop
          airdropSavedData={airdropSavedData}
          isDisabled={disableChangeStatus}
          onSettingsChange={handleSettingsChange}
        />
      );
    }
    if (assetType === 'nft') {
      return (
        <NFTAirdrop
          airdropSavedData={airdropSavedData}
          isDisabled={disableChangeStatus}
          onSettingsChange={handleSettingsChange}
          nodeID={activeSettings.node_id}
        />
      );
    }
  }, [
    assetType,
    disableChangeStatus,
    activeSettings,
  ]);

  const handleTypeChange = (type) => {
    setAssetType(type);
    setSettingsToSave(null);
  }

  return (
    <div className={styles.wrapper}>
      <form
        onSubmit={
          handleSubmit(onSubmit)
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div className={styles.scrollable_content}>
          <div>
            <div className={`${styles.title} d-flex align-items-center gap-2 mb-3`}>
              <EditableTitle
                defaultTitle="Airdrop"
                titleValue={titleValue}
                setTitleValue={setTitleValue}
                maxLength={25}
                size={16}
                edit={!disableChangeStatus}
              />
            </div>
            <div className={styles.type}>
              <div className={styles.type_title}>
                Asset type
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className={assetType === 'tokens' ? 'check-button-checked' : 'check-button'}
                  onClick={() => handleTypeChange('tokens')}
                  disabled={disableChangeStatus}
                >
                  Tokens
                </button>
                <div data-for="nftAssetTypeTooltip" data-tip>
                  <button
                    type="button"
                    className={assetType === 'nft' ? 'check-button-checked' : 'check-button'}
                    onClick={() => handleTypeChange('nft')}
                    disabled={disableChangeStatus}
                  >
                    NFTs
                  </button>
                </div>
                <button
                  type="button"
                  className={assetType === 'custom' ? 'check-button-checked' : 'check-button'}
                  onClick={() => handleTypeChange('custom')}
                  disabled={disableChangeStatus}
                >
                  Custom
                </button>
              </div>
            </div>
            {contentToRender}
          </div>
        </div>

        <div className="mt-4">
          <div className="d-flex gap-4">
            <button
              type="button"
              className={`${styles.btn_cancel}`}
              onClick={() => {
                dispatch(removeErrors(activeSettings.node_id));
                dispatch(clearActiveSettings());
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button w-100"
              disabled={!settingsToSave || disableChangeStatus}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AirDropSettings;
